import { DEFAULTS } from "@constants/Defaults";
import type { AuthUser } from "@doowii-types/auth";
import { Analytics } from "@services/analytics/Analytics";
import { withSentry } from "@utils/wrapper";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import { UserDocument } from "../../types/user";
import { db } from "./connection";
import { fetchOrganizationId } from "./organization";
import { getDocumentFromFirestore } from "./utils";

/**
 * Fetches a user document from Firestore.
 * @param userId - The ID of the user.
 * @param orgId - The ID of the organization.
 * @returns A Promise that resolves with the user document.
 */
const fetchUserDocument = withSentry((userId: string, orgId: string) =>
  getDocumentFromFirestore(`organizations/${orgId}/users`, userId)
);

/**
 * Fetches and processes a user document.
 * @param userId - The ID of the user.
 * @param orgFromToken - Optional organization ID from the token.
 * @returns A Promise that fetches & returns the userDocument, baseURL, and org name.
 */
const fetchAndProcessUserDocument = withSentry(async (userId: string) => {
  const orgId = await fetchOrganizationId(userId);
  if (!orgId) {
    return null;
  }

  Analytics.addUserInformation({ organization: orgId });

  const userDoc = await fetchUserDocument(userId, orgId);
  if (!userDoc) {
    return null;
  }

  return {
    userDocument: userDoc as UserDocument,
    organization: orgId || "",
  };
});

/**
 * Function to create a new document for when a user registers for the first time.
 * @param firstName - The first name of the user.
 * @param lastName - The last name of the user.
 * @param role - The role of the user.
 * @param user - The authenticated user object.
 * @returns A Promise that resolves with the new user document data.
 */
const createNewUserDocument = withSentry(
  async (
    firstName: string,
    lastName: string,
    role: UserDocument["role"],
    otherRole: UserDocument["otherRole"],
    user: AuthUser
  ) => {
    if (!user.email) {
      throw new Error("User or user email is null");
    }

    try {
      const invitationsCollectionRef = collection(db, "invitations");
      const emailQuery = query(invitationsCollectionRef, where("email", "==", user.email));
      const emailSnapshot = await getDocs(emailQuery);

      let organizationId = DEFAULTS.ORGANIZATION_ID;
      if (emailSnapshot.docs.length > 0) {
        organizationId = emailSnapshot.docs[0].data().organization;
      }

      const newUserDocData = {
        firstName,
        lastName,
        email: user.email,
        organization: organizationId,
        role,
        otherRole,
        pinboards: [],
        id: user.uid,
        registration: {
          status: "complete",
          date: new Date(Date.now()).toLocaleDateString(),
        },
        LSO: new Date(Date.now()).toLocaleDateString(),
      };
      await setDoc(doc(db, "organizations", organizationId, "users", user.uid), newUserDocData);

      await setDoc(doc(db, "user_orgs", user.uid), {
        id: user.uid,
        organization: organizationId,
      });

      if (!emailSnapshot.empty) {
        await deleteDoc(doc(db, "invitations", emailSnapshot.docs[0].id));
      }

      return newUserDocData;
    } catch (e) {
      console.error(e);

      throw e;
    }
  }
);

const disableAccount = withSentry(
  async (
    userToDelete: { id?: string; email: string; organization?: string },
    admin: { email: string }
  ) => {
    if (userToDelete.id) {
      const userRef = doc(db, "organizations", userToDelete.organization, "users", userToDelete.id);
      await setDoc(userRef, { registration: { status: "disabled" } }, { merge: true });

      const deletedCollectionRef = collection(db, "deleted");
      await addDoc(deletedCollectionRef, {
        email: userToDelete.email,
        disabledBy: admin.email,
        id: userToDelete.id,
        day: new Date().toISOString(),
        organization: userToDelete.organization,
      });
    } else {
      const invitationsCollectionRef = collection(db, "invitations");
      const emailQuery = query(invitationsCollectionRef, where("email", "==", userToDelete.email));
      const emailSnapshot = await getDocs(emailQuery);

      if (!emailSnapshot.empty) {
        const invitationDocRef = doc(invitationsCollectionRef, emailSnapshot.docs[0].id);
        await deleteDoc(invitationDocRef);
      }
    }
  }
);

const fetchSchemaURL = async (orgId: string) => {
  const docRef = doc(db, "organizations", orgId);

  let schemaURL = null;

  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().diagram_url) {
      schemaURL = docSnap.data().diagram_url;
    }
  } catch (error) {
    console.error("Error fetching document:", error);
  }
  return schemaURL;
};

export {
  createNewUserDocument,
  disableAccount,
  fetchAndProcessUserDocument,
  fetchSchemaURL,
  fetchUserDocument
};

