import { PATHS } from "@constants/Paths";
import { useBoundStore } from "@stores/store";
import { redirect } from "react-router-dom";

const rootLoader = async () => {
  const isAuthenticated = Boolean(useBoundStore.getState().authUser);
  if (!isAuthenticated) {
    return redirect(PATHS.SIGN_IN);
  }
  return null;
};

export { rootLoader };
