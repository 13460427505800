import { PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { faBookmark, faCodeBranch, faHouse, faMessages } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { NavBar } from "doowii-ui";

import { useAuth } from "../../hooks/useAuth";

const NavigationBar = () => {
  const { authUser } = useAuth();
  const { loading, streamLoading } = useChatData();
  const { _ } = useLingui();
  const userId = authUser?.uid;
  const disableNavigation = loading || streamLoading;

  const navItems = [
    {
      path: `${PATHS.USERS}/${userId}/${PATHS.HOME}`,
      label: _(msg`Home`),
      icon: <FontAwesomeIcon icon={faHouse} />,
      disabled: disableNavigation,
    },
    {
      path: `${PATHS.USERS}/${userId}/${PATHS.PINBOARDS}`,
      label: _(msg`Pinboards`),
      icon: <FontAwesomeIcon icon={faBookmark} />,
      disabled: disableNavigation,
    },
    {
      path: `${PATHS.USERS}/${userId}/${PATHS.THREADS}`,
      label: _(msg`Threads`),
      icon: <FontAwesomeIcon icon={faMessages} />,
      disabled: disableNavigation,
    },
    {
      path: PATHS.DATA_SCHEMA,
      label: _(msg`Data`),
      icon: <FontAwesomeIcon icon={faCodeBranch} />,
      disabled: disableNavigation,
    },
  ];

  return <NavBar navItems={navItems} />;
};

export { NavigationBar };
