import { faCode, faGripLinesVertical } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { css } from "@styled-system/css";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Tooltip,
} from "doowii-ui";
import { useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import { SQLDiagramHandler } from "./SQLDiagram/SQLDiagramHandler";
import { SQLEditor } from "./SQLEditor";

interface SQLSidePanelProps {
  sql: string;
  originalSql: string;
  chatId: string;
}

const ThickResizeHandle = () => (
  <PanelResizeHandle
    className={css({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "8px",
      cursor: "col-resize",
      backgroundColor: "base.blueBrandSecondary",
    })}
  >
    <FontAwesomeIcon
      className={css({ color: "base.blueBrandPrimary" })}
      icon={faGripLinesVertical}
    />
  </PanelResizeHandle>
);
const SQLSidePanel = ({ sql, originalSql, chatId }: SQLSidePanelProps) => {
  const { _ } = useLingui();
  const [open, setOpen] = useState(false);

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Tooltip content={_(msg`Show SQL`)}>
          <Button
            aria-label={_(msg`Open SQL Data schema and editor`)}
            iconOnly={faCode}
            size="small"
            variant="dropdown"
          />
        </Tooltip>
      </DialogTrigger>
      <DialogContent variant="slideRight">
        <DialogHeader>
          <DialogTitle>
            <Trans>SQL</Trans>
          </DialogTitle>
          <DialogDescription>
            <Trans>Data schema and SQL editor</Trans>
          </DialogDescription>
        </DialogHeader>
        {/* TODO: fix dialog boday style props issue */}
        <DialogBody style={{ padding: 0 }}>
          <PanelGroup direction="horizontal">
            <Panel defaultSize={40} minSize={10}>
              <SQLDiagramHandler sql={sql} />
            </Panel>
            <ThickResizeHandle />
            <Panel defaultSize={60} minSize={40}>
              <SQLEditor chatId={chatId} originalSql={originalSql} sql={sql} />
            </Panel>
          </PanelGroup>
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

export { SQLSidePanel };
