// src/services/webserver/featureFlags.ts
import { get } from "./base";

export const fetchFeatureFlags = async (): Promise<string[] | null> => {
  try {
    const response = await get("/feature_flags");
    return response.data;
  } catch (error) {
    console.error("Error fetching feature flags", error);
    return null;
  }
};
