import { UserRoles } from "./auth";

enum ViewEnum {
  FAVORITE_VIEW = "favorite",
  CHAT_VIEW = "chat",
  PINBOARD_VIEW = "pinboard",
  USER_MANAGEMENT_VIEW = "USER_MANAGEMENT",
  SETTINGS_VIEW = "SETTINGS",
  INTEGRATIONS_VIEW = "INTEGRATIONS",
}

interface UserDocument {
  id: string;
  email: string;
  lastName: string;
  firstName: string;
  role: keyof typeof UserRoles;
  otherRole: string;
  organization: string; // organization id
  pinboards: string[];
  LSO: string; // mm/dd/yyyy date
  registration: {
    date: string; // mm/dd/yyyy date
    status: string; // complete, ...
  };
}

interface UsersContextI {
  organizationUsers: UserDocument[];
  setOrganizationUsers: (users: UserDocument[]) => void;
  disabledUsers: UserDocument[];
  clearUsersData: () => void;
  fetchUsersInOrganization: () => void;
  fetchingOrganizationUsers: boolean;
}

export type { UserDocument, UsersContextI, ViewEnum };
