import { APOLLO_V2 } from "@constants/features";
import { ChartConfig } from "@doowii-types/chart";
import { DataVizType, DataVizTypes } from "@doowii-types/viz";
import {
  faChartLine,
  faChartPie,
  faChartScatter,
  faChartSimple,
  faTableRows,
  faPen,
} from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import * as Tabs from "@radix-ui/react-tabs";
import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";
import { capitalizeFirstLetter } from "@utils/capitalizeFirstLetter";
import { Button, Tooltip } from "doowii-ui";
import { useId } from "react";

interface DataNavigatorProps {
  selectedDataVizType: DataVizType;
  setSelectedDataVizType: (value: DataVizType) => void;
  columnTypes: ChartConfig["column_types"];
  selectedColumns: string[];
  suggestedChartType?: DataVizType;
  setIsApolloDialogOpen: (open: boolean) => void;
}

const dataVizIcons = {
  [DataVizTypes.TABLE]: faTableRows,
  [DataVizTypes.BAR_CHART]: faChartSimple,
  [DataVizTypes.PIE_CHART]: faChartPie,
  [DataVizTypes.LINE_CHART]: faChartLine,
  [DataVizTypes.SCATTER_CHART]: faChartScatter,
};

const getAvailableCharts = (
  columnTypes: ChartConfig["column_types"],
  selectedColumns: string[]
): DataVizType[] => {
  const selectedColumnTypes = selectedColumns.map((col) => columnTypes[col]);
  const numerical = selectedColumnTypes.filter((col) => col === "numerical");
  const categorical = selectedColumnTypes.filter((col) => col === "categorical");
  const temporal = selectedColumnTypes.filter((col) => col === "temporal");

  // Table && SQL can always be shown
  const charts: DataVizType[] = [DataVizTypes.TABLE];

  if (numerical.length === 2 && categorical.length <= 1) {
    charts.push(DataVizTypes.SCATTER_CHART);
  }
  if (numerical.length >= 1 && categorical.length === 1) {
    charts.push(DataVizTypes.BAR_CHART);
  }
  if (numerical.length === 1 && categorical.length === 1) {
    charts.push(DataVizTypes.PIE_CHART);
  }
  if (temporal.length + categorical.length === 1 && numerical.length >= 1) {
    charts.push(DataVizTypes.LINE_CHART);
  }
  return charts;
};

const DataNavigator = ({
  selectedDataVizType,
  setSelectedDataVizType,
  columnTypes,
  selectedColumns,
  suggestedChartType,
  setIsApolloDialogOpen,
}: DataNavigatorProps) => {
  const id = useId();
  const { _ } = useLingui();
  const availableCharts = getAvailableCharts(columnTypes, selectedColumns);
  const { featureFlags } = useAuth();

  const dataVizLabels = {
    [DataVizTypes.TABLE]: _(msg`Table view`),
    [DataVizTypes.BAR_CHART]: _(msg`Bar Chart view`),
    [DataVizTypes.PIE_CHART]: _(msg`Pie Chart view`),
    [DataVizTypes.LINE_CHART]: _(msg`Line Chart view`),
    [DataVizTypes.SCATTER_CHART]: _(msg`Scatter Chart view`),
  };

  return (
    <Tabs.Root
      defaultValue={selectedDataVizType}
      onValueChange={(value) => setSelectedDataVizType(value as DataVizType)}
    >
      <Tabs.List className={flex({ gap: "sm" })}>
        {Object.entries(dataVizIcons).map(([type, icon]) => (
          <Tabs.Trigger
            aria-label={dataVizLabels[type as keyof typeof dataVizLabels]}
            asChild
            disabled={
              !availableCharts.includes(type as DataVizType) ? type !== suggestedChartType : false
            }
            id={`${id}-${type}`}
            key={type}
            value={type}
          >
            <Tooltip content={_(msg` ${capitalizeFirstLetter(type.toLowerCase())} chart`)}>
              <Button
                aria-describedby={`${id}-${type}`}
                className={css({
                  '&[data-state="active"]': {
                    backgroundColor: "base.blueBrandSecondary",
                  },
                })}
                iconOnly={icon}
                size="small"
                variant="dropdown"
              />
            </Tooltip>
          </Tabs.Trigger>
        ))}
        {featureFlags.includes(APOLLO_V2) && (
          <Button
            aria-describedby={"chart-modification"}
            iconOnly={faPen}
            size="small"
            variant="dropdown"
            onClick={() => setIsApolloDialogOpen(true)}
          />
        )}
      </Tabs.List>
    </Tabs.Root>
  );
};
export { DataNavigator };
