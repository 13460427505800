import { ChartErrorState } from "@components/Charts";
import type { ChartConfig } from "@doowii-types/chart";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import HighchartsReact from "highcharts-react-official";
import { memo } from "react";

import { ChartLoadingSpinner } from "./ChartLoadingSpinner";
import { HighchartsWithAccessibility, sharedChartOptions } from "./utils";

// TODO: fix this any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataRow = Record<string, any>;

const extractCategoricalData = (data: DataRow[], chartConfig: ChartConfig) => {
  const categoricalColumns = chartConfig.columns.filter(
    (key: string) => chartConfig.column_types[key] === "categorical"
  );
  const numericalColumns = chartConfig.columns.filter(
    (key: string) => chartConfig.column_types[key] === "numerical"
  );

  // Prepare series data based on the number of numerical columns
  const seriesData: HighchartsWithAccessibility.SeriesBarOptions[] = numericalColumns.map(
    (numCol: string) => ({
      type: "bar",
      name: numCol,
      data: [],
    })
  );

  const categoryData: Record<string, Record<string, number[]>> = {};
  data.forEach((row) => {
    const categoryKey = categoricalColumns.map((column: string) => row[column]).join(" | ");
    categoryData[categoryKey] = categoryData[categoryKey] || {};
    numericalColumns.forEach((col: string) => {
      categoryData[categoryKey][col] = [];
    });
    numericalColumns.forEach((col: string) => {
      categoryData[categoryKey][col].push(row[col]);
    });
  });

  const categories = Object.keys(categoryData);

  categories.forEach((category) => {
    numericalColumns.forEach((numCol: string, index: number) => {
      const aggregation =
        chartConfig.column_aggregations && chartConfig.column_aggregations[numCol] !== undefined
          ? chartConfig.column_aggregations[numCol]
          : null;

      let value;
      switch (aggregation) {
        case "sum":
          value = categoryData[category][numCol].reduce((a, b) => a + b, 0);
          break;

        case "avg":
          value =
            categoryData[category][numCol].reduce((a, b) => a + b, 0) /
            categoryData[category][numCol].length;
          break;

        case "min":
          value = Math.min(...categoryData[category][numCol]);
          break;

        case "max":
          value = Math.max(...categoryData[category][numCol]);
          break;

        case "count":
          value = categoryData[category][numCol].length;
          break;

        default:
          value = categoryData[category][numCol].reduce((a, b) => a + b, 0);
      }
      value = typeof value === "number" ? Number(value.toFixed(2)) : 0;
      seriesData[index]?.data?.push(value);
    });
  });

  return {
    categories,
    series: seriesData,
    categoricalColumns,
  };
};

// TODO: fix this any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BarChart = ({ data, height, width, chartConfig, loading, error, title }: any) => {
  const { _ } = useLingui();
  if (loading || !chartConfig || !chartConfig.columns || !data) {
    return <ChartLoadingSpinner />;
  }
  if (error) {
    return <ChartErrorState />;
  }

  const { categories, series, categoricalColumns } = extractCategoricalData(data, chartConfig);

  const options: HighchartsWithAccessibility.Options = {
    ...sharedChartOptions,
    chart: {
      ...sharedChartOptions.chart,
      type: "bar",
      height,
      width,
      style: {
        fontFamily: "inherit",
      },
    },
    title: {
      text: chartConfig.chart_title || title || _(msg`Bar Chart`),
    },
    xAxis: {
      categories,
      title: {
        text: categoricalColumns.join(", "),
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: _(msg`Values`),
      },
      crosshair: chartConfig.y_axis_crosshair ?? true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: chartConfig.data_labels ?? false,
        },
      },
      series: {
        stacking: chartConfig.stack ? "normal" : undefined,
      },
    },
    series,
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: chartConfig.tooltip ?? true,
    },
    legend: {
      layout: chartConfig.legend_layout || "horizontal",
      align: chartConfig.legend_align || "center",
      verticalAlign: chartConfig.legend_vertical_align || "bottom",
      floating: chartConfig.legend_floating ?? false,
    },
  };

  return <HighchartsReact highcharts={HighchartsWithAccessibility} options={options} />;
};

const MemoizedBarChart = memo(
  BarChart,
  (prevProps, nextProps) =>
    prevProps.data === nextProps.data &&
    prevProps.height === nextProps.height &&
    prevProps.width === nextProps.width &&
    prevProps.chartConfig === nextProps.chartConfig &&
    prevProps.totalRows === nextProps.totalRows &&
    prevProps.title === nextProps.title
);

export { MemoizedBarChart };
