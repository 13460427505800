import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";
import React from "react";

const ChartLoadingSpinner: React.FC = () => {
  const spinnerStyle = css({
    // width: "xl2",
    // height: "xl2",
    border: "4px solid",
    borderColor: "border.base",
    borderTopColor: "foreground.secondary",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
    width: "32px",
    height: "32px",
  });

  const containerStyle = flex({
    display: "flex",
    flexDirection: "column",
    marginTop: "xl6",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "background.secondary",
  });

  return (
    <div className={containerStyle}>
      <div className={spinnerStyle} />
    </div>
  );
};

export { ChartLoadingSpinner };
