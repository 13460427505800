export const CHART_DATA_LIMIT = 5000;

export const PRE_FETCH_SIZE = 500;
export const PIN_PRE_FETCH_SIZE = 1000;
export const VALIDATE_FETCH_PAGE_SIZE = 10;
export const VALIDATE_FETCH_PAGE_NUMBER = 0;
export const CHART_LIMIT = 500;

export const ALERT_TIMEOUT = 3000;
export const PINBOARD_NAME_CHARACTER_LIMIT = 60;
export const QUESTION_LENGTH_LIMIT = 500;
