import {
  ChartErrorState,
  ChartWarning,
  MemoizedBarChart,
  MemoizedLineChart,
  MemoizedPieChart,
  MemoizedScatterChart,
} from "@components/Charts";
import { CHART_DATA_LIMIT } from "@constants/Limits";
import { Result } from "@doowii-types/chat";
import { DataVizType, DataVizTypes } from "@doowii-types/viz";
import { useChartQuery } from "@hooks/useChartQuery";
import { useResize } from "@hooks/useResize";
import { Flex } from "@styled-system/jsx";
import React from "react";

type ChartQueryResult = ReturnType<typeof useChartQuery>;

interface ChartRendererProps {
  selectedDataVizType: DataVizType;
  chartConfig: Result["chartConfig"];
  chartData: ChartQueryResult["data"];
  isError: boolean;
  isLoading: boolean;
  title: string;
}

const ChartRenderer: React.FC<ChartRendererProps> = ({
  selectedDataVizType,
  chartConfig,
  chartData,
  isError,
  isLoading,
  title,
}) => {
  const { height, width, componentRef } = useResize();

  const commonProps = {
    chartConfig,
    data: chartData?.rows || [],
    error: isError,
    height,
    loading: isLoading,
    title,
    totalRows: chartData?.rowCount ?? 0,
    width,
  };

  const renderChart = () => {
    switch (selectedDataVizType) {
      case DataVizTypes.PIE_CHART:
        return <MemoizedPieChart {...commonProps} />;

      case DataVizTypes.BAR_CHART:
        return <MemoizedBarChart {...commonProps} />;

      case DataVizTypes.LINE_CHART:
        return <MemoizedLineChart {...commonProps} />;

      case DataVizTypes.SCATTER_CHART:
        return <MemoizedScatterChart {...commonProps} />;

      case DataVizTypes.ERROR:
        return <ChartErrorState />;

      default:
        return null;
    }
  };

  return (
    <Flex alignItems="center" flexDirection="column" flexGrow={1}>
      <Flex flexGrow={1} justifyContent="center" ref={componentRef} width="100%">
        {commonProps.height > 0 ? renderChart() : null}
      </Flex>
      {commonProps.totalRows > CHART_DATA_LIMIT ? <ChartWarning /> : null}
    </Flex>
  );
};

export { ChartRenderer };
