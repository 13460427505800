import { VisualizationTypesEnum } from "@doowii-types/viz";
import { I18n } from "@lingui/core";
import { getNewChartConfig } from "@services/webserver/openai";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";

import { callSequalizer, fetchFollowUpPrompts } from "../api/sequalizer";
import { addToChatHistory, getSourceType } from "../services/firebase";
import { Result, Satisfaction } from "../types/chat";
import { UserDocument } from "../types/user";
import {
  DefaultError,
  ErrorType,
  QuestionTypeEnum,
  SequalizerErrorKey,
  sequalizerErrorMessagesMap,
} from "./Doowii.i";
import { fetchStreamEvent } from "./StreamEvent";

export class Doowii {
  i18n: I18n;

  setLoading: (loading: boolean) => void;

  setAllResults: Function;

  setAnswer: Function;

  setStreamLoading: (loading: boolean) => void;

  user: UserDocument;

  threadId: string;

  chat_id: string | null;

  chat_start_time: number | null;

  allResults: Result[];

  threads: any;

  constructor(
    i18n,
    setLoading,
    setAllResults,
    setAnswer,
    setStreamLoading,
    user,
    threadId,
    allResults,
    threads
  ) {
    this.i18n = i18n;
    this.setLoading = setLoading;
    this.setAllResults = setAllResults;
    this.setAnswer = setAnswer;
    this.setStreamLoading = setStreamLoading;
    this.user = user;
    this.threadId = threadId;
    this.chat_id = null;
    this.chat_start_time = null;
    this.allResults = allResults;
    this.threads = threads;
  }

  async chat({
    query,
    index,
    recentMessages = [] as { query: string; sql: string; answer: string }[],
    questionType = QuestionTypeEnum.USER,
  }) {
    try {
      this.initializeChat(query, index, questionType);
      await this.getSqlAndAnswer(query, index, recentMessages, questionType);
    } catch (error) {
      await this.handleError(error, query);
    }
  }

  initializeChat(query, index, questionType) {
    this.chat_start_time = performance.now();
    this.chat_id =
      questionType === QuestionTypeEnum.REGENERATE ? this.allResults[index].id : uuidv4();
    this.setLoading(true);

    const newResult = {
      id: this.chat_id,
      query,
      title: query,
      satisfied: "UNKNOWN",
      loading: true,
    };

    if (index === 0) {
      this.setAllResults([newResult]);
      this.setAnswer([""]);
    } else {
      this.setAllResults((prevResults) => [...prevResults, newResult]);
      this.setAnswer((prevAnswers) => [...prevAnswers, ""]);
    }

    setTimeout(() => {
      const element = document.getElementById(this.chat_id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 100);
  }

  async getSqlAndAnswer(query, index, recent_messages, questionType = "user") {
    const source_type = await getSourceType(this.user.organization);

    const response = await callSequalizer(
      this.user.organization,
      query,
      this.user.email,
      recent_messages,
      this.threadId,
      this.chat_id,
      source_type,
      "query",
      questionType
    );
    // TODO: add types
    let chartConfig = { suggestion: "TABLE", columns: [], column_types: {} };

    try {
      chartConfig = await getNewChartConfig(response.sql, query);
    } catch (error) {
      console.error("Error in getNewChartConfig", error);
    }

    await this.cleanUp({
      index,
      visualisation: response.sql ? VisualizationTypesEnum.TABLE : VisualizationTypesEnum.NO_SQL,
      query,
      sql: response.sql,
      chartConfig,
      recent_messages,
      questionType: questionType as QuestionTypeEnum,
    });
  }

  async handleError(error, query) {
    console.error("Error in chat", error);
    let err = DefaultError;
    if (error.name in sequalizerErrorMessagesMap) {
      err = {
        message: sequalizerErrorMessagesMap[error.name as SequalizerErrorKey],
        name: error.name,
      };
    }

    await this.cleanUp({
      visualisation: VisualizationTypesEnum.ERROR,
      query,
      index: this.allResults.length,
      sql: "",
      visualisationArray: [],
      error: err,
    });
  }

  async cleanUp({
    index,
    visualisation,
    query,
    sql,
    visualisationArray = [],
    error = {} as ErrorType,
    chartConfig = null,
    recent_messages = [],
    questionType = QuestionTypeEnum.USER,
  }) {
    setTimeout(() => {
      const element = document.getElementById(this.chat_id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
    const chatEndTime = performance.now();
    const duration = chatEndTime - this.chat_start_time;

    const apolloSense = chartConfig ?? {
      suggestion: visualisation || VisualizationTypesEnum.TABLE,
      columns: [],
      column_types: {},
    };
    const newResult = {
      id: this.chat_id,
      query,
      visualisation:
        visualisation === VisualizationTypesEnum.ERROR ||
        visualisation === VisualizationTypesEnum.NO_SQL
          ? visualisation
          : apolloSense.suggestion,
      visualisationArray,
      title: query,
      satisfied: "UNKNOWN" as Satisfaction,
      error,
      sql,
      timestamp: DateTime.now(),
      latency: parseFloat((duration / 1000).toFixed(2)),
      chartConfig: apolloSense,
      loading: false,
    };

    this.setLoading(false);

    let newAnswer: string = error.message ? error.message : "";

    this.setAllResults((prevResults) =>
      prevResults.map((result, i) => (i === index ? newResult : result))
    );
    this.setAnswer((prevAnswers) =>
      prevAnswers.map((answer, i) => (i === index ? newAnswer : answer))
    );

    let followUpPrompts = [];
    if (Object.keys(error).length === 0) {
      this.setStreamLoading(true);

      // Initiate both promises without awaiting them
      const fetchStreamEventPromise = fetchStreamEvent({
        setAnswer: this.setAnswer,
        index,
        thread_id: this.threadId,
        question_id: this.chat_id,
        recent_messages,
        setStreamLoading: this.setStreamLoading,
      });

      const fetchFollowUpPromptsPromise = fetchFollowUpPrompts({
        org_id: this.user.organization,
        thread_id: this.threadId,
        question_id: this.chat_id,
        recent_messages,
      });

      const results = await Promise.allSettled([
        fetchStreamEventPromise,
        fetchFollowUpPromptsPromise,
      ]);

      const [fetchStreamEventResult, fetchFollowUpPromptsResult] = results;

      if (fetchStreamEventResult.status === "fulfilled") {
        newAnswer = fetchStreamEventResult.value;
      } else {
        newAnswer = sequalizerErrorMessagesMap[fetchStreamEventResult.reason as SequalizerErrorKey];
        this.setStreamLoading(false);

        this.setAnswer((prevAnswers) =>
          prevAnswers.map((answer, i) => (i === index ? newAnswer : answer))
        );
      }

      if (fetchFollowUpPromptsResult.status === "fulfilled") {
        followUpPrompts = fetchFollowUpPromptsResult.value;
      }
    }

    const updatedResult = {
      ...newResult,
      follow_up_prompts: followUpPrompts || [],
    };

    this.setAllResults((prev) => {
      const updatedResults = [...prev];
      updatedResults[index] = updatedResult;
      return updatedResults;
    });

    await addToChatHistory(
      this.threads,
      this.threadId,
      this.user.organization,
      this.user.id,
      query,
      updatedResult,
      newAnswer
    );
  }
}
