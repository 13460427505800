import { ParentDocTypeEnum } from "@api/retriever.i";
import { ChartRenderer } from "@components/Charts/ChartRenderer";
import { DataTable } from "@components/DataTable";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { DataVizType, DataVizTypes } from "@doowii-types/viz";
import { useChartQuery } from "@hooks/useChartQuery";
import { Flex } from "@styled-system/jsx";
import React, { useEffect, useState } from "react";

interface DataContentProps {
  currentResult: Result | Pin;
  selectedDataVizType: DataVizType;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  setSelectedColumns: (selectedColumns: string[]) => void;
  selectedColumns: string[];
}

const DataContent: React.FC<DataContentProps> = ({
  currentResult,
  selectedDataVizType,
  parentDocId,
  parentDocType,
  setSelectedColumns,
  selectedColumns,
}) => {
  const [chartConfig, setChartConfig] = useState({});

  const {
    data: chartData,
    isError,
    isLoading,
  } = useChartQuery({
    sqlQuery: currentResult.sql,
    docId: currentResult.id,
    parentDocId,
    type: parentDocType,
  });

  useEffect(() => {
    const tempConfig = {
      ...currentResult.chartConfig,
      columns: selectedColumns.length > 0 ? selectedColumns : currentResult.chartConfig?.columns,
      column_types: currentResult.chartConfig?.column_types,
    };
    setChartConfig(tempConfig);
  }, [currentResult, selectedColumns]);

  if (selectedDataVizType === DataVizTypes.TABLE) {
    return (
      <DataTable
        parentDocId={parentDocId}
        parentDocType={parentDocType}
        result={currentResult}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
      />
    );
  }

  return (
    <Flex flexDirection="column" minHeight="xl" overflow="hidden" p="4" width="full">
      <ChartRenderer
        chartConfig={chartConfig}
        chartData={chartData}
        isError={isError}
        isLoading={isLoading}
        selectedDataVizType={selectedDataVizType}
        title={currentResult.title}
      />
    </Flex>
  );
};

export { DataContent };
