import { ChartErrorState } from "@components/Charts";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import HighchartsReact from "highcharts-react-official";
import { memo } from "react";

import { ChartLoadingSpinner } from "./ChartLoadingSpinner";
import { HighchartsWithAccessibility, sharedChartOptions } from "./utils";

// TODO: fix this any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataRow = Record<string, any>;

// TODO: fix this any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ScatterChart = ({ data, height, width, chartConfig, loading, error }: any) => {
  const { _ } = useLingui();

  if (loading || !chartConfig || !chartConfig.columns || !data) {
    return <ChartLoadingSpinner />;
  }
  if (error) {
    return <ChartErrorState />;
  }

  const xColumn = chartConfig?.columns.find(
    (column: string | number) =>
      chartConfig.column_types[column] === "numerical" && column !== chartConfig.columns[1]
  );
  const yColumn = chartConfig?.columns.find(
    (column: string | number) =>
      chartConfig.column_types[column] === "numerical" && column !== xColumn
  );
  const categoryColumn: string = chartConfig?.columns.find(
    (column: string | number) => chartConfig.column_types[column] === "categorical"
  );

  const series: HighchartsWithAccessibility.SeriesScatterOptions[] = categoryColumn
    ? Array.from(new Set(data.map((item: DataRow) => item[categoryColumn]))).map(
        (category: unknown) => ({
          type: "scatter" as const,
          data: data
            .filter((item: DataRow) => item[categoryColumn] === category)
            .map((item: DataRow) => [item[xColumn], item[yColumn]]),
          marker: {
            symbol: category === "Category1" ? "square" : "circle",
          },
        })
      )
    : [
        {
          type: "scatter",
          data: data.map((item: DataRow) => [item[xColumn], item[yColumn]]),
          marker: {
            symbol: "circle",
          },
        },
      ];

  const options: HighchartsWithAccessibility.Options = {
    ...sharedChartOptions,
    chart: {
      ...sharedChartOptions.chart,
      type: "scatter",
      height,
      width,
      zooming: chartConfig.zooming ?? true,
      style: {
        fontFamily: "inherit",
      },
    },
    title: {
      text: chartConfig.chart_title || _(msg`Scatter Plot Visualization`),
    },
    xAxis: {
      title: {
        text: xColumn ? xColumn : _(msg`X-Axis Data`),
      },
      crosshair: chartConfig.x_axis_crosshair ?? true,
    },
    yAxis: {
      title: {
        text: yColumn ? yColumn : _(msg`Y-Axis Data`),
      },
      crosshair: chartConfig.y_axis_crosshair ?? true,
    },
    legend: {
      layout: chartConfig.legend_layout || "vertical",
      align: "right",
      verticalAlign: chartConfig.legend_vertical_align || "top",
      floating: chartConfig.legend_floating ?? true,
    },
    series,
    credits: {
      enabled: false,
    },
    tooltip: {
      headerFormat: "<b>{series.name}</b><br>",
      pointFormat: `${xColumn}: {point.x}<br>${yColumn}: {point.y} `,
      enabled: chartConfig.tooltip ?? true,
    },
  };

  return <HighchartsReact highcharts={HighchartsWithAccessibility} options={options} />;
};

const MemoizedScatterChart = memo(
  ScatterChart,
  (prevProps, nextProps) =>
    prevProps.data === nextProps.data &&
    prevProps.height === nextProps.height &&
    prevProps.width === nextProps.width &&
    prevProps.chartConfig === nextProps.chartConfig &&
    prevProps.totalRows === nextProps.totalRows
);

export { MemoizedScatterChart };
