import { CHART_DATA_LIMIT } from "@constants/Limits";
import { Trans } from "@lingui/macro";
import { Box } from "@styled-system/jsx";

const ChartWarning = () => (
  <Box
    css={{
      color: "foreground.base",
      textStyle: "paragraph_12",
    }}
  >
    <Trans>
      We used the first {CHART_DATA_LIMIT} data points to create the chart. To view the full
      dataset, please use the table view.
    </Trans>
  </Box>
);

export { ChartWarning };
