import { ChartErrorState } from "@components/Charts";
import { ChartConfig } from "@doowii-types/chart";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import HighchartsReact from "highcharts-react-official";
import { memo } from "react";

import { ChartLoadingSpinner } from "./ChartLoadingSpinner";
import { HighchartsWithAccessibility, sharedChartOptions } from "./utils";

// TODO: fix this any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataRow = Record<string, any>;

const extractPieData = (data: DataRow[], chartConfig: ChartConfig) => {
  const categoricalColumn =
    chartConfig.columns.find((key) => chartConfig.column_types[key] === "categorical") ?? "";
  const numericalColumn =
    chartConfig.columns.find((key) => chartConfig.column_types[key] === "numerical") ?? "";

  const aggregateData = data.reduce((acc, item) => {
    const category = item[categoricalColumn];
    acc[category] = acc[category] ?? 0;
    acc[category] += item[numericalColumn];
    return acc;
  }, {});

  return Object.entries(aggregateData).map(([name, y]) => ({
    name,
    y,
  }));
};

// TODO: fix this any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PieChart = ({ data, height, width, chartConfig, loading, error, title }: any) => {
  const { _ } = useLingui();
  if (loading || !chartConfig || !chartConfig.columns || !data) {
    return <ChartLoadingSpinner />;
  }
  if (error) {
    return <ChartErrorState />;
  }

  const seriesData = extractPieData(data, chartConfig);

  const options: HighchartsWithAccessibility.Options = {
    ...sharedChartOptions,
    chart: {
      ...sharedChartOptions.chart,
      type: "pie",
      height,
      width,
      plotBackgroundColor: undefined,
      plotBorderWidth: undefined,
      plotShadow: false,
      style: {
        fontFamily: "inherit",
      },
    },
    title: {
      text: chartConfig.chart_title || title || _(msg`Pie Chart`),
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      enabled: chartConfig.tooltip ?? true,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          // eslint-disable-next-line lingui/no-unlocalized-strings
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: _(msg`Share`),
        colorByPoint: true,
        data: seriesData,
        type: "pie",
      } as HighchartsWithAccessibility.SeriesPieOptions,
    ],
    credits: {
      enabled: false,
    },
    legend: {
      layout: chartConfig.legend_layout || "vertical",
      align: "right",
      verticalAlign: chartConfig.legend_vertical_align || "middle",
      floating: chartConfig.legend_floating ?? false,
      labelFormatter(this): string {
        const point = this as Highcharts.Point;
        return `${point.name}: ${point.y?.toFixed(1)}`;
      },
    },
  };

  return <HighchartsReact highcharts={HighchartsWithAccessibility} options={options} />;
};

const MemoizedPieChart = memo(
  PieChart,
  (prevProps, nextProps) =>
    prevProps.data === nextProps.data &&
    prevProps.height === nextProps.height &&
    prevProps.width === nextProps.width &&
    prevProps.chartConfig === nextProps.chartConfig &&
    prevProps.totalRows === nextProps.totalRows &&
    prevProps.title === nextProps.title
);

export { MemoizedPieChart };
