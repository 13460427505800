import { usePinboard } from "@context/pinboard";
import { useModalClose } from "@hooks/useModalClose";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputField,
} from "doowii-ui";
import { useState } from "react";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  triggerId: string;
  chatIdToPin?: string | null;
}

const CreatePinboardDialog: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  triggerId,
  chatIdToPin = null,
}) => {
  useModalClose(isOpen, triggerId);
  const { _ } = useLingui();
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { boards, addNewBoard, pinToBoard } = usePinboard();

  const handleCreatePinboard = async () => {
    setLoading(true);

    if (name.trim().length < 1) {
      setErrorMessage(_(msg`Pinboard name cannot be empty`));
    } else if (
      [...boards.values()].some((board) => board.name.toLowerCase() === name.toLowerCase())
    ) {
      setErrorMessage(_(msg`Pinboard with this name already exists`));
    } else {
      const boardId = await addNewBoard(name);

      if (chatIdToPin) {
        await pinToBoard(chatIdToPin, boardId);
      }
      setIsOpen(false);
    }

    setLoading(false);
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined}>
        <DialogHeader>
          <DialogTitle>
            <Trans>Create a new pinboard</Trans>
          </DialogTitle>
        </DialogHeader>
        <DialogBody css={{ display: "flex", flexDirection: "column", gap: "md" }}>
          <InputField
            disabled={loading}
            errorText={errorMessage}
            label={_(msg`Pinboard name`)}
            onChange={(e) => setName(e.target.value)}
            placeholder={_(msg`Enter pinboard name`)}
            type="text"
            value={name}
          />
        </DialogBody>
        <DialogFooter css={{ border: "none!", p: "xl2", gap: "sm" }}>
          <Button loading={loading} onClick={handleCreatePinboard} size="large" variant="primary">
            <Trans>Create pinboard</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { CreatePinboardDialog };
