import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import { NavigationBar } from "@components/NavigationBar";
import { NotificationsButton } from "@components/NotificationsButton";
import { UserDropdown } from "@components/UserDropdown";
import { PATHS } from "@constants/Paths";
import { Trans } from "@lingui/macro";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  BackgroundBox,
  LoadingLogo,
} from "doowii-ui";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

const AuthLayout = () => {
  const currentPath = window.location.pathname;

  const { authUser, isLoading, userDocument, signOut } = useAuth();
  const isAuthenticating = !authUser && isLoading;

  if (isAuthenticating) {
    return (
      <Flex css={{ h: "100vh", alignItems: "center" }}>
        <LoadingLogo />
      </Flex>
    );
  }

  const isAuthenticated = Boolean(authUser);
  const isRegistered = Boolean(userDocument);
  const isDisabled = userDocument?.registration.status === "disabled";

  if (isAuthenticated && !isRegistered) {
    return <Navigate replace state={{ from: currentPath }} to={PATHS.REGISTER} />;
  } else if (isDisabled) {
    return (
      <AlertDialog defaultOpen>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              <Trans>Account Disabled</Trans>
            </AlertDialogTitle>
            <AlertDialogDescription>
              <Trans>
                Your account has been disabled. Please contact your administrator for assistance.
              </Trans>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={signOut}>
              <Trans>Sign in again</Trans>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  return isAuthenticated ? (
    <Flex
      css={{
        height: "100vh",
      }}
    >
      <NavigationBar />
      <BackgroundBox
        className={css({
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: "6",
        })}
        containerProps={{
          css: {
            w: "full",
            display: "flex",
          },
        }}
      >
        <Flex
          css={{
            alignItems: "center",
            justifyContent: "space-between",
            px: "10",
            py: "6",
          }}
        >
          <Breadcrumb />
          <Flex css={{ gap: 3 }}>
            <NotificationsButton />
            <UserDropdown />
          </Flex>
        </Flex>
        <Flex css={{ flex: 1, flexDirection: "column", alignItems: "center" }}>
          <Outlet />
        </Flex>
      </BackgroundBox>
    </Flex>
  ) : (
    <Navigate replace state={{ from: currentPath }} to={PATHS.SIGN_IN} />
  );
};

export { AuthLayout };
