import { AuthUser } from "@doowii-types/auth";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { StateCreator } from "zustand";

import { Analytics } from "../services/analytics/Analytics";
import {
  createNewUserDocument,
  db,
  initializeAuthListener,
  updateDocumentInFirestore,
  updateLastSignIn,
} from "../services/firebase";
import { UserDocument } from "../types/user";
import { fetchFeatureFlags } from "@services/webserver/featureFlags";

interface AuthSliceInterface {
  authUser: AuthUser | null;
  userDocument: UserDocument | null;
  isLoading: boolean;
  organization: string | null;
  setAuthUser: (authUser: AuthUser | null) => void;
  createNewUser: (userDetails: Partial<UserDocument>) => Promise<UserDocument>;
  updateUserDocument: (userDetails: Partial<UserDocument>) => Promise<void>;
  signOut: () => Promise<void>;
  fetchUserDocument: (userId: string, org?: string) => Promise<UserDocument | null>;
  initializeAuthListener: () => void;
  fetchOrganizationId: (userId: string) => Promise<string | null>;
  fetchOrganizationName: (orgId: string) => Promise<void>;
  featureFlags: string[];
}

const createAuthSlice: StateCreator<AuthSliceInterface> = (set, get) => ({
  authUser: null,
  userDocument: null,
  isLoading: true,
  organization: "",
  featureFlags: [],
  setAuthUser: (authUser: AuthUser | null) => set({ authUser }),
  createNewUser: async (userDetails: Partial<UserDocument>) => {
    const newUserDoc = await createNewUserDocument(
      userDetails.firstName,
      userDetails.lastName,
      userDetails.role,
      userDetails.otherRole,
      get().authUser
    );
    set({ userDocument: newUserDoc });
    return newUserDoc;
  },
  updateUserDocument: async (userDetails: Partial<UserDocument>) => {
    const { organization, authUser } = get();
    if (!organization || !authUser) {
      return;
    }
    await updateDocumentInFirestore(
      `organizations/${organization}/users`,
      authUser.uid,
      userDetails
    ).catch((error) => {
      console.error("Error updating user document:", error);
    });
    set({ userDocument: { ...get().userDocument, ...userDetails } as UserDocument });
  },
  signOut: async () => {
    await signOut(getAuth());
    set({ authUser: null, userDocument: null, isLoading: false });
  },
  fetchOrganizationId: async (userId: string) => {
    const userOrgsDocRef = doc(db, "user_orgs", userId);
    const userOrgsDocSnapshot = await getDoc(userOrgsDocRef);
    if (!userOrgsDocSnapshot.exists()) {
      console.error("User organization document does not exist.");
      return null;
    }
    return userOrgsDocSnapshot.data().organization;
  },
  fetchOrganizationName: async (orgId: string) => {
    const orgsDocRef = doc(db, "organizations", orgId);
    const orgsDocSnapshot = await getDoc(orgsDocRef);
    if (!orgsDocSnapshot.exists()) {
      console.warn("No Organization document found.");
      return;
    }
    set({ organization: orgsDocSnapshot.data().name });
  },
  fetchUserDocument: async (userId, org) => {
    try {
      const orgId = org ?? (await get().fetchOrganizationId(userId));
      if (!orgId) {
        return null;
      }

      const userDocSnapshot = await getDoc(doc(db, "organizations", orgId, "users", userId));
      if (!userDocSnapshot.exists()) {
        console.error("User document does not exist in the specified organization.");
        return null;
      }

      await get().fetchOrganizationName(orgId);

      return userDocSnapshot.data() as UserDocument;
    } catch (error) {
      console.error("Error fetching user document:", error);
      return null;
    }
  },
  initializeAuthListener: () => {
    initializeAuthListener(async (response) => {
      set({ isLoading: true });

      if (response.authUser) {
        set({
          authUser: response.authUser,
          userDocument: response.userDocument,
          organization: response.organization ?? undefined,
          isLoading: false,
        });

        if (response.userDocument) {
          await updateLastSignIn(response.userDocument.organization, response.authUser.uid);
        }
        const flags = await fetchFeatureFlags() ?? [];
        set({ featureFlags: flags });
        Analytics.login(response.authUser.uid);
      } else {
        set({
          authUser: null,
          userDocument: null,
          organization: null,
          isLoading: false,
          featureFlags: []
        });
      }
    });
  },
  
});

export { createAuthSlice, type AuthSliceInterface };

