/**
 * This module contains constants for feature flags stored in Firebase.
 *
 * The constants in this file are intended to match the features
 * available in the 'feature_flag' collection in Firebase. Each feature
 * flag represents a potential feature a user might have access to.
 *
 * Developers should add any new feature flags to this file. Once a feature
 * flag is added here, it can be imported into other parts of the application
 * and used for conditional rendering based on the user's feature access.
 *
 * For example, a component could import a feature flag from this file, and
 * then use that flag to conditionally render certain elements or control
 * certain behaviors. This allows for flexible, granular control of features
 * on a per-user basis.
 *
 * @module features
 */
export const DEV_ACCOUNT = "dev_account";
export const INVESTOR_ACCOUNT = "investor_account";
export const SANDBOX_ACCOUNT = "sandbox_account";
export const DEMO_ACCOUNT = "demo_account";
export const USE_WEBSOCKET = "use_websocket";
export const BEN_REQUEST = "ben_request";
export const HAS_INTEGRATIONS = "has_integrations";
export const DOES_NOT_HAVE_MANAGE_USERS = "does_not_have_manage_users";
export const SAVANT = "savant";
export const CANVAS_USAGE = "CANVAS_USAGE";
export const SHOW_CANVAS_SCHEMA = "show_canvas_schema";
export const APOLLO_DISABLED = "apollo_disabled";
export const APOLLO_V2 = "apollo_v2";
