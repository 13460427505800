import { usePinboard } from "@context/pinboard";
import { useModalClose } from "@hooks/useModalClose";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { stopPropagationWrapper } from "@utils/stopPropagationWrapper";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputField,
} from "doowii-ui";
import { useEffect, useState } from "react";

interface Props {
  isOpen: boolean;
  boardId: string;
  boardName: string;
  triggerId: string;
  setIsOpen: (isOpen: boolean) => void;
}
const EditPinboardNameDialog: React.FC<Props> = ({
  isOpen,
  boardId,
  boardName,
  triggerId,
  setIsOpen,
}) => {
  useModalClose(isOpen, triggerId);
  const { _ } = useLingui();
  const [name, setName] = useState(boardName);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { updateBoardName } = usePinboard();

  useEffect(() => {
    setName(boardName);
  }, [isOpen, boardName]);

  const handleSend = async () => {
    setLoading(true);
    if (name.trim().length < 1) {
      setErrorMessage(_(msg`Pinboard name cannot be empty`));
    } else {
      await updateBoardName(boardId, name);
      setIsOpen(false);
    }
    setLoading(false);
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined} onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>
            <Trans>Edit pinboard name</Trans>
          </DialogTitle>
        </DialogHeader>
        <DialogBody css={{ display: "flex", flexDirection: "column", gap: "md" }}>
          <InputField
            disabled={loading}
            errorText={errorMessage}
            label={_(msg`Pinboard name`)}
            onChange={(e) => setName(e.target.value)}
            placeholder={_(msg`Enter pinboard name`)}
            type="text"
            value={name}
          />
        </DialogBody>
        <DialogFooter css={{ border: "none!", p: "xl2", gap: "sm" }}>
          <Button
            loading={loading}
            onClick={stopPropagationWrapper(handleSend)}
            size="large"
            variant="primary"
          >
            <Trans>Save</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { EditPinboardNameDialog };
