import { CHART_DATA_LIMIT } from "@constants/Limits";
// import { usePinboard } from "@context/pinboard";
import { useQuery } from "@tanstack/react-query";

import { fetchPageData } from "../api/retriever";
import { ParentDocTypeEnum } from "../api/retriever.i";

interface UseChartQueryProps {
  sqlQuery?: string;
  docId?: string;
  parentDocId?: string;
  type: ParentDocTypeEnum;
}

const useChartQuery = ({ sqlQuery, docId, parentDocId, type }: UseChartQueryProps) => {
  const sortModel = JSON.parse(localStorage.getItem(`sortModel-${docId}`) ?? "[]");

  return useQuery({
    queryKey: ["chartData", sqlQuery, docId, sortModel],
    queryFn: () => {
      if (!sqlQuery || !docId) {
        return Promise.reject(new Error("Invalid query parameters"));
      }
      return fetchPageData({
        docId,
        parentDocId,
        parentDocType: type,
        sortModel,
        pageSize: CHART_DATA_LIMIT,
        pageNumber: 0,
      });
    },
    enabled: Boolean(sqlQuery) && Boolean(docId),
  });
};

export { useChartQuery };
