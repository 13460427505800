// src/AlertDialog/AlertDialog.tsx
import { cx, sva } from "@doowii/design-system/css";
import { styled } from "@doowii/design-system/jsx";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import * as React3 from "react";

// src/Button/Button.tsx
import { cva } from "@doowii/design-system/css";
import { Box } from "@doowii/design-system/jsx";
import {
  FontAwesomeIcon as FontAwesomeIcon2
} from "@fortawesome/react-fontawesome";
import React from "react";

// src/Spinner/Spinner.tsx
import { css } from "@doowii/design-system/css";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import {
  FontAwesomeIcon
} from "@fortawesome/react-fontawesome";
import { jsx } from "react/jsx-runtime";
var Spinner = (props) => /* @__PURE__ */ jsx(
  FontAwesomeIcon,
  {
    className: css({ animation: "spin" }),
    icon: faSpinner,
    ...props
  }
);

// src/Button/Button.tsx
import { jsx as jsx2, jsxs } from "react/jsx-runtime";
var buttonRecipe = cva({
  base: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    gap: "sm",
    cursor: "pointer",
    borderWidth: "2px",
    borderStyle: "solid",
    fontWeight: "medium"
  },
  variants: {
    size: {
      small: {
        padding: "md",
        textStyle: "paragraph_16",
        borderRadius: "lg",
        height: "40px"
      },
      medium: {
        py: `md`,
        px: `ld`,
        textStyle: "paragraph_18",
        borderRadius: "xl",
        height: "48px"
      },
      large: {
        py: `md`,
        px: `ld`,
        textStyle: "paragraph_18",
        borderRadius: "xl",
        height: "56px"
      }
    },
    variant: {
      primary: {
        backgroundColor: "background.primary",
        color: "foreground.primary",
        borderColor: "transparent",
        _hover: {
          backgroundColor: "background.hover.primary",
          color: "foreground.hover.primary"
        },
        _active: {
          backgroundColor: "background.active",
          color: "foreground.active"
        }
      },
      secondary: {
        backgroundColor: "background.secondary",
        color: "foreground.secondary",
        borderColor: "border.secondary",
        _hover: {
          backgroundColor: "background.hover.secondary",
          color: "foreground.hover.secondary",
          borderColor: "border.hover.secondary"
        },
        _active: {
          backgroundColor: "background.active"
        }
      },
      tertiary: {
        backgroundColor: "background.tertiary",
        color: "foreground.tertiary",
        borderColor: "transparent",
        _hover: {
          backgroundColor: "background.hover.tertiary",
          color: "foreground.hover.tertiary"
        },
        _active: {
          backgroundColor: "background.active"
        }
      },
      dropdown: {
        backgroundColor: "white",
        color: "base.darkBlue",
        borderColor: "transparent",
        _hover: {
          backgroundColor: "base.blueBrandSecondary"
        },
        _active: {
          backgroundColor: "base.blueBrandTertiary"
        },
        _focus: {
          backgroundColor: "base.blueBrandTertiary"
        }
      },
      text: {
        fontSize: "inherit",
        padding: "0",
        textDecoration: "underline",
        backgroundColor: "transparent",
        color: "foreground.tertiary",
        borderColor: "transparent",
        _hover: {
          color: "base.darkBlue"
        }
      }
    },
    destructive: { true: {} },
    disabled: {
      true: {
        cursor: "not-allowed",
        color: "foreground.disabled",
        backgroundColor: "background.disabled",
        borderColor: "transparent",
        _hover: {
          backgroundColor: "background.disabled",
          color: "foreground.disabled"
        },
        _active: {
          backgroundColor: "background.disabled",
          color: "foreground.disabled"
        }
      }
    },
    full: {
      true: {
        w: "100% !important"
      }
    },
    iconOnly: {
      true: {
        p: "sm",
        justifyContent: "center"
      }
    }
  },
  compoundVariants: [
    {
      variant: "primary",
      destructive: true,
      css: {
        backgroundColor: "background.destructive.primary",
        color: "foreground.destructive.primary",
        borderColor: "border.destructive.primary",
        _hover: {
          backgroundColor: "background.destructive.hover.primary",
          color: "foreground.destructive.hover.primary"
        },
        _active: {
          backgroundColor: "background.destructive.active.primary",
          color: "foreground.destructive.primary"
        }
      }
    },
    {
      variant: "secondary",
      destructive: true,
      css: {
        backgroundColor: "background.destructive.secondary",
        color: "foreground.destructive.secondary",
        borderColor: "border.destructive.secondary",
        _hover: {
          backgroundColor: "background.destructive.hover.secondary",
          color: "foreground.destructive.hover.secondary",
          borderColor: "border.destructive.hover.secondary"
        },
        _active: {
          backgroundColor: "background.destructive.secondary"
        }
      }
    },
    {
      variant: "tertiary",
      destructive: true,
      css: {
        backgroundColor: "background.destructive.tertiary",
        color: "foreground.destructive.tertiary",
        _hover: {
          backgroundColor: "background.destructive.hover.tertiary",
          color: "foreground.destructive.hover.tertiary"
        },
        _active: {
          backgroundColor: "background.destructive.active.tertiary"
        }
      }
    },
    {
      iconOnly: true,
      size: "small",
      css: {
        w: "40px",
        _disabled: {
          backgroundColor: "transparent"
        }
      }
    },
    {
      iconOnly: true,
      size: "medium",
      css: {
        w: "48px",
        _disabled: {
          backgroundColor: "transparent"
        }
      }
    },
    {
      iconOnly: true,
      size: "large",
      css: {
        w: "56px",
        _disabled: {
          backgroundColor: "transparent"
        }
      }
    }
  ],
  defaultVariants: {
    size: "medium",
    variant: "primary"
  }
});
var Button = React.forwardRef(
  ({
    children,
    disabled,
    loading,
    iconOnly,
    iconRight,
    iconLeft,
    className,
    full,
    destructive,
    ...buttonProps
  }, ref) => {
    const showIconLeft = iconLeft && !iconOnly;
    const showIconRight = iconRight && !iconOnly;
    const showSingleSpinner = loading && !iconLeft;
    const buttonClass = buttonRecipe({
      ...buttonProps,
      full,
      iconOnly: Boolean(iconOnly),
      destructive,
      disabled: Boolean(disabled ?? loading)
    });
    return /* @__PURE__ */ jsxs(
      "button",
      {
        className: `${buttonClass} ${className ?? ""}`,
        disabled: disabled ?? loading,
        ref,
        type: "button",
        ...buttonProps,
        children: [
          showSingleSpinner ? /* @__PURE__ */ jsx2(
            Box,
            {
              style: {
                position: "absolute",
                transform: "translateX(-50%)",
                left: "50%"
              },
              children: /* @__PURE__ */ jsx2(Spinner, { size: "xs" })
            }
          ) : null,
          showIconLeft ? /* @__PURE__ */ jsxs(Box, { style: { position: "relative", display: "flex" }, children: [
            /* @__PURE__ */ jsx2(
              FontAwesomeIcon2,
              {
                icon: iconLeft,
                opacity: loading ? 0 : 1,
                size: "xs"
              }
            ),
            loading ? /* @__PURE__ */ jsx2(
              Spinner,
              {
                size: "xs",
                style: {
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: "25%"
                }
              }
            ) : null
          ] }) : null,
          iconOnly ? /* @__PURE__ */ jsx2(FontAwesomeIcon2, { icon: iconOnly, opacity: loading ? 0 : 1 }) : /* @__PURE__ */ jsx2(Box, { style: { opacity: !iconLeft && loading ? 0 : 1 }, children }),
          showIconRight ? /* @__PURE__ */ jsx2(
            FontAwesomeIcon2,
            {
              icon: iconRight,
              opacity: loading ? 0 : 1,
              size: "xs"
            }
          ) : null
        ]
      }
    );
  }
);
Button.displayName = "Button";

// src/utils/style-context.tsx
import * as React2 from "react";
import { jsx as jsx3 } from "react/jsx-runtime";
var createStyleContext = (recipe) => {
  const StyleContext = React2.createContext(null);
  const withProvider3 = (Component, slot, defaultProps) => {
    const Comp = React2.forwardRef((props, ref) => {
      const [variantProps, otherProps] = recipe.splitVariantProps(
        props
      );
      const { className = "", children, ...rest } = otherProps;
      const styles = recipe(variantProps);
      const slotClass = styles[slot];
      const classNames = [
        defaultProps?.className ?? null,
        slotClass,
        className ?? null
      ].filter(Boolean);
      return /* @__PURE__ */ jsx3(StyleContext.Provider, { value: styles, children: /* @__PURE__ */ jsx3(
        Component,
        {
          ref,
          ...defaultProps,
          className: classNames.join(" "),
          ...rest,
          children
        }
      ) });
    });
    Comp.displayName = Component.displayName || Component.name;
    return Comp;
  };
  const withContext3 = (Component, slot, defaultProps) => {
    if (!slot) {
      return Component;
    }
    const Comp = React2.forwardRef(
      ({
        className,
        variant,
        ...rest
      }, ref) => {
        const styles = React2.useContext(StyleContext);
        const slotClass = styles?.[slot];
        const classNames = [
          defaultProps?.className,
          slotClass,
          className
        ].filter(Boolean);
        return /* @__PURE__ */ jsx3(
          Component,
          {
            ref,
            ...defaultProps,
            className: classNames.join(" "),
            variant,
            ...rest
          }
        );
      }
    );
    Comp.displayName = Component.displayName || Component.name;
    return Comp;
  };
  const useSlot = (slot = "") => {
    const styles = React2.useContext(StyleContext);
    return styles?.[slot] ?? "";
  };
  return {
    withProvider: withProvider3,
    withContext: withContext3,
    useSlot
  };
};

// src/AlertDialog/AlertDialog.tsx
import { jsx as jsx4, jsxs as jsxs2 } from "react/jsx-runtime";
var alertDialogRecipe = sva({
  className: "alertDialog",
  slots: [
    "root",
    "trigger",
    "portal",
    "overlay",
    "content",
    "header",
    "footer",
    "title",
    "description",
    "action",
    "cancel"
  ],
  base: {
    overlay: {
      position: "fixed",
      inset: "0",
      zIndex: 50,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      // Darker background
      animation: "overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1)",
      textAlign: "center"
    },
    content: {
      position: "fixed",
      left: "50%",
      top: "50%",
      zIndex: 50,
      p: "6",
      display: "flex",
      flexDirection: "column",
      w: "full",
      maxWidth: "lg",
      maxHeight: "85vh",
      // Limit the maximum height
      transform: "translate(-50%, -50%)",
      border: "base",
      bg: "background",
      boxShadow: "lg",
      animation: "contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)",
      "&:focus": { outline: "none" },
      overflow: "hidden",
      alignItems: "center",
      sm: {
        rounded: "lg"
      }
    },
    header: {
      display: "flex",
      flexDirection: "column",
      spaceY: "2",
      textAlign: "center",
      alignItems: "center"
    },
    footer: {
      display: "flex",
      flexDirection: "column-reverse",
      marginTop: "sm",
      sm: {
        marginTop: "xl",
        flexDirection: "row",
        justifyContent: "flex-end",
        spaceX: "md"
      }
    },
    title: {
      textStyle: "lg",
      fontWeight: "semibold"
    },
    description: {
      textStyle: "sm",
      color: "muted.foreground"
    },
    cancel: {
      mt: {
        base: "2",
        sm: "0"
      }
    }
  }
});
var { withProvider, withContext } = createStyleContext(alertDialogRecipe);
var AlertDialogPortal = withContext(
  styled(AlertDialogPrimitive.Portal),
  "portal"
);
var AlertDialogOverlay = withContext(
  styled(AlertDialogPrimitive.Overlay),
  "overlay"
);
var Content2 = React3.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsxs2(AlertDialogPortal, { children: [
  /* @__PURE__ */ jsx4(AlertDialogOverlay, {}),
  /* @__PURE__ */ jsx4(AlertDialogPrimitive.Content, { className, ref, ...props })
] }));
Content2.displayName = AlertDialogPrimitive.Content.displayName;
var Cancel2 = React3.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx4(
  AlertDialogPrimitive.Cancel,
  {
    className: cx(buttonRecipe({ variant: "secondary" }), className),
    ref,
    ...props
  }
));
Cancel2.displayName = AlertDialogPrimitive.Cancel.displayName;
var AlertDialog = withProvider(styled(AlertDialogPrimitive.Root), "root");
var AlertDialogTrigger = withContext(
  styled(AlertDialogPrimitive.Trigger),
  "trigger"
);
var AlertDialogContent = withContext(styled(Content2), "content");
var AlertDialogHeader = withContext(styled("div"), "header");
var AlertDialogFooter = withContext(styled("div"), "footer");
var AlertDialogTitle = withContext(
  styled(AlertDialogPrimitive.Title),
  "title"
);
var AlertDialogDescription = withContext(
  styled(AlertDialogPrimitive.Description),
  "description"
);
var AlertDialogAction = styled(AlertDialogPrimitive.Action, buttonRecipe);
var AlertDialogCancel = withContext(styled(Cancel2), "cancel");

// src/AlertDialog/AlertDialog.stories.tsx
import { css as css3 } from "@doowii/design-system/css";

// ../../node_modules/@fortawesome/free-solid-svg-icons/index.mjs
var faCircleExclamation = {
  prefix: "fas",
  iconName: "circle-exclamation",
  icon: [512, 512, ["exclamation-circle"], "f06a", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24l0 112c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-112c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
};
var faCircleCheck = {
  prefix: "fas",
  iconName: "circle-check",
  icon: [512, 512, [61533, "check-circle"], "f058", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"]
};
var faTrash = {
  prefix: "fas",
  iconName: "trash",
  icon: [448, 512, [], "f1f8", "M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z"]
};
var faCircleInfo = {
  prefix: "fas",
  iconName: "circle-info",
  icon: [512, 512, ["info-circle"], "f05a", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"]
};
var faXmark = {
  prefix: "fas",
  iconName: "xmark",
  icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"]
};
var faClose = faXmark;
var faCheck = {
  prefix: "fas",
  iconName: "check",
  icon: [448, 512, [10003, 10004], "f00c", "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"]
};

// src/CircularIcon/CircularIcon.tsx
import { css as css2 } from "@doowii/design-system/css";
import { Box as Box2 } from "@doowii/design-system/jsx";
import { FontAwesomeIcon as FontAwesomeIcon3 } from "@fortawesome/react-fontawesome";
import { jsx as jsx5 } from "react/jsx-runtime";
var circleStyle = {
  width: "148px",
  height: "148px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "linear-gradient(97.52deg, rgba(246, 205, 60, 0.1) 47.37%, rgba(41, 189, 240, 0.1) 65.41%)",
  border: "2px solid transparent",
  boxShadow: "4px 8px 12px 0px #EFC42814, -4px -8px 12px 0px #2ABDF114",
  position: "relative",
  overflow: "hidden",
  "&:after": {
    content: "''",
    position: "absolute",
    inset: 0,
    borderRadius: "50%",
    padding: "3px",
    width: "full",
    height: "full",
    background: "linear-gradient(97.52deg, #F6CD3C 47.37%, #29BDF0 65.41%)",
    "-webkit-mask": "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    "-webkit-mask-composite": "xor",
    "mask-composite": "exclude",
    "pointer-events": "none"
  }
};
var iconStyle = css2({
  fontSize: "42px",
  rotate: "10deg"
});
var CircularIcon = ({ icon, css: cssProp }) => /* @__PURE__ */ jsx5(Box2, { css: { ...circleStyle, ...cssProp ?? {} }, children: /* @__PURE__ */ jsx5(FontAwesomeIcon3, { className: iconStyle, icon }) });

// src/AlertDialog/AlertDialog.stories.tsx
import { jsx as jsx6, jsxs as jsxs3 } from "react/jsx-runtime";
var Default = {
  render: () => /* @__PURE__ */ jsxs3(AlertDialog, { children: [
    /* @__PURE__ */ jsx6(AlertDialogTrigger, { asChild: true, children: /* @__PURE__ */ jsx6(Button, { children: "Open Alert Dialog" }) }),
    /* @__PURE__ */ jsxs3(AlertDialogContent, { children: [
      /* @__PURE__ */ jsxs3(AlertDialogHeader, { children: [
        /* @__PURE__ */ jsx6(AlertDialogTitle, { children: "Are you sure?" }),
        /* @__PURE__ */ jsx6(AlertDialogDescription, { children: "This action cannot be undone. This will permanently delete your account and remove your data from our servers." })
      ] }),
      /* @__PURE__ */ jsxs3(AlertDialogFooter, { children: [
        /* @__PURE__ */ jsx6(AlertDialogCancel, { children: "Cancel" }),
        /* @__PURE__ */ jsx6(AlertDialogAction, { children: "Continue" })
      ] })
    ] })
  ] })
};
var WithIcon = {
  render: () => /* @__PURE__ */ jsxs3(AlertDialog, { children: [
    /* @__PURE__ */ jsx6(AlertDialogTrigger, { asChild: true, children: /* @__PURE__ */ jsx6(Button, { children: "Open Alert Dialog with Icon" }) }),
    /* @__PURE__ */ jsxs3(AlertDialogContent, { children: [
      /* @__PURE__ */ jsxs3(AlertDialogHeader, { children: [
        /* @__PURE__ */ jsx6(CircularIcon, { css: { marginBottom: "md" }, icon: faTrash }),
        /* @__PURE__ */ jsx6(AlertDialogTitle, { children: "Delete Chat" }),
        /* @__PURE__ */ jsx6(AlertDialogDescription, { children: "Are you sure you want to delete this chat?" })
      ] }),
      /* @__PURE__ */ jsxs3(AlertDialogFooter, { children: [
        /* @__PURE__ */ jsx6(AlertDialogCancel, { children: "Cancel" }),
        /* @__PURE__ */ jsx6(AlertDialogAction, { destructive: true, children: "Delete" })
      ] })
    ] })
  ] })
};

// src/Avatar/Avatar.tsx
import { css as css4, cva as cva2, cx as cx2 } from "@doowii/design-system/css";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as React4 from "react";
import { jsx as jsx7 } from "react/jsx-runtime";
var avatarRecipe = cva2({
  base: {
    pos: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    overflow: "hidden",
    rounded: "full"
  },
  variants: {
    size: {
      small: { w: "24px", h: "24px", textStyle: "paragraph_12" },
      medium: { w: "32px", h: "32px", textStyle: "paragraph_14" },
      large: { w: "40px", h: "40px", textStyle: "paragraph_18" }
    }
  },
  defaultVariants: {
    size: "medium"
  }
});
var Avatar = React4.forwardRef(({ className, size, ...props }, ref) => /* @__PURE__ */ jsx7(
  AvatarPrimitive.Root,
  {
    className: cx2(avatarRecipe({ size }), className),
    ref,
    ...props
  }
));
Avatar.displayName = AvatarPrimitive.Root.displayName;
var AvatarImage = React4.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx7(
  AvatarPrimitive.Image,
  {
    className: cx2(
      css4({ aspectRatio: "square", h: "full", w: "full" }),
      className
    ),
    ref,
    ...props
  }
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;
var AvatarFallback = React4.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx7(
  AvatarPrimitive.Fallback,
  {
    className: cx2(
      css4({
        display: "flex",
        h: "full",
        w: "full",
        alignItems: "center",
        justifyContent: "center",
        rounded: "full",
        bg: "base.blueBrandPrimary",
        color: "white",
        fontWeight: "600"
      }),
      className
    ),
    ref,
    ...props
  }
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

// src/BackgroundBox/BackgroundBox.tsx
import { Box as Box3 } from "@doowii/design-system/jsx";
import { jsx as jsx8, jsxs as jsxs4 } from "react/jsx-runtime";
var BackgroundBox = ({ children, containerProps, ...props }) => /* @__PURE__ */ jsxs4(
  Box3,
  {
    ...containerProps,
    css: {
      position: "relative",
      overflow: "auto",
      ...containerProps?.css
    },
    children: [
      /* @__PURE__ */ jsx8(
        Box3,
        {
          css: {
            position: "fixed",
            background: "#F6F3EB",
            opacity: "20%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
            pointerEvents: "none"
          }
        }
      ),
      /* @__PURE__ */ jsx8(
        Box3,
        {
          css: {
            position: "fixed",
            opacity: "6%",
            top: "-15%",
            left: "-15%",
            width: "70%",
            height: "70%",
            borderRadius: "full",
            background: "linear-gradient(274.7deg, #DD6C19 0.76%, #EFC428 77.58%)",
            zIndex: 0,
            filter: "blur(300px)",
            pointerEvents: "none"
          }
        }
      ),
      /* @__PURE__ */ jsx8(
        Box3,
        {
          css: {
            position: "fixed",
            opacity: "6%",
            bottom: "-15%",
            right: "-15%",
            width: "70%",
            height: "70%",
            borderRadius: "full",
            background: "linear-gradient(141.47deg, #3CA0C3 16.12%, #7564E3 74.96%)",
            filter: "blur(300px)",
            zIndex: 0,
            pointerEvents: "none"
          }
        }
      ),
      /* @__PURE__ */ jsx8(
        Box3,
        {
          ...props,
          css: { maxWidth: "100%", zIndex: 1, position: "relative", ...props.css },
          children
        }
      )
    ]
  }
);

// src/Badge/Badge.tsx
import { css as css6, cva as cva3, cx as cx3 } from "@doowii/design-system/css";
import { HStack } from "@doowii/design-system/jsx";
import {
  faArrowUpRightFromSquare,
  faCircleXmark
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon4 } from "@fortawesome/react-fontawesome";
import React6 from "react";

// src/Tooltip/Tooltip.tsx
import { css as css5 } from "@doowii/design-system/css";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { forwardRef as forwardRef4 } from "react";
import { jsx as jsx9, jsxs as jsxs5 } from "react/jsx-runtime";
var tooltipContentStyles = css5({
  bg: "base.darkBlue",
  color: "base.white",
  rounded: "xs",
  padding: "sm",
  textStyle: "paragraph_14",
  zIndex: 9999999,
  maxWidth: "400px"
});
var tooltipArrowStyles = css5({
  fill: "base.darkBlue"
});
var Tooltip = forwardRef4(
  ({ open, children, side, sideOffset, align, content, ...triggerProps }, ref) => /* @__PURE__ */ jsx9(TooltipPrimitive.Provider, { children: /* @__PURE__ */ jsxs5(TooltipPrimitive.Root, { open, children: [
    /* @__PURE__ */ jsx9(TooltipPrimitive.Trigger, { asChild: true, ...triggerProps, ref, children }),
    /* @__PURE__ */ jsxs5(
      TooltipPrimitive.Content,
      {
        align,
        className: tooltipContentStyles,
        side,
        sideOffset,
        children: [
          content,
          /* @__PURE__ */ jsx9(TooltipPrimitive.Arrow, { className: tooltipArrowStyles })
        ]
      }
    )
  ] }) })
);
Tooltip.displayName = "Tooltip";

// src/Badge/Badge.tsx
import { jsx as jsx10, jsxs as jsxs6 } from "react/jsx-runtime";
var badgeStyles = cva3({
  base: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    rounded: "full",
    p: "1",
    pl: "3",
    textStyle: "paragraph_14",
    fontWeight: "medium",
    gap: "3",
    backgroundColor: "base.lightherGray"
  },
  variants: {
    hasIcons: {
      true: {
        alignItems: "space-between"
      }
    }
  },
  defaultVariants: {
    hasIcons: true
  }
});
var Badge = React6.forwardRef(
  ({ children, className, withIcons = true, onNavigate, onRemove, ...props }, ref) => /* @__PURE__ */ jsxs6(
    "span",
    {
      className: cx3(
        badgeStyles({
          hasIcons: withIcons
        }),
        className
      ),
      ...props,
      ref,
      children: [
        children,
        withIcons ? /* @__PURE__ */ jsxs6(HStack, { gap: "none", children: [
          /* @__PURE__ */ jsx10(Tooltip, { content: "Navigate to the Pin", children: /* @__PURE__ */ jsx10(
            FontAwesomeIcon4,
            {
              className: css6({
                w: "6",
                h: "6",
                cursor: "pointer"
              }),
              icon: faArrowUpRightFromSquare,
              onClick: onNavigate,
              size: "sm"
            }
          ) }),
          /* @__PURE__ */ jsx10(Tooltip, { content: "Remove from Pinboard", children: /* @__PURE__ */ jsx10(
            FontAwesomeIcon4,
            {
              className: css6({
                w: "6",
                h: "6",
                cursor: "pointer"
              }),
              icon: faCircleXmark,
              onClick: onRemove,
              size: "sm"
            }
          ) })
        ] }) : null
      ]
    }
  )
);
Badge.displayName = "Badge";

// src/Breadcrumb/Breadcrumb.tsx
import { css as css7, cx as cx4 } from "@doowii/design-system/css";
import { faChevronRight, faEllipsis } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon5 } from "@fortawesome/react-fontawesome";

// ../../node_modules/@radix-ui/react-slot/dist/index.mjs
import * as React8 from "react";

// ../../node_modules/@radix-ui/react-compose-refs/dist/index.mjs
import * as React7 from "react";
function setRef(ref, value) {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref !== null && ref !== void 0) {
    ref.current = value;
  }
}
function composeRefs(...refs) {
  return (node) => refs.forEach((ref) => setRef(ref, node));
}
function useComposedRefs(...refs) {
  return React7.useCallback(composeRefs(...refs), refs);
}

// ../../node_modules/@radix-ui/react-slot/dist/index.mjs
import { Fragment, jsx as jsx11 } from "react/jsx-runtime";
var Slot = React8.forwardRef((props, forwardedRef) => {
  const { children, ...slotProps } = props;
  const childrenArray = React8.Children.toArray(children);
  const slottable = childrenArray.find(isSlottable);
  if (slottable) {
    const newElement = slottable.props.children;
    const newChildren = childrenArray.map((child) => {
      if (child === slottable) {
        if (React8.Children.count(newElement) > 1) return React8.Children.only(null);
        return React8.isValidElement(newElement) ? newElement.props.children : null;
      } else {
        return child;
      }
    });
    return /* @__PURE__ */ jsx11(SlotClone, { ...slotProps, ref: forwardedRef, children: React8.isValidElement(newElement) ? React8.cloneElement(newElement, void 0, newChildren) : null });
  }
  return /* @__PURE__ */ jsx11(SlotClone, { ...slotProps, ref: forwardedRef, children });
});
Slot.displayName = "Slot";
var SlotClone = React8.forwardRef((props, forwardedRef) => {
  const { children, ...slotProps } = props;
  if (React8.isValidElement(children)) {
    const childrenRef = getElementRef(children);
    return React8.cloneElement(children, {
      ...mergeProps(slotProps, children.props),
      // @ts-ignore
      ref: forwardedRef ? composeRefs(forwardedRef, childrenRef) : childrenRef
    });
  }
  return React8.Children.count(children) > 1 ? React8.Children.only(null) : null;
});
SlotClone.displayName = "SlotClone";
var Slottable = ({ children }) => {
  return /* @__PURE__ */ jsx11(Fragment, { children });
};
function isSlottable(child) {
  return React8.isValidElement(child) && child.type === Slottable;
}
function mergeProps(slotProps, childProps) {
  const overrideProps = { ...childProps };
  for (const propName in childProps) {
    const slotPropValue = slotProps[propName];
    const childPropValue = childProps[propName];
    const isHandler = /^on[A-Z]/.test(propName);
    if (isHandler) {
      if (slotPropValue && childPropValue) {
        overrideProps[propName] = (...args) => {
          childPropValue(...args);
          slotPropValue(...args);
        };
      } else if (slotPropValue) {
        overrideProps[propName] = slotPropValue;
      }
    } else if (propName === "style") {
      overrideProps[propName] = { ...slotPropValue, ...childPropValue };
    } else if (propName === "className") {
      overrideProps[propName] = [slotPropValue, childPropValue].filter(Boolean).join(" ");
    }
  }
  return { ...slotProps, ...overrideProps };
}
function getElementRef(element) {
  let getter = Object.getOwnPropertyDescriptor(element.props, "ref")?.get;
  let mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.ref;
  }
  getter = Object.getOwnPropertyDescriptor(element, "ref")?.get;
  mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.props.ref;
  }
  return element.props.ref || element.ref;
}

// src/Breadcrumb/Breadcrumb.tsx
import * as React9 from "react";
import { jsx as jsx12, jsxs as jsxs7 } from "react/jsx-runtime";
var Breadcrumb = React9.forwardRef(({ ...props }, ref) => /* @__PURE__ */ jsx12("nav", { "aria-label": "breadcrumb", ref, ...props }));
Breadcrumb.displayName = "Breadcrumb";
var BreadcrumbList = React9.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx12(
  "ol",
  {
    className: cx4(
      css7({
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "1.5",
        overflowWrap: "break-word",
        textStyle: "paragraph_16",
        fontWeight: "500",
        lineHeight: "sm",
        sm: { gap: "2.5" }
      }),
      className
    ),
    ref,
    ...props
  }
));
BreadcrumbList.displayName = "BreadcrumbList";
var BreadcrumbItem = React9.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx12(
  "li",
  {
    className: cx4(
      css7({ display: "inline-flex", alignItems: "center", gap: "1.5" }),
      className
    ),
    ref,
    ...props
  }
));
BreadcrumbItem.displayName = "BreadcrumbItem";
var BreadcrumbLink = React9.forwardRef(({ asChild, className, ...props }, ref) => {
  const Comp = asChild ? Slot : "a";
  return /* @__PURE__ */ jsx12(
    Comp,
    {
      className: cx4(
        css7({
          transitionProperty: "color, background-color, border-color, text-decoration-color, fill, stroke",
          transitionTimingFunction: "colors",
          transitionDuration: "colors"
        }),
        className
      ),
      ref,
      ...props
    }
  );
});
BreadcrumbLink.displayName = "BreadcrumbLink";
var BreadcrumbPage = React9.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx12(
  "span",
  {
    "aria-current": "page",
    "aria-disabled": "true",
    className: cx4(css7({ fontWeight: "normal" }), className),
    ref,
    role: "link",
    ...props
  }
));
BreadcrumbPage.displayName = "BreadcrumbPage";
var BreadcrumbSeparator = ({
  children,
  className,
  ...props
}) => /* @__PURE__ */ jsx12(
  "li",
  {
    "aria-hidden": "true",
    className: cx4("[&>svg]:size-3.5", className),
    role: "presentation",
    ...props,
    children: children ?? /* @__PURE__ */ jsx12(FontAwesomeIcon5, { icon: faChevronRight })
  }
);
BreadcrumbSeparator.displayName = "BreadcrumbSeparator";
var BreadcrumbEllipsis = ({
  className,
  ...props
}) => /* @__PURE__ */ jsxs7(
  "span",
  {
    "aria-hidden": "true",
    className: cx4(
      css7({
        display: "flex",
        h: "9",
        w: "9",
        alignItems: "center",
        justifyContent: "center"
      }),
      className
    ),
    role: "presentation",
    ...props,
    children: [
      /* @__PURE__ */ jsx12(FontAwesomeIcon5, { className: css7({ h: "4", w: "4" }), icon: faEllipsis }),
      /* @__PURE__ */ jsx12(
        "span",
        {
          className: css7({
            pos: "absolute",
            w: "sr.only",
            h: "sr.only",
            p: "sr.only",
            m: "sr.only",
            overflow: "hidden",
            clip: "rect(0, 0, 0, 0)",
            whiteSpace: "nowrap",
            borderWidth: "0"
          }),
          children: "More"
        }
      )
    ]
  }
);
BreadcrumbEllipsis.displayName = "BreadcrumbElipssis";

// src/Cards/Card.tsx
import { css as css8, cva as cva4 } from "@doowii/design-system/css";
import React10 from "react";
var cardRecipe = cva4({
  base: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "ld",
    bg: "base.white",
    p: "ld",
    rounded: "xl",
    border: "1px solid token(colors.base.lightGray)",
    shadow: "regular",
    w: "full",
    textAlign: "left",
    color: "base.darkBlue"
  },
  variants: {
    variant: {
      default: {},
      button: {
        cursor: "pointer",
        _hover: {
          shadow: "hover"
        }
      },
      flat: {
        shadow: "none",
        _hover: {
          shadow: "none"
        }
      }
    }
  },
  defaultVariants: {
    variant: "default"
  }
});
var Card = React10.forwardRef(
  ({ children, variant, label, onClick, css: cssProp, ...props }, ref) => {
    const cardClass = css8(cardRecipe.raw({ variant }), cssProp);
    const CardComponent = variant === "button" ? "button" : "div";
    return React10.createElement(
      CardComponent,
      {
        "aria-label": label,
        className: cardClass,
        onClick: variant === "button" ? onClick : void 0,
        ref,
        type: variant === "button" ? "button" : void 0,
        ...props
      },
      children
    );
  }
);
Card.displayName = "Card";

// src/Checkbox/Checkbox.tsx
import { css as css9, cx as cx5 } from "@doowii/design-system/css";
import { Flex, Box as Box4 } from "@doowii/design-system/jsx";
import { FontAwesomeIcon as FontAwesomeIcon6 } from "@fortawesome/react-fontawesome";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as React11 from "react";
import { jsx as jsx13, jsxs as jsxs8 } from "react/jsx-runtime";
var Checkbox = React11.forwardRef(({ className, variant = "primary", secondaryLabel, ...props }, ref) => /* @__PURE__ */ jsxs8(
  Flex,
  {
    css: {
      alignItems: variant === "expanded" ? "center" : "flex-start",
      gap: "sm",
      p: variant === "expanded" ? "md" : "sm",
      pr: "md",
      width: variant === "expanded" ? "100%" : "fit-content",
      bg: variant === "secondary" ? "base.blueBrandSecondary" : "base.white",
      color: variant === "secondary" ? "base.blueBrandPrimary" : "base.darkBlue",
      rounded: variant === "expanded" ? "20px" : "sm",
      shadow: variant === "expanded" ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none"
    },
    children: [
      /* @__PURE__ */ jsx13(
        CheckboxPrimitive.Root,
        {
          className: cx5(
            css9({
              cursor: "pointer",
              h: "4",
              w: "4",
              rounded: "3px",
              borderWidth: "2px",
              _focusVisible: { ring: "none", ringOffset: "none", shadow: "1" },
              _disabled: { cursor: "not-allowed", opacity: "0.5" },
              _checked: {
                bg: variant === "secondary" ? "base.blueBrandPrimary" : "#117DA3",
                borderColor: variant === "secondary" ? "base.blueBrandPrimary" : "#117DA3",
                color: "white"
              }
            }),
            className
          ),
          ref,
          ...props,
          children: /* @__PURE__ */ jsx13(
            CheckboxPrimitive.Indicator,
            {
              className: cx5(
                css9({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "current"
                })
              ),
              children: /* @__PURE__ */ jsx13(FontAwesomeIcon6, { icon: faCheck, size: "xs" })
            }
          )
        }
      ),
      variant === "expanded" ? /* @__PURE__ */ jsxs8(Flex, { direction: "column", children: [
        /* @__PURE__ */ jsx13(
          "label",
          {
            className: css9({
              textStyle: "label_16",
              lineHeight: "none",
              fontWeight: "bold",
              _peerDisabled: { cursor: "not-allowed", opacity: "0.7" }
            }),
            htmlFor: props.id,
            children: props.label
          }
        ),
        secondaryLabel && /* @__PURE__ */ jsx13(
          Box4,
          {
            css: {
              textStyle: "label_14",
              color: "base.gray",
              mt: "2px"
            },
            children: secondaryLabel
          }
        )
      ] }) : /* @__PURE__ */ jsx13(
        "label",
        {
          className: css9({
            textStyle: "label_16",
            lineHeight: "none",
            fontWeight: "medium",
            _peerDisabled: { cursor: "not-allowed", opacity: "0.7" }
          }),
          htmlFor: props.id,
          children: props.label
        }
      )
    ]
  }
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

// src/CircularCounter/CircularCounter.tsx
import { Box as Box5 } from "@doowii/design-system/jsx";

// src/Label/Label.tsx
import { cva as cva5, cx as cx6 } from "@doowii/design-system/css";
import { jsx as jsx14 } from "react/jsx-runtime";
var labelRecipe = cva5({
  base: {
    fontWeight: 500
  },
  variants: {
    level: {
      1: {
        textStyle: "label_14"
      },
      2: {
        textStyle: "label_16"
      },
      3: {
        textStyle: "label_18"
      },
      4: {
        textStyle: "label_20"
      }
    },
    variant: {
      primary: {
        color: "foreground.base"
      },
      constrast: {
        color: "base.blueBrandPrimary"
      }
    }
  },
  defaultVariants: {
    variant: "primary",
    level: 2
  }
});
var Label = ({
  level,
  variant,
  children,
  ...props
}) => /* @__PURE__ */ jsx14(
  "label",
  {
    ...props,
    className: cx6(labelRecipe({ level, variant }), props.className),
    children
  }
);

// src/CircularCounter/CircularCounter.tsx
import { jsx as jsx15 } from "react/jsx-runtime";
var circleStyle2 = {
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px solid transparent",
  position: "relative",
  overflow: "hidden",
  "&:after": {
    content: "''",
    position: "absolute",
    inset: 0,
    borderRadius: "50%",
    padding: "3px",
    width: "full",
    height: "full",
    background: "linear-gradient(97.52deg, #F6CD3C 47.37%, #29BDF0 65.41%)",
    "-webkit-mask": "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    "-webkit-mask-composite": "xor",
    "mask-composite": "exclude",
    "pointer-events": "none"
  }
};
var CircularCounter = ({ counter }) => /* @__PURE__ */ jsx15(Box5, { css: circleStyle2, children: /* @__PURE__ */ jsx15(Label, { level: counter.toString().length > 2 ? 1 : 2, children: counter }) });

// src/ConfirmationDialog/ConfirmationDialog.tsx
import { Flex as Flex2 } from "@doowii/design-system/jsx";
import { DialogTitle as DialogTitle2 } from "@radix-ui/react-dialog";

// ../../node_modules/@radix-ui/react-visually-hidden/dist/index.mjs
import * as React13 from "react";

// ../../node_modules/@radix-ui/react-primitive/dist/index.mjs
import * as React12 from "react";
import * as ReactDOM from "react-dom";
import { jsx as jsx16 } from "react/jsx-runtime";
var NODES = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
];
var Primitive = NODES.reduce((primitive, node) => {
  const Node = React12.forwardRef((props, forwardedRef) => {
    const { asChild, ...primitiveProps } = props;
    const Comp = asChild ? Slot : node;
    if (typeof window !== "undefined") {
      window[Symbol.for("radix-ui")] = true;
    }
    return /* @__PURE__ */ jsx16(Comp, { ...primitiveProps, ref: forwardedRef });
  });
  Node.displayName = `Primitive.${node}`;
  return { ...primitive, [node]: Node };
}, {});

// ../../node_modules/@radix-ui/react-visually-hidden/dist/index.mjs
import { jsx as jsx17 } from "react/jsx-runtime";
var NAME = "VisuallyHidden";
var VisuallyHidden = React13.forwardRef(
  (props, forwardedRef) => {
    return /* @__PURE__ */ jsx17(
      Primitive.span,
      {
        ...props,
        ref: forwardedRef,
        style: {
          // See: https://github.com/twbs/bootstrap/blob/master/scss/mixins/_screen-reader.scss
          position: "absolute",
          border: 0,
          width: 1,
          height: 1,
          padding: 0,
          margin: -1,
          overflow: "hidden",
          clip: "rect(0, 0, 0, 0)",
          whiteSpace: "nowrap",
          wordWrap: "normal",
          ...props.style
        }
      }
    );
  }
);
VisuallyHidden.displayName = NAME;
var Root5 = VisuallyHidden;

// src/Dialog/Dialog.tsx
import { css as css10, sva as sva2 } from "@doowii/design-system/css";
import { styled as styled2 } from "@doowii/design-system/jsx";
import { FontAwesomeIcon as FontAwesomeIcon7 } from "@fortawesome/react-fontawesome";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as React14 from "react";
import { jsx as jsx18, jsxs as jsxs9 } from "react/jsx-runtime";
var dialog = sva2({
  className: "dialog",
  slots: [
    "root",
    "trigger",
    "portal",
    "overlay",
    "close",
    "content",
    "header",
    "body",
    "footer",
    "title",
    "description",
    "actionButtons"
  ],
  base: {
    overlay: {
      position: "fixed",
      inset: "0",
      zIndex: 50,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      // Darker background
      animation: "overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1)"
    },
    close: {
      position: "absolute",
      right: "6",
      top: "5",
      rounded: "sm",
      transition: "opacity",
      cursor: "pointer",
      _hover: {
        opacity: "1"
      },
      _focus: {
        outline: "2px solid transparent",
        outlineOffset: "2px"
      },
      _disabled: {
        pointerEvents: "none"
      }
    },
    content: {
      position: "fixed",
      left: "50%",
      top: "50%",
      zIndex: 50,
      display: "flex",
      flexDirection: "column",
      w: "full",
      maxWidth: "lg",
      maxHeight: "85vh",
      // Limit the maximum height
      transform: "translate(-50%, -50%)",
      border: "base",
      bg: "background",
      boxShadow: "lg",
      animation: "contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)",
      "&:focus": { outline: "none" },
      overflow: "hidden",
      rounded: "lg"
    },
    header: {
      display: "flex",
      flexDirection: "column",
      paddingX: "6",
      paddingY: "4",
      borderBottom: "1px solid",
      borderColor: "gray.200",
      flexShrink: 0
      // Prevent header from shrinking
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      textStyle: "lg",
      fontWeight: "semibold",
      marginBottom: "2",
      paddingRight: "8"
    },
    description: {
      textStyle: "sm",
      color: "muted.foreground"
    },
    actionButtons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      spaceX: "2",
      cursor: "pointer"
    },
    body: {
      flex: "1 1 auto",
      overflowY: "auto",
      padding: "6"
    },
    footer: {
      display: "flex",
      justifyContent: "flex-end",
      paddingX: "6",
      paddingY: "6",
      borderTop: "1px solid",
      borderColor: "gray.200",
      flexShrink: 0,
      // Prevent footer from shrinking
      spaceX: "2",
      sm: {
        flexDirection: "row",
        justifyContent: "flex-end"
      }
    }
  },
  variants: {
    variant: {
      default: {},
      slideRight: {
        content: {
          left: "auto",
          right: "0",
          top: "0",
          bottom: "0",
          maxWidth: "80%",
          height: "100%",
          maxHeight: "100vh",
          transform: "none",
          animation: "slideInFromRight 300ms cubic-bezier(0.16, 1, 0.3, 1)"
        }
      },
      fullScreen: {
        content: {
          top: "50%",
          transform: "translateY(-50%)",
          left: "xl",
          right: "xl",
          bottom: "xl",
          width: "auto",
          height: "fit-content",
          maxWidth: "85vw",
          maxHeight: "85vh",
          justifySelf: "center"
        }
      }
    }
  }
});
var { withProvider: withProvider2, withContext: withContext2 } = createStyleContext(dialog);
var DialogPortal = withContext2(styled2(DialogPrimitive.Portal), "portal");
var DialogOverlay = withContext2(styled2(DialogPrimitive.Overlay), "overlay");
var DialogClose = withContext2(styled2(DialogPrimitive.Close), "close");
var DialogActionButtons = withContext2(styled2("div"), "actionButtons");
var Content5 = React14.forwardRef(({ children, ...props }, ref) => {
  const styles = dialog({ variant: props.variant });
  return /* @__PURE__ */ jsxs9(DialogPortal, { children: [
    /* @__PURE__ */ jsx18(DialogOverlay, { className: styles.overlay }),
    /* @__PURE__ */ jsxs9(DialogPrimitive.Content, { className: styles.content, ref, ...props, children: [
      children,
      /* @__PURE__ */ jsxs9(DialogClose, { className: styles.close, children: [
        /* @__PURE__ */ jsx18(FontAwesomeIcon7, { icon: faXmark, size: "lg" }),
        /* @__PURE__ */ jsx18("span", { className: css10({ srOnly: true }), children: "Close" })
      ] })
    ] })
  ] });
});
Content5.displayName = DialogPrimitive.Content.displayName;
var Dialog = withProvider2(styled2(DialogPrimitive.Root), "root");
var DialogTrigger = withContext2(DialogPrimitive.Trigger, "trigger");
var DialogContent = Content5;
var DialogHeader = withContext2(styled2("div"), "header");
var DialogTitle = withContext2(DialogPrimitive.Title, "title");
var DialogDescription = withContext2(
  styled2(DialogPrimitive.Description),
  "description"
);
var DialogBody = withContext2(styled2("div"), "body");
var DialogFooter = withContext2(styled2("div"), "footer");

// src/Heading/Heading.tsx
import { css as css11, cva as cva6 } from "@doowii/design-system/css";
import { jsx as jsx19 } from "react/jsx-runtime";
var headingRecipe = cva6({
  base: {
    fontWeight: 600
  },
  variants: {
    level: {
      1: {
        textStyle: "title_h1"
      },
      2: {
        textStyle: "title_h2"
      },
      3: {
        textStyle: "title_h3"
      }
    },
    variant: {
      primary: {
        color: "foreground.base"
      },
      constrast: {
        color: "base.blueBrandPrimary"
      }
    }
  },
  defaultVariants: {
    variant: "primary",
    level: 2
  }
});
var Heading = ({
  level = 2,
  variant,
  as,
  children,
  css: cssProp,
  component,
  ...props
}) => {
  const Tag = as ?? `h${level}`;
  const RenderComponent = component ?? Tag;
  return /* @__PURE__ */ jsx19(
    RenderComponent,
    {
      className: css11(headingRecipe.raw({ level, variant }), cssProp),
      ...props,
      children
    }
  );
};

// src/Text/Text.tsx
import { cva as cva7, cx as cx7 } from "@doowii/design-system/css";
import { jsx as jsx20 } from "react/jsx-runtime";
var textRecipe = cva7({
  base: {},
  variants: {
    level: {
      1: {
        textStyle: "paragraph_12"
      },
      2: {
        textStyle: "paragraph_14"
      },
      3: {
        textStyle: "paragraph_16"
      },
      4: {
        textStyle: "paragraph_18"
      }
    },
    variant: {
      primary: {
        color: "foreground.base"
      },
      constrast: {
        color: "base.blueBrandPrimary"
      }
    }
  },
  defaultVariants: {
    variant: "primary",
    level: 2
  }
});
var Text = ({
  level,
  variant,
  className,
  children,
  ...props
}) => /* @__PURE__ */ jsx20("p", { className: cx7(textRecipe({ level, variant }), className), ...props, children });

// src/ConfirmationDialog/ConfirmationDialog.tsx
import { jsx as jsx21, jsxs as jsxs10 } from "react/jsx-runtime";
var ConfirmationDialog = ({
  isOpen,
  title,
  description,
  confirmationButtonText,
  cancelButtonText,
  isLoadingAction,
  icon,
  destructive,
  setIsOpen,
  onConfirm,
  onCancel
}) => /* @__PURE__ */ jsx21(Dialog, { onOpenChange: setIsOpen, open: isOpen, children: /* @__PURE__ */ jsxs10(DialogContent, { onClick: (e) => e.stopPropagation(), variant: "default", children: [
  /* @__PURE__ */ jsx21(Root5, { children: /* @__PURE__ */ jsx21(DialogTitle2, { children: "Confirmation" }) }),
  /* @__PURE__ */ jsx21(DialogBody, { children: /* @__PURE__ */ jsxs10(
    Flex2,
    {
      css: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "md"
      },
      children: [
        icon ? /* @__PURE__ */ jsx21(CircularIcon, { icon }) : null,
        /* @__PURE__ */ jsxs10(
          Flex2,
          {
            css: {
              alignItems: "center",
              flexDirection: "column",
              gap: "xs"
            },
            children: [
              /* @__PURE__ */ jsx21(Heading, { level: 2, children: title }),
              description ? /* @__PURE__ */ jsx21(Text, { level: 3, children: description }) : null
            ]
          }
        )
      ]
    }
  ) }),
  /* @__PURE__ */ jsxs10(
    DialogFooter,
    {
      css: {
        display: "flex",
        justifyContent: "center!",
        border: "none",
        p: "xl2",
        gap: "sm"
      },
      children: [
        /* @__PURE__ */ jsx21(
          Button,
          {
            destructive,
            loading: isLoadingAction,
            onClick: onConfirm,
            size: "large",
            variant: "primary",
            children: confirmationButtonText ?? "Confirm"
          }
        ),
        /* @__PURE__ */ jsx21(
          Button,
          {
            onClick: (e) => onCancel ? onCancel(e) : setIsOpen(false),
            size: "large",
            variant: "secondary",
            children: cancelButtonText ?? "Cancel"
          }
        )
      ]
    }
  )
] }) });

// src/Dropdown/Dropdown.tsx
import { css as css12, cx as cx8 } from "@doowii/design-system/css";
import {
  faCheck as faCheck2,
  faChevronRight as faChevronRight2,
  faPeriod
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon8 } from "@fortawesome/react-fontawesome";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import * as React15 from "react";
import { jsx as jsx22, jsxs as jsxs11 } from "react/jsx-runtime";
var DropdownMenu = DropdownMenuPrimitive.Root;
var DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
var DropdownMenuGroup = DropdownMenuPrimitive.Group;
var DropdownMenuPortal = DropdownMenuPrimitive.Portal;
var DropdownMenuSub = DropdownMenuPrimitive.Sub;
var DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
var DropdownMenuSubTrigger = React15.forwardRef(({ className, inset, children, ...props }, ref) => /* @__PURE__ */ jsxs11(
  DropdownMenuPrimitive.SubTrigger,
  {
    className: cx8(
      css12({
        display: "flex",
        cursor: "default",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        pl: "2",
        pr: "2",
        pt: "1.5",
        pb: "1.5",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        _hover: { bg: "base.blueBrandSecondary" },
        _focus: { bg: "base.blueBrandSecondary" },
        _active: { bg: "base.blueBrandTertiary" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      inset && css12({ pl: "8" }),
      className
    ),
    ref,
    ...props,
    children: [
      children,
      /* @__PURE__ */ jsx22(
        FontAwesomeIcon8,
        {
          className: css12({ ml: "auto", h: "4", w: "4" }),
          icon: faChevronRight2,
          size: "xs"
        }
      )
    ]
  }
));
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;
var DropdownMenuSubContent = React15.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx22(
  DropdownMenuPrimitive.SubContent,
  {
    className: cx8(
      css12({
        zIndex: 50,
        minW: "8rem",
        overflow: "hidden",
        rounded: "md",
        p: "1",
        shadow: "0px 4px 20px 0px #1539462E",
        bg: "white",
        color: "foreground.base",
        textStyle: "paragraph_16",
        ml: "2"
      }),
      className
    ),
    ref,
    ...props
  }
));
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;
var DropdownMenuContent = React15.forwardRef(({ className, sideOffset = 4, ...props }, ref) => /* @__PURE__ */ jsx22(DropdownMenuPrimitive.Portal, { children: /* @__PURE__ */ jsx22(
  DropdownMenuPrimitive.Content,
  {
    className: cx8(
      css12({
        zIndex: 50,
        minW: "8rem",
        overflow: "hidden",
        rounded: "md",
        p: "1",
        shadow: "0px 4px 20px 0px #1539462E",
        bg: "white",
        color: "foreground.base",
        textStyle: "paragraph_16"
      }),
      className
    ),
    ref,
    sideOffset,
    ...props
  }
) }));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;
var DropdownMenuItem = React15.forwardRef(({ className, inset, ...props }, ref) => /* @__PURE__ */ jsx22(
  DropdownMenuPrimitive.Item,
  {
    className: cx8(
      css12({
        pos: "relative",
        display: "flex",
        gap: "4",
        cursor: "pointer",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        p: "12px",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        _hover: { bg: "base.blueBrandSecondary" },
        _focus: { bg: "base.blueBrandSecondary" },
        _active: { bg: "base.blueBrandTertiary" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      inset && css12({ pl: "8" }),
      className
    ),
    ref,
    ...props
  }
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;
var DropdownMenuCheckboxItem = React15.forwardRef(({ className, children, checked, ...props }, ref) => /* @__PURE__ */ jsxs11(
  DropdownMenuPrimitive.CheckboxItem,
  {
    checked,
    className: cx8(
      css12({
        pos: "relative",
        display: "flex",
        gap: "2",
        cursor: "pointer",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        p: "12px",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        _hover: { bg: "base.blueBrandSecondary" },
        _focus: { bg: "base.blueBrandSecondary" },
        _active: { bg: "base.blueBrandTertiary" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx22(
        FontAwesomeIcon8,
        {
          className: css12({ h: "4", w: "4" }),
          icon: faCheck2,
          size: "xs"
        }
      ),
      children
    ]
  }
));
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;
var DropdownMenuRadioItem = React15.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs11(
  DropdownMenuPrimitive.RadioItem,
  {
    className: cx8(
      css12({
        pos: "relative",
        display: "flex",
        gap: "2",
        cursor: "pointer",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        p: "12px",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        _hover: { bg: "base.blueBrandSecondary" },
        _focus: { bg: "base.blueBrandSecondary" },
        _active: { bg: "base.blueBrandTertiary" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx22(
        FontAwesomeIcon8,
        {
          className: css12({ h: "4", w: "4", mb: "3" }),
          icon: faPeriod
        }
      ),
      children
    ]
  }
));
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;
var DropdownMenuLabel = React15.forwardRef(({ className, inset, ...props }, ref) => /* @__PURE__ */ jsx22(
  DropdownMenuPrimitive.Label,
  {
    className: cx8(
      css12({
        pl: "2",
        pr: "2",
        pt: "1.5",
        pb: "1.5",
        fontSize: "sm",
        lineHeight: "sm",
        fontWeight: "semibold"
      }),
      inset && css12({ pl: "8" }),
      className
    ),
    ref,
    ...props
  }
));
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;
var DropdownMenuSeparator = React15.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx22(
  DropdownMenuPrimitive.Separator,
  {
    className: cx8(
      css12({
        ml: ".mx-1",
        mr: ".mx-1",
        mt: "1",
        mb: "1",
        h: "1px",
        bg: "base.lightGray"
      }),
      className
    ),
    ref,
    ...props
  }
));
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;
var DropdownMenuShortcut = ({
  className,
  ...props
}) => /* @__PURE__ */ jsx22(
  "span",
  {
    className: cx8(
      css12({
        ml: "auto",
        fontSize: "xs",
        letterSpacing: "widest",
        opacity: "0.6"
      }),
      className
    ),
    ...props
  }
);
DropdownMenuShortcut.displayName = "DropdownMenuShortcut";

// src/FeedbackButton/FeedbackButton.tsx
import { cva as cva8 } from "@doowii/design-system/css";
import { faThumbsDown, faThumbsUp } from "@fortawesome/pro-regular-svg-icons";
import {
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid
} from "@fortawesome/pro-solid-svg-icons";
import React16, { useState } from "react";
import { jsx as jsx23 } from "react/jsx-runtime";
var feedBackButtonRecipe = cva8({
  variants: {
    type: {
      positive: {
        color: "complementary.greenPrimary",
        backgroundColor: "white",
        _hover: {
          color: "complementary.greenPrimary",
          backgroundColor: "complementary.greenSecondary"
        }
      },
      negative: {
        color: "complementary.destructivePrimary",
        backgroundColor: "white",
        _hover: {
          color: "complementary.destructivePrimary",
          backgroundColor: "complementary.destructiveSecondary"
        }
      }
    },
    isActive: { true: {} }
  },
  compoundVariants: [
    {
      type: "positive",
      isActive: true,
      css: {
        color: "complementary.greenPrimary",
        backgroundColor: "complementary.greenSecondary"
      }
    },
    {
      type: "negative",
      isActive: true,
      css: {
        color: "complementary.destructivePrimary",
        backgroundColor: "complementary.destructiveSecondary"
      }
    }
  ]
});
var FeedbackButton = React16.forwardRef(
  ({ type, isActive, label, onClick }, ref) => {
    const [isHover, setIsHover] = useState(false);
    const positiveIcon = isHover || isActive ? faThumbsUpSolid : faThumbsUp;
    const negativeIcon = isHover || isActive ? faThumbsDownSolid : faThumbsDown;
    return /* @__PURE__ */ jsx23(Tooltip, { content: label, children: /* @__PURE__ */ jsx23(
      Button,
      {
        "aria-label": label,
        className: feedBackButtonRecipe({ type, isActive }),
        iconOnly: type === "positive" ? positiveIcon : negativeIcon,
        onClick,
        onMouseEnter: () => setIsHover(true),
        onMouseLeave: () => setIsHover(false),
        ref
      }
    ) });
  }
);
FeedbackButton.displayName = "FeedbackButton";

// src/FollowUpCard/FollowUpCard.tsx
import { css as css13, cx as cx9 } from "@doowii/design-system/css";
import { flex } from "@doowii/design-system/patterns";
import { faPaperPlaneTop } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon9 } from "@fortawesome/react-fontawesome";
import { jsx as jsx24, jsxs as jsxs12 } from "react/jsx-runtime";
var followUpCardStyle = cx9(
  flex({
    gap: "ld",
    direction: "row",
    align: "center",
    justify: "start"
  }),
  css13({
    bg: "base.blueBrandTertiary",
    px: "md",
    py: "12px",
    rounded: "xl",
    cursor: "pointer",
    w: "full",
    textAlign: "left",
    color: "base.darkBlue",
    _hover: {
      bg: "base.white"
    }
  })
);
var followUpCardTextStyle = cx9(
  flex({
    grow: 1
  }),
  css13({
    textStyle: "paragraph_16"
  })
);
var followUpCardIconStyle = cx9(
  flex({
    align: "center",
    justify: "center",
    shrink: 0
  }),
  css13({
    w: "32px",
    h: "32px"
  })
);
var FollowUpCard = ({ onClick, children }) => /* @__PURE__ */ jsxs12("button", { className: followUpCardStyle, onClick, type: "button", children: [
  /* @__PURE__ */ jsx24("div", { className: followUpCardTextStyle, children }),
  /* @__PURE__ */ jsx24("div", { className: followUpCardIconStyle, children: /* @__PURE__ */ jsx24(FontAwesomeIcon9, { icon: faPaperPlaneTop }) })
] });

// src/InputField/InputField.tsx
import { css as css14, cva as cva9 } from "@doowii/design-system/css";
import { Box as Box6, Flex as Flex3 } from "@doowii/design-system/jsx";
import { forwardRef as forwardRef12, useId, useMemo, useState as useState2 } from "react";
import { jsx as jsx25, jsxs as jsxs13 } from "react/jsx-runtime";
var DEFAULT_INPUT_SIZE = 20;
var inputFieldRecipe = cva9({
  base: {
    color: "foreground.base",
    outline: "none",
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "border.base",
    fontWeight: 500
  },
  variants: {
    size: {
      small: {
        padding: "sm",
        paddingRight: "xl3",
        textStyle: "paragraph_14",
        borderRadius: "sm"
      },
      medium: {
        padding: "md",
        paddingRight: "xl3",
        textStyle: "paragraph_16",
        borderRadius: "md"
      },
      large: {
        padding: "ld",
        paddingRight: "xl3",
        textStyle: "paragraph_18",
        borderRadius: "lg"
      }
    },
    disabled: {
      true: {
        backgroundColor: "background.disabled",
        borderColor: "border.disabled"
      }
    },
    error: {
      true: {
        borderColor: "border.error",
        "&:focus": {
          borderColor: "border.error"
        }
      }
    }
  },
  defaultVariants: {
    size: "medium"
  }
});
var InputField = forwardRef12(
  ({
    label,
    value,
    size,
    defaultValue,
    onChange,
    helperText,
    errorText,
    disabled,
    icon,
    inputSize,
    id: propId,
    ...props
  }, ref) => {
    const [internalValue, setInternalValue] = useState2(defaultValue ?? "");
    const id = useId();
    const inputId = propId ?? `input-${id}`;
    const handleChange = (e) => {
      onChange?.(e);
      if (value === void 0) {
        setInternalValue(e.target.value);
      }
    };
    const ariaDescribedBy = useMemo(() => {
      const describedBy = [];
      if (errorText) {
        describedBy.push(`${inputId}-error`);
      }
      if (helperText) {
        describedBy.push(`${inputId}-helper`);
      }
      return describedBy.join(" ");
    }, [errorText, helperText, inputId]);
    return /* @__PURE__ */ jsxs13(Flex3, { direction: "column", gap: "sm", children: [
      label.length > 0 ? /* @__PURE__ */ jsx25(Label, { htmlFor: inputId, level: 1, children: label }) : null,
      /* @__PURE__ */ jsxs13(
        Box6,
        {
          className: css14({
            display: "flex",
            alignItems: "center",
            position: "relative"
          }),
          children: [
            /* @__PURE__ */ jsx25(
              "input",
              {
                "aria-describedby": ariaDescribedBy || void 0,
                "aria-invalid": Boolean(errorText),
                className: inputFieldRecipe({
                  size,
                  disabled,
                  error: Boolean(errorText)
                }),
                disabled,
                id: inputId,
                onChange: handleChange,
                ref,
                value: value ?? internalValue,
                ...props,
                size: inputSize ?? DEFAULT_INPUT_SIZE
              }
            ),
            icon ? /* @__PURE__ */ jsx25(
              Box6,
              {
                className: css14({
                  position: "absolute",
                  right: "md",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }),
                children: icon
              }
            ) : null,
            errorText ? /* @__PURE__ */ jsx25(
              "p",
              {
                className: css14({
                  position: "absolute",
                  bottom: "-ld",
                  margin: 0,
                  textStyle: "paragraph_12",
                  color: "foreground.error"
                }),
                id: `${inputId}-error`,
                children: errorText
              }
            ) : null,
            helperText ? /* @__PURE__ */ jsx25(
              "p",
              {
                className: css14({
                  position: "absolute",
                  bottom: errorText ? "-xl2" : "-ld",
                  margin: 0,
                  textStyle: "paragraph_12"
                }),
                id: `${inputId}-helper`,
                children: helperText
              }
            ) : null
          ]
        }
      )
    ] });
  }
);
InputField.displayName = "InputField";

// src/Loading/LoadingLogo.tsx
import { css as css15, cx as cx10 } from "@doowii/design-system/css";
import { jsx as jsx26, jsxs as jsxs14 } from "react/jsx-runtime";
var loadingPageStyles = css15({
  fontSize: "4em",
  textAlign: "center",
  fontWeight: 600,
  color: "#003a47",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1
});
var letterStyles = css15({
  display: "inline-block",
  position: "relative"
});
var bounceStyles = css15({
  display: "inline-block",
  animation: "bounce 0.6s infinite alternate"
});
var dotStyles = css15({
  position: "absolute",
  top: "-0.7em",
  left: "0%",
  transform: "translateX(-50%)"
});
var LoadingLogo = () => /* @__PURE__ */ jsxs14("div", { className: loadingPageStyles, children: [
  /* @__PURE__ */ jsx26("span", { children: "d" }),
  /* @__PURE__ */ jsx26("span", { className: bounceStyles, style: { animationDelay: "0s" }, children: "o" }),
  /* @__PURE__ */ jsx26("span", { className: bounceStyles, style: { animationDelay: "0.1s" }, children: "o" }),
  /* @__PURE__ */ jsx26("span", { children: "w" }),
  /* @__PURE__ */ jsxs14("span", { className: letterStyles, children: [
    "\u0131",
    /* @__PURE__ */ jsx26(
      "span",
      {
        className: cx10(bounceStyles, dotStyles),
        style: { animationDelay: "0.2s" },
        children: "."
      }
    )
  ] }),
  /* @__PURE__ */ jsxs14("span", { className: letterStyles, children: [
    "\u0131",
    /* @__PURE__ */ jsx26(
      "span",
      {
        className: cx10(bounceStyles, dotStyles),
        style: { animationDelay: "0.23s" },
        children: "."
      }
    )
  ] })
] });

// src/Logo/Logo.tsx
import { jsx as jsx27, jsxs as jsxs15 } from "react/jsx-runtime";
var LogoDoowii = () => /* @__PURE__ */ jsxs15(
  "svg",
  {
    fill: "none",
    height: "48",
    viewBox: "0 0 136 48",
    width: "136",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M22.4429 37.6765C20.7599 37.6765 19.2599 37.2671 17.9428 36.4484C16.6501 35.6296 15.6257 34.5131 14.8696 33.0989C14.1378 31.6847 13.772 30.0719 13.772 28.2607C13.772 26.4495 14.1378 24.8368 14.8696 23.4226C15.6257 22.0084 16.6501 20.8919 17.9428 20.0731C19.2599 19.2543 20.7599 18.845 22.4429 18.845C23.6624 18.845 24.76 19.0807 25.7357 19.5521C26.7357 20.0235 27.5528 20.681 28.1869 21.5245C28.8455 22.3433 29.2113 23.2861 29.2845 24.353V31.9824C29.2113 33.0493 28.8577 34.0169 28.2235 34.8853C27.5894 35.7288 26.7723 36.4111 25.7722 36.9322C24.7722 37.4284 23.6624 37.6765 22.4429 37.6765ZM23.4307 32.5406C24.2356 32.5406 24.9308 32.367 25.5161 32.0196C26.1015 31.6474 26.5649 31.1388 26.9064 30.4937C27.2479 29.8486 27.4186 29.1043 27.4186 28.2607C27.4186 27.4172 27.2479 26.6852 26.9064 26.065C26.5893 25.4199 26.1259 24.9112 25.5161 24.5391C24.9308 24.1669 24.2478 23.9808 23.4673 23.9808C22.6868 23.9808 21.9917 24.1669 21.3819 24.5391C20.7965 24.9112 20.3209 25.4199 19.955 26.065C19.6136 26.71 19.4428 27.442 19.4428 28.2607C19.4428 29.0795 19.6136 29.8114 19.955 30.4565C20.2965 31.1016 20.7721 31.6102 21.3819 31.9824C21.9917 32.3545 22.6746 32.5406 23.4307 32.5406ZM32.687 37.3043H27.1991V32.429L28.0406 28.0374L27.0893 23.6459V10.2852H32.687V37.3043Z",
          fill: "#153946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M45.1895 37.7137C43.3602 37.7137 41.7016 37.3043 40.2138 36.4856C38.7503 35.642 37.5918 34.5007 36.7381 33.0617C35.8844 31.6226 35.4576 30.0099 35.4576 28.2235C35.4576 26.4371 35.8844 24.8368 36.7381 23.4226C37.5918 22.0084 38.7503 20.8919 40.2138 20.0731C41.6772 19.2295 43.3358 18.8077 45.1895 18.8077C47.0432 18.8077 48.7018 19.2171 50.1652 20.0359C51.6287 20.8546 52.7872 21.9835 53.6409 23.4226C54.4946 24.8368 54.9214 26.4371 54.9214 28.2235C54.9214 30.0099 54.4946 31.6226 53.6409 33.0617C52.7872 34.5007 51.6287 35.642 50.1652 36.4856C48.7018 37.3043 47.0432 37.7137 45.1895 37.7137ZM45.1895 32.5406C45.9944 32.5406 46.7017 32.367 47.3115 32.0196C47.9213 31.6474 48.3847 31.1388 48.7018 30.4937C49.0432 29.8238 49.214 29.0671 49.214 28.2235C49.214 27.3799 49.0432 26.648 48.7018 26.0277C48.3603 25.3827 47.8847 24.8864 47.2749 24.5391C46.6895 24.1669 45.9944 23.9808 45.1895 23.9808C44.409 23.9808 43.7138 24.1669 43.1041 24.5391C42.4943 24.8864 42.0187 25.3827 41.6772 26.0277C41.3357 26.6728 41.165 27.4172 41.165 28.2607C41.165 29.0795 41.3357 29.8238 41.6772 30.4937C42.0187 31.1388 42.4943 31.6474 43.1041 32.0196C43.7138 32.367 44.409 32.5406 45.1895 32.5406Z",
          fill: "#153946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M66.2694 37.7137C64.4401 37.7137 62.7815 37.3043 61.2937 36.4856C59.8302 35.642 58.6717 34.5007 57.818 33.0617C56.9643 31.6226 56.5375 30.0099 56.5375 28.2235C56.5375 26.4371 56.9643 24.8368 57.818 23.4226C58.6717 22.0084 59.8302 20.8919 61.2937 20.0731C62.7571 19.2295 64.4157 18.8077 66.2694 18.8077C68.1231 18.8077 69.7817 19.2171 71.2451 20.0359C72.7086 20.8546 73.8671 21.9835 74.7208 23.4226C75.5745 24.8368 76.0013 26.4371 76.0013 28.2235C76.0013 30.0099 75.5745 31.6226 74.7208 33.0617C73.8671 34.5007 72.7086 35.642 71.2451 36.4856C69.7817 37.3043 68.1231 37.7137 66.2694 37.7137ZM66.2694 32.5406C67.0743 32.5406 67.7816 32.367 68.3914 32.0196C69.0012 31.6474 69.4646 31.1388 69.7817 30.4937C70.1232 29.8238 70.2939 29.0671 70.2939 28.2235C70.2939 27.3799 70.1232 26.648 69.7817 26.0277C69.4402 25.3827 68.9646 24.8864 68.3548 24.5391C67.7694 24.1669 67.0743 23.9808 66.2694 23.9808C65.4889 23.9808 64.7938 24.1669 64.184 24.5391C63.5742 24.8864 63.0986 25.3827 62.7571 26.0277C62.4157 26.6728 62.2449 27.4172 62.2449 28.2607C62.2449 29.0795 62.4157 29.8238 62.7571 30.4937C63.0986 31.1388 63.5742 31.6474 64.184 32.0196C64.7938 32.367 65.4889 32.5406 66.2694 32.5406Z",
          fill: "#153946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M82.7846 37.3043L76.6382 19.2171H82.1261L85.9311 32.3545L84.3213 32.3918L88.5287 19.2171H93.0654L97.3094 32.3918L95.663 32.3545L99.5045 19.2171H104.992L98.846 37.3043H94.2727L90.1385 24.7996H91.5287L87.3213 37.3043H82.7846Z",
          fill: "#153946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M106.871 37.3043V19.2171H112.469V37.3043H106.871ZM109.688 16.7236C108.81 16.7236 108.078 16.4259 107.493 15.8304C106.932 15.2101 106.651 14.4658 106.651 13.5974C106.651 12.7042 106.932 11.9599 107.493 11.3644C108.078 10.769 108.81 10.4712 109.688 10.4712C110.566 10.4712 111.286 10.769 111.847 11.3644C112.408 11.9599 112.688 12.7042 112.688 13.5974C112.688 14.4658 112.408 15.2101 111.847 15.8304C111.286 16.4259 110.566 16.7236 109.688 16.7236Z",
          fill: "#153946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M116.41 37.3043V19.2171H122.008V37.3043H116.41ZM119.228 16.7236C118.35 16.7236 117.618 16.4259 117.032 15.8304C116.471 15.2101 116.191 14.4658 116.191 13.5974C116.191 12.7042 116.471 11.9599 117.032 11.3644C117.618 10.769 118.35 10.4712 119.228 10.4712C120.106 10.4712 120.825 10.769 121.386 11.3644C121.947 11.9599 122.228 12.7042 122.228 13.5974C122.228 14.4658 121.947 15.2101 121.386 15.8304C120.825 16.4259 120.106 16.7236 119.228 16.7236Z",
          fill: "#153946"
        }
      )
    ]
  }
);
var LogoD = () => /* @__PURE__ */ jsxs15(
  "svg",
  {
    fill: "none",
    height: "48",
    viewBox: "0 0 48 48",
    width: "48",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M17.7749 39C15.8777 39 14.1867 38.5367 12.7019 37.6101C11.2446 36.6834 10.0897 35.4198 9.23734 33.8193C8.41244 32.2188 8 30.3936 8 28.3438C8 26.2939 8.41244 24.4688 9.23734 22.8682C10.0897 21.2677 11.2446 20.0041 12.7019 19.0774C14.1867 18.1508 15.8777 17.6875 17.7749 17.6875C19.1498 17.6875 20.3871 17.9543 21.487 18.4878C22.6143 19.0213 23.5354 19.7654 24.2503 20.7201C24.9927 21.6467 25.4052 22.7138 25.4877 23.9212V32.5557C25.4052 33.7631 25.0065 34.8582 24.2916 35.841C23.5767 36.7957 22.6555 37.5679 21.5282 38.1576C20.4008 38.7192 19.1498 39 17.7749 39ZM18.8885 33.1875C19.7959 33.1875 20.5796 32.9909 21.2395 32.5978C21.8994 32.1766 22.4218 31.601 22.8068 30.8709C23.1917 30.1409 23.3842 29.2985 23.3842 28.3438C23.3842 27.389 23.1917 26.5607 22.8068 25.8587C22.4493 25.1286 21.9269 24.553 21.2395 24.1318C20.5796 23.7106 19.8097 23.5 18.9298 23.5C18.0499 23.5 17.2663 23.7106 16.5789 24.1318C15.9189 24.553 15.3828 25.1286 14.9703 25.8587C14.5854 26.5888 14.3929 27.4171 14.3929 28.3438C14.3929 29.2704 14.5854 30.0987 14.9703 30.8288C15.3553 31.5589 15.8914 32.1345 16.5789 32.5557C17.2663 32.9769 18.0362 33.1875 18.8885 33.1875ZM29.3234 38.5788H23.1367V33.0611L24.0854 28.091L23.013 23.1209V8H29.3234V38.5788Z",
          fill: "#153946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M36.618 38.5979C35.6281 38.5979 34.8032 38.2609 34.1433 37.587C33.5109 36.885 33.1947 36.0426 33.1947 35.0598C33.1947 34.049 33.5109 33.2066 34.1433 32.5327C34.8032 31.8588 35.6281 31.5218 36.618 31.5218C37.6078 31.5218 38.419 31.8588 39.0514 32.5327C39.6838 33.2066 40 34.049 40 35.0598C40 36.0426 39.6838 36.885 39.0514 37.587C38.419 38.2609 37.6078 38.5979 36.618 38.5979Z",
          fill: "#153946"
        }
      )
    ]
  }
);
var Logo = ({ full = true, className }) => /* @__PURE__ */ jsx27("div", { className, children: full ? /* @__PURE__ */ jsx27(LogoDoowii, {}) : /* @__PURE__ */ jsx27(LogoD, {}) });

// src/MainSearchBar/MainSearchBar.tsx
import { css as css16, cx as cx11 } from "@doowii/design-system/css";
import { VisuallyHidden as VisuallyHidden2 } from "@doowii/design-system/jsx";
import { flex as flex2 } from "@doowii/design-system/patterns";
import { faMagnifyingGlass, faPaperPlaneTop as faPaperPlaneTop2 } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon10 } from "@fortawesome/react-fontawesome";
import { useId as useId2, useState as useState3 } from "react";
import { jsx as jsx28, jsxs as jsxs16 } from "react/jsx-runtime";
var MainSearchBar = ({
  placeholder = "How can I help you today?",
  label: LabelText,
  onSubmit,
  size = "default"
}) => {
  const id = useId2();
  const [inputValue, setInputValue] = useState3("");
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit(inputValue);
    }
  };
  const handleSubmitClick = () => {
    onSubmit(inputValue);
  };
  const searchInputContainer = cx11(
    flex2({
      direction: "row",
      align: "center",
      gap: size === "small" ? "8px" : "16px",
      w: "full",
      maxW: size === "small" ? "100%" : "720px"
    }),
    css16({
      background: "base.white",
      rounded: "50px",
      position: "relative",
      textAlign: "center",
      padding: size === "small" ? "sm" : "md",
      boxSizing: "border-box",
      _before: {
        rounded: "50px",
        content: '""',
        backgroundImage: "linear-gradient(97.52deg, #29BDF0 47.66%, #F6CD3C 65.7%)",
        top: "-4px",
        left: "-4px",
        bottom: "-4px",
        right: "-4px",
        position: "absolute",
        zIndex: "-1"
      },
      _hover: {
        _before: {
          backgroundImage: "linear-gradient(97.52deg, #F6CD3C 47.66%, #29BDF0 65.7%)"
        },
        cursor: "pointer"
      }
    })
  );
  const iconStyle2 = css16({
    color: "base.darkBlue",
    fontSize: size === "small" ? "14px" : "24px"
  });
  const searchInput = css16({
    flexGrow: 1,
    fontSize: size === "small" ? "14px" : "24px",
    color: "base.gray",
    fontWeight: "medium",
    overflow: "hidden",
    _focus: {
      outline: "none",
      color: "base.darkBlue"
    }
  });
  return /* @__PURE__ */ jsxs16("div", { className: searchInputContainer, children: [
    /* @__PURE__ */ jsx28("div", { className: iconStyle2, children: /* @__PURE__ */ jsx28(FontAwesomeIcon10, { icon: faMagnifyingGlass }) }),
    /* @__PURE__ */ jsx28(VisuallyHidden2, { children: /* @__PURE__ */ jsx28("label", { htmlFor: `search-${id}`, children: LabelText }) }),
    /* @__PURE__ */ jsx28(
      "input",
      {
        className: searchInput,
        id: `search-${id}`,
        name: "chat input",
        onBlur: (e) => e.target.placeholder = placeholder,
        onChange: (e) => setInputValue(e.target.value),
        onFocus: (e) => e.target.placeholder = "",
        onKeyDown: handleKeyDown,
        placeholder,
        type: "text",
        value: inputValue
      }
    ),
    inputValue ? /* @__PURE__ */ jsx28(
      "button",
      {
        className: iconStyle2,
        onClick: handleSubmitClick,
        onKeyDown: (e) => e.key === "Enter" && handleSubmitClick(),
        tabIndex: 0,
        type: "button",
        children: /* @__PURE__ */ jsx28(FontAwesomeIcon10, { icon: faPaperPlaneTop2 })
      }
    ) : null
  ] });
};

// src/NavBar/NavBar.tsx
import { css as css17, cva as cva10, cx as cx12 } from "@doowii/design-system/css";
import { Center } from "@doowii/design-system/jsx";
import { flex as flex3 } from "@doowii/design-system/patterns";
import {
  faChevronsLeft,
  faChevronsRight
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon11 } from "@fortawesome/react-fontawesome";
import { useId as useId3, useState as useState4 } from "react";
import { NavLink } from "react-router-dom";
import { jsx as jsx29, jsxs as jsxs17 } from "react/jsx-runtime";
var navBarStyles = cva10({
  base: {
    position: "relative",
    height: "100vh",
    p: "ld",
    zIndex: 2,
    bg: "base.white",
    borderRight: "1px solid token(colors.base.lightGray)",
    transition: "width 0.3s ease"
  },
  variants: {
    collapsed: {
      true: { width: "112px" },
      false: { width: "270px" }
    }
  },
  defaultVariants: {
    collapsed: false
  }
});
var navBarRoot = (props) => cx12(
  flex3({ gap: "xl", direction: "column", align: "center" }),
  navBarStyles(props)
);
var navBarMenu = cx12(
  flex3({ gap: "sm", direction: "column", align: "center" }),
  css17({
    w: "100%",
    bg: "white"
  })
);
var navBarIcon = css17({
  w: "32px",
  h: "32px",
  minW: "32px"
});
var navBarItem = cx12(
  flex3({
    gap: "2.5",
    direction: "row",
    align: "center"
  }),
  css17({
    bg: "base.white",
    py: "3",
    px: "4",
    w: "100%",
    minWidth: "xl5",
    rounded: "xl",
    color: "base.darkBlue",
    fontSize: "20px",
    fontWeight: "medium",
    cursor: "pointer",
    _hover: {
      bg: "base.blueBrandSecondary"
    },
    _pressed: {
      bg: "base.blueBrandTertiary"
    }
  })
);
var disabledNavBarItem = css17({
  color: "base.lighterGrey",
  cursor: "not-allowed",
  pointerEvents: "none",
  opacity: 0.6
});
var collapseButton = css17({
  bg: "base.white",
  rounded: "full",
  cursor: "pointer",
  position: "absolute",
  top: "28px",
  right: "-20px",
  padding: "7px",
  color: "base.darkBlue",
  border: "1px solid token(colors.base.lightGray)",
  zIndex: 2,
  _hover: {
    bg: "base.blueBrandSecondary"
  },
  _pressed: {
    bg: "base.blueBrandTertiary"
  }
});
var NavBarItem = ({
  children,
  to,
  disabled = false
}) => /* @__PURE__ */ jsx29("li", { className: css17({ w: "100%" }), children: /* @__PURE__ */ jsxs17(
  NavLink,
  {
    "aria-disabled": disabled,
    className: ({ isActive }) => cx12(
      navBarItem,
      isActive && css17({ bg: "base.blueBrandTertiary" }),
      disabled && disabledNavBarItem
    ),
    tabIndex: disabled ? -1 : void 0,
    to,
    children: [
      /* @__PURE__ */ jsx29(Center, { className: navBarIcon, children: children.icon }),
      children.label
    ]
  }
) });
var NavBar = ({ navItems }) => {
  const id = useId3();
  const [isCollapsed, setIsCollapsed] = useState4(false);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  return /* @__PURE__ */ jsxs17("div", { className: navBarRoot({ collapsed: isCollapsed }), children: [
    isCollapsed ? /* @__PURE__ */ jsx29(LogoD, {}) : /* @__PURE__ */ jsx29(LogoDoowii, {}),
    /* @__PURE__ */ jsx29(
      "button",
      {
        "aria-controls": `collapse-nav-${id}`,
        "aria-expanded": !isCollapsed,
        "aria-label": "Toggle navigation",
        className: collapseButton,
        id: `collapse-button-${id}`,
        onClick: toggleCollapse,
        type: "button",
        children: /* @__PURE__ */ jsx29(
          FontAwesomeIcon11,
          {
            className: css17({
              w: "24px",
              h: "24px",
              cursor: "pointer"
            }),
            icon: isCollapsed ? faChevronsRight : faChevronsLeft
          }
        )
      }
    ),
    /* @__PURE__ */ jsx29(
      "ul",
      {
        "aria-labelledby": `collapse-button-${id}`,
        className: navBarMenu,
        id: `collapse-nav-${id}`,
        children: navItems.map((item) => /* @__PURE__ */ jsx29(NavBarItem, { disabled: item.disabled, to: item.path, children: {
          icon: item.icon,
          label: isCollapsed ? null : item.label
        } }, item.path))
      }
    )
  ] });
};

// src/PageLayout/PageLayout.tsx
import { Flex as Flex4 } from "@doowii/design-system/jsx";
import { useId as useId4 } from "react";
import { jsx as jsx30, jsxs as jsxs18 } from "react/jsx-runtime";
var PageLayout = ({
  title,
  loading,
  isEmpty,
  emptyProps,
  action,
  children
}) => {
  const id = useId4();
  return /* @__PURE__ */ jsxs18(
    Flex4,
    {
      css: {
        flexDirection: "column",
        width: "full",
        height: "full",
        px: "xl3",
        pb: "xl3"
      },
      children: [
        /* @__PURE__ */ jsxs18(
          Flex4,
          {
            css: {
              justifyContent: "space-between",
              alignItems: "center",
              mb: "ld"
            },
            children: [
              /* @__PURE__ */ jsx30(Heading, { level: 1, children: title }),
              !loading && !isEmpty ? action : null
            ]
          }
        ),
        /* @__PURE__ */ jsxs18(Flex4, { css: { w: "full", flex: 1 }, children: [
          loading ? /* @__PURE__ */ jsx30(
            Flex4,
            {
              css: { alignItems: "center", justifyContent: "center", w: "full" },
              children: /* @__PURE__ */ jsx30(LoadingLogo, {})
            }
          ) : null,
          isEmpty && emptyProps && !loading ? /* @__PURE__ */ jsxs18(
            Flex4,
            {
              css: {
                alignItems: "center",
                flexDirection: "column",
                gap: "8",
                w: "full"
              },
              children: [
                /* @__PURE__ */ jsx30(CircularIcon, { icon: emptyProps.icon }),
                /* @__PURE__ */ jsxs18(
                  Flex4,
                  {
                    css: {
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "2"
                    },
                    children: [
                      /* @__PURE__ */ jsx30(Heading, { level: 2, children: emptyProps.title }),
                      /* @__PURE__ */ jsx30(Text, { level: 4, children: emptyProps.description })
                    ]
                  }
                ),
                /* @__PURE__ */ jsx30(Button, { id: `${id}-empty-button`, onClick: emptyProps.onClick, children: emptyProps.buttonText })
              ]
            }
          ) : null,
          !loading && !isEmpty ? children : null
        ] })
      ]
    }
  );
};

// src/Progress/Progress.tsx
import { css as css18, cx as cx13 } from "@doowii/design-system/css";
import * as ProgressPrimitive from "@radix-ui/react-progress";
import * as React18 from "react";
import { jsx as jsx31 } from "react/jsx-runtime";
var Progress = React18.forwardRef(({ className, value, ...props }, ref) => /* @__PURE__ */ jsx31(
  ProgressPrimitive.Root,
  {
    className: cx13(
      css18({
        pos: "relative",
        h: "2",
        w: "full",
        overflow: "hidden",
        rounded: "full",
        bg: "base.lightGray"
      }),
      className
    ),
    ref,
    ...props,
    children: /* @__PURE__ */ jsx31(
      ProgressPrimitive.Indicator,
      {
        className: css18({
          h: "full",
          w: "full",
          flex: "1",
          bg: "linear-gradient(92.59deg, #F6CD3C 2.17%, #29BDF0 94.8%)"
        }),
        style: { transform: `translateX(-${100 - (value ?? 0)}%)` }
      }
    )
  }
));
Progress.displayName = ProgressPrimitive.Root.displayName;

// src/RequestBar/RequestBar.tsx
import { css as css19, cx as cx14 } from "@doowii/design-system/css";
import { flex as flex4 } from "@doowii/design-system/patterns";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon12 } from "@fortawesome/react-fontawesome";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import * as React19 from "react";
import { jsx as jsx32, jsxs as jsxs19 } from "react/jsx-runtime";
var RequestBar = AccordionPrimitive.Root;
var RequestBarItem = React19.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx32(
  AccordionPrimitive.Item,
  {
    className: cx14(
      css19({
        p: "4",
        w: "100%",
        bg: "base.blueBrandSecondary",
        rounded: "xl",
        border: "1px solid token(colors.base.lightGray)"
      }),
      className
    ),
    ref,
    ...props
  }
));
RequestBarItem.displayName = "RequestBarItem";
var RequestBarTrigger = React19.forwardRef(({ className, children, hasContent = false, ...props }, ref) => /* @__PURE__ */ jsx32(AccordionPrimitive.Header, { className: "flex", children: /* @__PURE__ */ jsxs19(
  AccordionPrimitive.Trigger,
  {
    className: cx14(
      flex4({
        direction: "row",
        align: "center",
        justify: "center",
        gap: "4"
      }),
      css19({
        w: "100% ",
        cursor: hasContent ? "pointer" : "text",
        userSelect: "text",
        '&[data-state="open"]': {
          "& .follow-up-text::before": {
            content: hasContent ? '"Hide follow-ups"' : '""'
          },
          "& .icon": {
            transform: hasContent ? "rotate(180deg)" : "none"
          }
        },
        '&[data-state="closed"]': {
          "& .follow-up-text::before": {
            content: hasContent ? '"Show follow-ups"' : '""'
          }
        }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx32(
        "div",
        {
          className: cx14(
            flex4({
              direction: "row",
              align: "center",
              justify: "flex-start",
              gap: "2",
              grow: 1
            }),
            css19({
              textStyle: "paragraph_18"
            })
          ),
          children
        }
      ),
      hasContent ? /* @__PURE__ */ jsxs19(
        "div",
        {
          className: cx14(
            flex4({
              direction: "row",
              align: "center",
              justify: "center",
              gap: "4"
            }),
            css19({
              p: "4",
              pr: "3",
              h: "10",
              textStyle: "label_16"
            })
          ),
          children: [
            /* @__PURE__ */ jsx32("span", { className: "follow-up-text" }),
            /* @__PURE__ */ jsx32(
              FontAwesomeIcon12,
              {
                className: cx14(
                  css19({
                    w: "6",
                    h: "6",
                    transition: "transform 0.2s"
                  }),
                  "icon"
                ),
                icon: faChevronDown
              }
            )
          ]
        }
      ) : null
    ]
  }
) }));
RequestBarTrigger.displayName = "RequestBarTrigger";
var RequestBarContent = React19.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs19(AccordionPrimitive.Content, { className: css19({}), ref, ...props, children: [
  /* @__PURE__ */ jsx32(
    "div",
    {
      className: css19({
        w: "100%",
        h: "0.5",
        my: "4",
        bg: "linear-gradient(97.52deg, #29BDF0 47.66%, #F6CD3C 65.7%)"
      })
    }
  ),
  /* @__PURE__ */ jsx32(
    "div",
    {
      className: cx14(
        flex4({
          direction: "column",
          align: "center",
          justify: "flex-start",
          gap: "2"
        }),
        css19({ pb: "4", pt: "0" }),
        className
      ),
      children
    }
  )
] }));
RequestBarContent.displayName = AccordionPrimitive.Content.displayName;

// src/SearchInput/SearchInput.tsx
import { css as css20 } from "@doowii/design-system/css";
import { Box as Box7, VisuallyHidden as VisuallyHidden3 } from "@doowii/design-system/jsx";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon13 } from "@fortawesome/react-fontawesome";
import { useEffect, useId as useId5, useRef, useState as useState5 } from "react";
import { jsx as jsx33, jsxs as jsxs20 } from "react/jsx-runtime";
var SearchInput = ({
  variant = "standard",
  ...props
}) => {
  const id = useId5();
  const [isExpanded, setIsExpanded] = useState5(variant === "standard");
  const [isShrinking, setIsShrinking] = useState5(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (isExpanded && inputRef.current && variant !== "standard") {
      inputRef.current.focus();
    }
  }, [isExpanded, variant]);
  const toggleExpand = () => {
    if (variant === "expandable") {
      setIsExpanded((prev) => !prev);
    }
  };
  const handleBlur = () => {
    if (variant === "expandable" && inputRef.current?.value.trim() === "") {
      setIsShrinking(true);
      setTimeout(() => {
        setIsShrinking(false);
        setIsExpanded(false);
      }, 300);
    }
  };
  if (variant === "expandable" && !isExpanded && !isShrinking) {
    return /* @__PURE__ */ jsx33(
      Button,
      {
        "aria-expanded": isExpanded,
        "aria-label": "Expand search input",
        iconOnly: faSearch,
        onClick: toggleExpand,
        size: "medium",
        variant: "dropdown"
      }
    );
  }
  return /* @__PURE__ */ jsxs20(
    Box7,
    {
      css: {
        position: "relative",
        background: "white",
        borderRadius: "56px",
        animation: variant === "standard" ? "" : isShrinking ? `shrinkWidth 0.3s ease-in-out forwards` : `growWidth 0.3s ease-in-out forwards`
      },
      children: [
        /* @__PURE__ */ jsx33(
          Box7,
          {
            css: {
              position: "absolute",
              left: "md",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "15px"
            },
            children: /* @__PURE__ */ jsx33(FontAwesomeIcon13, { icon: faSearch })
          }
        ),
        /* @__PURE__ */ jsx33(VisuallyHidden3, { children: /* @__PURE__ */ jsx33("label", { htmlFor: id, children: props["aria-label"] }) }),
        /* @__PURE__ */ jsx33(
          "input",
          {
            className: css20({
              display: "flex",
              padding: "10px",
              pl: "xl3",
              width: "100%",
              alignItems: "center",
              position: "relative",
              borderStyle: "solid",
              borderWidth: "2px",
              borderColor: "border.base",
              borderRadius: "56px",
              outline: "none"
            }),
            id,
            ref: inputRef,
            ...props,
            onBlur: handleBlur
          }
        )
      ]
    }
  );
};

// src/Select/Select.tsx
import { css as css21, cx as cx15 } from "@doowii/design-system/css";
import {
  faCheck as faCheck3,
  faChevronDown as faChevronDown2,
  faChevronUp
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon14 } from "@fortawesome/react-fontawesome";
import * as SelectPrimitive from "@radix-ui/react-select";
import * as React20 from "react";
import { jsx as jsx34, jsxs as jsxs21 } from "react/jsx-runtime";
var Select = SelectPrimitive.Root;
var SelectGroup = SelectPrimitive.Group;
var SelectValue = ({ name, ...rest }) => /* @__PURE__ */ jsx34(SelectPrimitive.Value, { ...rest });
var SelectTrigger = React20.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs21(
  SelectPrimitive.Trigger,
  {
    className: cx15(
      css21({
        display: "flex",
        w: "full",
        alignItems: "center",
        justifyContent: "space-between",
        color: "foreground.base",
        whiteSpace: "nowrap",
        rounded: "md",
        borderWidth: "2px",
        borderColor: "base.gray",
        bgColor: "transparent",
        p: "4",
        mt: "2",
        fontWeight: "500",
        lineHeight: "sm",
        cursor: "pointer",
        _focus: {
          ring: "none",
          ringOffset: "none",
          shadow: "1",
          borderColor: "base.blueBrandPrimary"
        },
        _disabled: { cursor: "not-allowed", opacity: "0.5" },
        "&>span": {
          overflow: "hidden",
          display: "-webkit-box",
          // @ts-ignore
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: "1"
        }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      children,
      /* @__PURE__ */ jsx34(SelectPrimitive.Icon, { asChild: true, children: /* @__PURE__ */ jsx34(
        FontAwesomeIcon14,
        {
          className: css21({ h: "4", w: "4", opacity: "0.5" }),
          icon: faChevronDown2
        }
      ) })
    ]
  }
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;
var SelectScrollUpButton = React20.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx34(
  SelectPrimitive.ScrollUpButton,
  {
    className: cx15(
      css21({
        display: "flex",
        cursor: "default",
        alignItems: "center",
        justifyContent: "center",
        pt: "1",
        pb: "1"
      }),
      className
    ),
    ref,
    ...props,
    children: /* @__PURE__ */ jsx34(FontAwesomeIcon14, { icon: faChevronUp })
  }
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;
var SelectScrollDownButton = React20.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx34(
  SelectPrimitive.ScrollDownButton,
  {
    className: cx15(
      css21({
        display: "flex",
        cursor: "default",
        alignItems: "center",
        justifyContent: "center",
        pt: "1",
        pb: "1"
      }),
      className
    ),
    ref,
    ...props,
    children: /* @__PURE__ */ jsx34(FontAwesomeIcon14, { icon: faChevronDown2 })
  }
));
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;
var SelectContent = React20.forwardRef(({ className, children, position = "popper", ...props }, ref) => /* @__PURE__ */ jsx34(SelectPrimitive.Portal, { children: /* @__PURE__ */ jsxs21(
  SelectPrimitive.Content,
  {
    className: cx15(
      css21({
        pos: "relative",
        zIndex: "50",
        maxH: "96",
        minW: "8rem",
        overflow: "hidden",
        rounded: "md",
        shadow: "0px 4px 20px 0px #1539462E",
        bg: "white"
      }),
      position === "popper" && css21({
        // @ts-ignore
        "data-[side=bottom]": {
          transform: (
            // eslint-disable-next-line sonarjs/no-duplicate-string
            "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
          )
        },
        "data-[side=left]": {
          transform: "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
        },
        "data-[side=right]": {
          transform: "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
        },
        "data-[side=top]": {
          transform: "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
        }
      }),
      className
    ),
    position,
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx34(SelectScrollUpButton, {}),
      /* @__PURE__ */ jsx34(
        SelectPrimitive.Viewport,
        {
          className: cx15(
            css21({ p: "1" }),
            position === "popper" && css21({
              h: "var(.-radix-select-trigger-height)",
              w: "full",
              minW: "var(.-radix-select-trigger-width)"
            })
          ),
          children
        }
      ),
      /* @__PURE__ */ jsx34(SelectScrollDownButton, {})
    ]
  }
) }));
SelectContent.displayName = SelectPrimitive.Content.displayName;
var SelectLabel = React20.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx34(
  SelectPrimitive.Label,
  {
    className: cx15(
      css21({
        pl: "2",
        pr: "2",
        pt: "1.5",
        pb: "1.5",
        fontSize: "sm",
        lineHeight: "sm",
        fontWeight: "semibold",
        color: "foreground.base"
      }),
      className
    ),
    ref,
    ...props
  }
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;
var SelectItem = React20.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs21(
  SelectPrimitive.Item,
  {
    className: cx15(
      css21({
        color: "foreground.base",
        pos: "relative",
        display: "flex",
        w: "full",
        cursor: "default",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        pt: "3",
        pb: "3",
        pl: "3",
        pr: "8",
        fontSize: "sm",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        // @ts-ignore
        "data-[disabled]": { pointerEvents: "none", opacity: "0.5" },
        _hover: { bgColor: "base.blueBrandSecondary" },
        _active: { bgColor: "base.blueBrandTertiary" }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx34(
        "span",
        {
          className: css21({
            pos: "absolute",
            right: "2",
            display: "flex",
            h: "3.5",
            w: "3.5",
            alignItems: "center",
            justifyContent: "center"
          }),
          children: /* @__PURE__ */ jsx34(SelectPrimitive.ItemIndicator, { children: /* @__PURE__ */ jsx34(FontAwesomeIcon14, { className: css21({ h: "4", w: "4" }), icon: faCheck3 }) })
        }
      ),
      /* @__PURE__ */ jsx34(SelectPrimitive.ItemText, { children })
    ]
  }
));
SelectItem.displayName = SelectPrimitive.Item.displayName;
var SelectSeparator = React20.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx34(
  SelectPrimitive.Separator,
  {
    className: cx15(
      css21({ ml: ".mx-1", mr: ".mx-1", mt: "1", mb: "1", h: "px" }),
      className
    ),
    ref,
    ...props
  }
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

// src/Separator/Separator.tsx
import { css as css22, cx as cx16 } from "@doowii/design-system/css";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import * as React21 from "react";
import { jsx as jsx35 } from "react/jsx-runtime";
var Separator3 = React21.forwardRef(
  ({
    className,
    orientation = "horizontal",
    decorative = true,
    colorful = true,
    ...props
  }, ref) => /* @__PURE__ */ jsx35(
    SeparatorPrimitive.Root,
    {
      className: cx16(
        css22({ flexShrink: "0", bg: "base.lightGray" }),
        orientation === "horizontal" ? css22({ h: "1px", w: "full" }) : css22({ h: "full", w: "1px" }),
        colorful && css22({
          h: "0.5",
          // bgGradient: "gradient.primary",
          bg: "linear-gradient(97.52deg, #29BDF0 47.66%, #F6CD3C 65.7%)"
        }),
        className
      ),
      decorative,
      orientation,
      ref,
      ...props
    }
  )
);
Separator3.displayName = SeparatorPrimitive.Root.displayName;

// src/Skeleton/Skeleton.tsx
import { css as css23, cx as cx17 } from "@doowii/design-system/css";
import { Box as Box8 } from "@doowii/design-system/jsx";
import { jsx as jsx36 } from "react/jsx-runtime";
var Skeleton = ({ className, ...props }) => /* @__PURE__ */ jsx36(
  Box8,
  {
    className: cx17(
      css23({ animation: "pulse", bg: "base.lightherGray" }),
      className
    ),
    rounded: props.rounded ?? "md",
    ...props
  }
);

// src/SuggestionCard/SuggestionCard.tsx
import { css as css24, cx as cx18 } from "@doowii/design-system/css";
import { flex as flex5 } from "@doowii/design-system/patterns";
import { faPaperPlaneTop as faPaperPlaneTop3 } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon15 } from "@fortawesome/react-fontawesome";
import { jsx as jsx37, jsxs as jsxs22 } from "react/jsx-runtime";
var suggestionCardStyle = cx18(
  flex5({
    gap: "ld",
    direction: "row",
    align: "center",
    justify: "start"
  }),
  css24({
    bg: "base.white",
    p: "ld",
    rounded: "xl",
    cursor: "pointer",
    border: "1px solid token(colors.base.lightGray)",
    shadow: "regular",
    w: "full",
    maxWidth: "720px",
    minHeight: "92px",
    textAlign: "left",
    color: "base.darkBlue",
    _hover: {
      shadow: "hover",
      bg: "base.blueBrandSecondary"
    }
  })
);
var suggestionCardTextStyle = cx18(
  flex5({
    grow: 1
  }),
  css24({
    textStyle: "paragraph_16"
  })
);
var suggestionCardIconStyle = cx18(
  flex5({
    align: "center",
    justify: "center",
    shrink: 0
  }),
  css24({
    w: "32px",
    h: "32px"
  })
);
var SuggestionCard = ({
  onClick,
  children,
  label
}) => /* @__PURE__ */ jsxs22(
  "button",
  {
    "aria-label": label,
    className: suggestionCardStyle,
    onClick,
    type: "button",
    children: [
      /* @__PURE__ */ jsx37("div", { className: suggestionCardTextStyle, children }),
      /* @__PURE__ */ jsx37("div", { className: suggestionCardIconStyle, children: /* @__PURE__ */ jsx37(FontAwesomeIcon15, { icon: faPaperPlaneTop3 }) })
    ]
  }
);

// src/Switch/Switch.tsx
import * as React29 from "react";
import { css as css25, cx as cx19 } from "@doowii/design-system/css";

// ../../node_modules/@radix-ui/react-switch/dist/index.mjs
import * as React28 from "react";

// ../../node_modules/@radix-ui/primitive/dist/index.mjs
function composeEventHandlers(originalEventHandler, ourEventHandler, { checkForDefaultPrevented = true } = {}) {
  return function handleEvent(event) {
    originalEventHandler?.(event);
    if (checkForDefaultPrevented === false || !event.defaultPrevented) {
      return ourEventHandler?.(event);
    }
  };
}

// ../../node_modules/@radix-ui/react-context/dist/index.mjs
import * as React22 from "react";
import { jsx as jsx38 } from "react/jsx-runtime";
function createContextScope(scopeName, createContextScopeDeps = []) {
  let defaultContexts = [];
  function createContext3(rootComponentName, defaultContext) {
    const BaseContext = React22.createContext(defaultContext);
    const index = defaultContexts.length;
    defaultContexts = [...defaultContexts, defaultContext];
    function Provider3(props) {
      const { scope, children, ...context } = props;
      const Context = scope?.[scopeName][index] || BaseContext;
      const value = React22.useMemo(() => context, Object.values(context));
      return /* @__PURE__ */ jsx38(Context.Provider, { value, children });
    }
    function useContext22(consumerName, scope) {
      const Context = scope?.[scopeName][index] || BaseContext;
      const context = React22.useContext(Context);
      if (context) return context;
      if (defaultContext !== void 0) return defaultContext;
      throw new Error(`\`${consumerName}\` must be used within \`${rootComponentName}\``);
    }
    Provider3.displayName = rootComponentName + "Provider";
    return [Provider3, useContext22];
  }
  const createScope = () => {
    const scopeContexts = defaultContexts.map((defaultContext) => {
      return React22.createContext(defaultContext);
    });
    return function useScope(scope) {
      const contexts = scope?.[scopeName] || scopeContexts;
      return React22.useMemo(
        () => ({ [`__scope${scopeName}`]: { ...scope, [scopeName]: contexts } }),
        [scope, contexts]
      );
    };
  };
  createScope.scopeName = scopeName;
  return [createContext3, composeContextScopes(createScope, ...createContextScopeDeps)];
}
function composeContextScopes(...scopes) {
  const baseScope = scopes[0];
  if (scopes.length === 1) return baseScope;
  const createScope = () => {
    const scopeHooks = scopes.map((createScope2) => ({
      useScope: createScope2(),
      scopeName: createScope2.scopeName
    }));
    return function useComposedScopes(overrideScopes) {
      const nextScopes = scopeHooks.reduce((nextScopes2, { useScope, scopeName }) => {
        const scopeProps = useScope(overrideScopes);
        const currentScope = scopeProps[`__scope${scopeName}`];
        return { ...nextScopes2, ...currentScope };
      }, {});
      return React22.useMemo(() => ({ [`__scope${baseScope.scopeName}`]: nextScopes }), [nextScopes]);
    };
  };
  createScope.scopeName = baseScope.scopeName;
  return createScope;
}

// ../../node_modules/@radix-ui/react-use-controllable-state/dist/index.mjs
import * as React24 from "react";

// ../../node_modules/@radix-ui/react-use-callback-ref/dist/index.mjs
import * as React23 from "react";
function useCallbackRef(callback) {
  const callbackRef = React23.useRef(callback);
  React23.useEffect(() => {
    callbackRef.current = callback;
  });
  return React23.useMemo(() => (...args) => callbackRef.current?.(...args), []);
}

// ../../node_modules/@radix-ui/react-use-controllable-state/dist/index.mjs
function useControllableState({
  prop,
  defaultProp,
  onChange = () => {
  }
}) {
  const [uncontrolledProp, setUncontrolledProp] = useUncontrolledState({ defaultProp, onChange });
  const isControlled = prop !== void 0;
  const value = isControlled ? prop : uncontrolledProp;
  const handleChange = useCallbackRef(onChange);
  const setValue = React24.useCallback(
    (nextValue) => {
      if (isControlled) {
        const setter = nextValue;
        const value2 = typeof nextValue === "function" ? setter(prop) : nextValue;
        if (value2 !== prop) handleChange(value2);
      } else {
        setUncontrolledProp(nextValue);
      }
    },
    [isControlled, prop, setUncontrolledProp, handleChange]
  );
  return [value, setValue];
}
function useUncontrolledState({
  defaultProp,
  onChange
}) {
  const uncontrolledState = React24.useState(defaultProp);
  const [value] = uncontrolledState;
  const prevValueRef = React24.useRef(value);
  const handleChange = useCallbackRef(onChange);
  React24.useEffect(() => {
    if (prevValueRef.current !== value) {
      handleChange(value);
      prevValueRef.current = value;
    }
  }, [value, prevValueRef, handleChange]);
  return uncontrolledState;
}

// ../../node_modules/@radix-ui/react-use-previous/dist/index.mjs
import * as React25 from "react";
function usePrevious(value) {
  const ref = React25.useRef({ value, previous: value });
  return React25.useMemo(() => {
    if (ref.current.value !== value) {
      ref.current.previous = ref.current.value;
      ref.current.value = value;
    }
    return ref.current.previous;
  }, [value]);
}

// ../../node_modules/@radix-ui/react-use-size/dist/index.mjs
import * as React27 from "react";

// ../../node_modules/@radix-ui/react-use-layout-effect/dist/index.mjs
import * as React26 from "react";
var useLayoutEffect2 = Boolean(globalThis?.document) ? React26.useLayoutEffect : () => {
};

// ../../node_modules/@radix-ui/react-use-size/dist/index.mjs
function useSize(element) {
  const [size, setSize] = React27.useState(void 0);
  useLayoutEffect2(() => {
    if (element) {
      setSize({ width: element.offsetWidth, height: element.offsetHeight });
      const resizeObserver = new ResizeObserver((entries) => {
        if (!Array.isArray(entries)) {
          return;
        }
        if (!entries.length) {
          return;
        }
        const entry = entries[0];
        let width;
        let height;
        if ("borderBoxSize" in entry) {
          const borderSizeEntry = entry["borderBoxSize"];
          const borderSize = Array.isArray(borderSizeEntry) ? borderSizeEntry[0] : borderSizeEntry;
          width = borderSize["inlineSize"];
          height = borderSize["blockSize"];
        } else {
          width = element.offsetWidth;
          height = element.offsetHeight;
        }
        setSize({ width, height });
      });
      resizeObserver.observe(element, { box: "border-box" });
      return () => resizeObserver.unobserve(element);
    } else {
      setSize(void 0);
    }
  }, [element]);
  return size;
}

// ../../node_modules/@radix-ui/react-switch/dist/index.mjs
import { jsx as jsx39, jsxs as jsxs23 } from "react/jsx-runtime";
var SWITCH_NAME = "Switch";
var [createSwitchContext, createSwitchScope] = createContextScope(SWITCH_NAME);
var [SwitchProvider, useSwitchContext] = createSwitchContext(SWITCH_NAME);
var Switch = React28.forwardRef(
  (props, forwardedRef) => {
    const {
      __scopeSwitch,
      name,
      checked: checkedProp,
      defaultChecked,
      required,
      disabled,
      value = "on",
      onCheckedChange,
      ...switchProps
    } = props;
    const [button, setButton] = React28.useState(null);
    const composedRefs = useComposedRefs(forwardedRef, (node) => setButton(node));
    const hasConsumerStoppedPropagationRef = React28.useRef(false);
    const isFormControl = button ? Boolean(button.closest("form")) : true;
    const [checked = false, setChecked] = useControllableState({
      prop: checkedProp,
      defaultProp: defaultChecked,
      onChange: onCheckedChange
    });
    return /* @__PURE__ */ jsxs23(SwitchProvider, { scope: __scopeSwitch, checked, disabled, children: [
      /* @__PURE__ */ jsx39(
        Primitive.button,
        {
          type: "button",
          role: "switch",
          "aria-checked": checked,
          "aria-required": required,
          "data-state": getState(checked),
          "data-disabled": disabled ? "" : void 0,
          disabled,
          value,
          ...switchProps,
          ref: composedRefs,
          onClick: composeEventHandlers(props.onClick, (event) => {
            setChecked((prevChecked) => !prevChecked);
            if (isFormControl) {
              hasConsumerStoppedPropagationRef.current = event.isPropagationStopped();
              if (!hasConsumerStoppedPropagationRef.current) event.stopPropagation();
            }
          })
        }
      ),
      isFormControl && /* @__PURE__ */ jsx39(
        BubbleInput,
        {
          control: button,
          bubbles: !hasConsumerStoppedPropagationRef.current,
          name,
          value,
          checked,
          required,
          disabled,
          style: { transform: "translateX(-100%)" }
        }
      )
    ] });
  }
);
Switch.displayName = SWITCH_NAME;
var THUMB_NAME = "SwitchThumb";
var SwitchThumb = React28.forwardRef(
  (props, forwardedRef) => {
    const { __scopeSwitch, ...thumbProps } = props;
    const context = useSwitchContext(THUMB_NAME, __scopeSwitch);
    return /* @__PURE__ */ jsx39(
      Primitive.span,
      {
        "data-state": getState(context.checked),
        "data-disabled": context.disabled ? "" : void 0,
        ...thumbProps,
        ref: forwardedRef
      }
    );
  }
);
SwitchThumb.displayName = THUMB_NAME;
var BubbleInput = (props) => {
  const { control, checked, bubbles = true, ...inputProps } = props;
  const ref = React28.useRef(null);
  const prevChecked = usePrevious(checked);
  const controlSize = useSize(control);
  React28.useEffect(() => {
    const input = ref.current;
    const inputProto = window.HTMLInputElement.prototype;
    const descriptor = Object.getOwnPropertyDescriptor(inputProto, "checked");
    const setChecked = descriptor.set;
    if (prevChecked !== checked && setChecked) {
      const event = new Event("click", { bubbles });
      setChecked.call(input, checked);
      input.dispatchEvent(event);
    }
  }, [prevChecked, checked, bubbles]);
  return /* @__PURE__ */ jsx39(
    "input",
    {
      type: "checkbox",
      "aria-hidden": true,
      defaultChecked: checked,
      ...inputProps,
      tabIndex: -1,
      ref,
      style: {
        ...props.style,
        ...controlSize,
        position: "absolute",
        pointerEvents: "none",
        opacity: 0,
        margin: 0
      }
    }
  );
};
function getState(checked) {
  return checked ? "checked" : "unchecked";
}
var Root12 = Switch;
var Thumb = SwitchThumb;

// src/Switch/Switch.tsx
import { jsx as jsx40 } from "react/jsx-runtime";
var Switch2 = React29.forwardRef(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx40(
    Root12,
    {
      className: cx19(
        css25({
          all: "unset",
          width: "42px",
          // Default width
          height: "25px",
          // Default height
          backgroundColor: "#117DA3",
          borderRadius: "9999px",
          position: "relative",
          WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
          transition: "background-color 0.2s",
          '&[data-state="checked"]': { backgroundColor: "#33A665" },
          "&[data-disabled]": { backgroundColor: "#A9A9A9" }
        }),
        className
      ),
      ref,
      ...props,
      children: /* @__PURE__ */ jsx40(
        Thumb,
        {
          className: css25({
            display: "block",
            width: "21px",
            // Default thumb width
            height: "21px",
            // Default thumb height
            backgroundColor: "white",
            borderRadius: "9999px",
            boxShadow: "0 2px 2px rgba(0, 0, 0, 0.05)",
            transition: "transform 0.2s",
            transform: "translateX(2px)",
            // Default unchecked position
            '[data-state="checked"] &': { transform: "translateX(19px)" },
            // Checked position
            "[data-disabled] &": { backgroundColor: "#D3D3D3" }
          })
        }
      )
    }
  );
});
Switch2.displayName = "Switch";

// src/Table/SimpleTable.tsx
import { css as css28 } from "@doowii/design-system/css";
import { Box as Box10, Flex as Flex6 } from "@doowii/design-system/jsx";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from "@tanstack/react-table";

// src/Table/Table.tsx
import { css as css27 } from "@doowii/design-system/css";
import { Box as Box9, Flex as Flex5 } from "@doowii/design-system/jsx";
import {
  faChevronLeft,
  faChevronRight as faChevronRight3,
  faChevronsLeft as faChevronsLeft2,
  faChevronsRight as faChevronsRight2
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon16 } from "@fortawesome/react-fontawesome";
import {
  flexRender
} from "@tanstack/react-table";
import { useCallback as useCallback3 } from "react";

// src/Table/TablePrimitive.tsx
import { css as css26, cx as cx20 } from "@doowii/design-system/css";
import * as React30 from "react";
import { jsx as jsx41 } from "react/jsx-runtime";
var Table = React30.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx41("div", { className: css26({ pos: "relative", w: "full", overflow: "auto" }), children: /* @__PURE__ */ jsx41(
  "table",
  {
    className: cx20(
      css26({
        w: "full",
        captionSide: "bottom",
        fontSize: "sm",
        lineHeight: "sm"
      }),
      className
    ),
    ref,
    ...props
  }
) }));
Table.displayName = "Table";
var TableHeader = React30.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx41("thead", { className: cx20(className), ref, ...props }));
TableHeader.displayName = "TableHeader";
var TableBody = React30.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx41("tbody", { className: cx20(className), ref, ...props }));
TableBody.displayName = "TableBody";
var TableFooter = React30.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx41(
  "tfoot",
  {
    className: cx20(
      css26({
        borderTopWidth: "1px",
        fontWeight: "medium",
        _last: { "&>tr": { borderBottomWidth: "0px" } }
      }),
      className
    ),
    ref,
    ...props
  }
));
TableFooter.displayName = "TableFooter";
var TableRow = React30.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx41(
  "tr",
  {
    className: cx20(
      css26({
        borderBottomWidth: "1px",
        transitionProperty: "color, background-color, border-color, text-decoration-color, fill, stroke",
        transitionTimingFunction: "colors",
        transitionDuration: "colors"
      }),
      className
    ),
    ref,
    ...props
  }
));
TableRow.displayName = "TableRow";
var TableHead = React30.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsx41(
  "th",
  {
    className: cx20(
      css26({
        h: "12",
        px: "4",
        textAlign: "left",
        verticalAlign: "middle",
        fontWeight: "medium",
        "&:has([role=checkbox])": { pr: "0" }
      }),
      className
    ),
    ref,
    ...props,
    children
  }
));
TableHead.displayName = "TableHead";
var TableCell = React30.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsx41(
  "td",
  {
    className: cx20(
      css26({
        p: "4",
        verticalAlign: "middle",
        "&:has([role=checkbox])": { pr: "0" }
      }),
      className
    ),
    ref,
    ...props,
    children
  }
));
TableCell.displayName = "TableCell";
var TableCaption = React30.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx41(
  "caption",
  {
    className: cx20(
      css26({ mt: "4", fontSize: "sm", lineHeight: "sm" }),
      className
    ),
    ref,
    ...props
  }
));
TableCaption.displayName = "TableCaption";

// src/Table/Table.tsx
import { jsx as jsx42, jsxs as jsxs24 } from "react/jsx-runtime";
var TableHeader2 = ({
  table,
  className
}) => /* @__PURE__ */ jsx42(TableHeader, { className, children: table.getHeaderGroups().map((headerGroup) => /* @__PURE__ */ jsx42(TableRow, { children: headerGroup.headers.map((header) => /* @__PURE__ */ jsx42(TableHead, { children: header.isPlaceholder ? null : /* @__PURE__ */ jsx42(Label, { level: 1, children: flexRender(
  header.column.columnDef.header,
  header.getContext()
) }) }, header.id)) }, headerGroup.id)) });
var TableBody2 = ({
  table,
  className
}) => /* @__PURE__ */ jsx42(TableBody, { className, children: table.getRowModel().rows.map((row) => /* @__PURE__ */ jsx42(
  TableRow,
  {
    "data-state": row.getIsSelected() ? "selected" : null,
    children: row.getVisibleCells().map((cell) => /* @__PURE__ */ jsx42(TableCell, { children: cell.column.columnDef.isAction ? flexRender(cell.column.columnDef.cell, cell.getContext()) : /* @__PURE__ */ jsx42(Label, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }) }, cell.id))
  },
  row.id
)) });
var LoadingSkeleton = () => /* @__PURE__ */ jsxs24(Flex5, { css: { flexDirection: "column", gap: "md" }, children: [
  /* @__PURE__ */ jsxs24(Flex5, { css: { alignItems: "center", gap: "md" }, children: [
    /* @__PURE__ */ jsx42(Box9, { css: { flex: 1 }, children: /* @__PURE__ */ jsx42(Skeleton, { css: { height: "32px", flex: 1, maxW: "40%" } }) }),
    /* @__PURE__ */ jsx42(Box9, { css: { flex: 1 }, children: /* @__PURE__ */ jsx42(Skeleton, { css: { height: "32px", flex: 1, maxW: "40%" } }) }),
    /* @__PURE__ */ jsx42(Box9, { css: { flex: 1 }, children: /* @__PURE__ */ jsx42(Skeleton, { css: { height: "32px", flex: 1, maxW: "40%" } }) }),
    /* @__PURE__ */ jsx42(Box9, { css: { flex: 1 }, children: /* @__PURE__ */ jsx42(Skeleton, { css: { height: "32px", flex: 1, maxW: "40%" } }) })
  ] }),
  Array.from(Array(11).keys()).map((index) => /* @__PURE__ */ jsxs24(
    Flex5,
    {
      css: {
        alignItems: "center",
        gap: "md",
        justifyContent: "space-between"
      },
      children: [
        /* @__PURE__ */ jsx42(Skeleton, { css: { height: "32px", flex: 1 } }),
        /* @__PURE__ */ jsx42(Skeleton, { css: { height: "32px", flex: 1 } }),
        /* @__PURE__ */ jsx42(Skeleton, { css: { height: "32px", flex: 1 } }),
        /* @__PURE__ */ jsx42(Skeleton, { css: { height: "32px", flex: 1 } })
      ]
    },
    index
  ))
] });
var DefaultEmptyComponent = () => /* @__PURE__ */ jsx42(Flex5, { css: { alignItems: "center", justifyContent: "center", h: "full" }, children: "No results." });
var Table2 = ({
  table,
  className,
  loading,
  emptyComponent
}) => {
  const renderTable = useCallback3(() => {
    if (loading) {
      return /* @__PURE__ */ jsx42(LoadingSkeleton, {});
    } else if (table.getRowModel().rows.length < 1) {
      return emptyComponent ?? /* @__PURE__ */ jsx42(DefaultEmptyComponent, {});
    } else {
      return /* @__PURE__ */ jsx42(Box9, { css: { rounded: "md", borderWidth: "1px" }, children: /* @__PURE__ */ jsxs24(Table, { children: [
        /* @__PURE__ */ jsx42(TableHeader2, { table }),
        /* @__PURE__ */ jsx42(TableBody2, { table })
      ] }) });
    }
  }, [table, loading, emptyComponent]);
  return /* @__PURE__ */ jsx42(Box9, { className, children: renderTable() });
};
var TablePaginationActions = ({
  table
}) => /* @__PURE__ */ jsxs24(
  Flex5,
  {
    css: {
      alignItems: "center",
      mr: "2",
      ml: "2",
      gap: "sm",
      color: "foreground.base"
    },
    children: [
      /* @__PURE__ */ jsxs24(
        Button,
        {
          className: css27({
            display: "none",
            h: "8",
            w: "8",
            p: "0",
            lg: { display: "flex" }
          }),
          disabled: !table.getCanPreviousPage(),
          onClick: () => table.setPageIndex(0),
          variant: "dropdown",
          children: [
            /* @__PURE__ */ jsx42(
              "span",
              {
                className: css27({
                  pos: "absolute",
                  w: "sr.only",
                  h: "sr.only",
                  p: "sr.only",
                  m: "sr.only",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  whiteSpace: "nowrap",
                  borderWidth: "0"
                }),
                children: "Go to first page"
              }
            ),
            /* @__PURE__ */ jsx42(
              FontAwesomeIcon16,
              {
                className: css27({ h: "4", w: "4" }),
                icon: faChevronsLeft2,
                size: "sm"
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs24(
        Button,
        {
          className: css27({ h: "8", w: "8", p: "0" }),
          disabled: !table.getCanPreviousPage(),
          onClick: () => table.previousPage(),
          variant: "dropdown",
          children: [
            /* @__PURE__ */ jsx42(
              "span",
              {
                className: css27({
                  pos: "absolute",
                  w: "sr.only",
                  h: "sr.only",
                  p: "sr.only",
                  m: "sr.only",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  whiteSpace: "nowrap",
                  borderWidth: "0"
                }),
                children: "Go to previous page"
              }
            ),
            /* @__PURE__ */ jsx42(
              FontAwesomeIcon16,
              {
                className: css27({ h: "4", w: "4" }),
                icon: faChevronLeft,
                size: "xs"
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs24(
        "div",
        {
          className: css27({
            display: "flex",
            w: "100px",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "sm",
            lineHeight: "sm",
            fontWeight: "medium"
          }),
          children: [
            "Page ",
            table.getState().pagination.pageIndex + 1,
            " of ",
            table.getPageCount()
          ]
        }
      ),
      /* @__PURE__ */ jsxs24(
        Button,
        {
          className: css27({ h: "8", w: "8", p: "0" }),
          disabled: !table.getCanNextPage(),
          onClick: () => table.nextPage(),
          variant: "dropdown",
          children: [
            /* @__PURE__ */ jsx42(
              "span",
              {
                className: css27({
                  pos: "absolute",
                  w: "sr.only",
                  h: "sr.only",
                  p: "sr.only",
                  m: "sr.only",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  whiteSpace: "nowrap",
                  borderWidth: "0"
                }),
                children: "Go to next page"
              }
            ),
            /* @__PURE__ */ jsx42(
              FontAwesomeIcon16,
              {
                className: css27({ h: "4", w: "4" }),
                icon: faChevronRight3,
                size: "xs"
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs24(
        Button,
        {
          className: css27({
            display: "none",
            h: "8",
            w: "8",
            p: "0",
            lg: { display: "flex" }
          }),
          disabled: !table.getCanNextPage(),
          onClick: () => table.setPageIndex(table.getPageCount() - 1),
          variant: "dropdown",
          children: [
            /* @__PURE__ */ jsx42(
              "span",
              {
                className: css27({
                  pos: "absolute",
                  w: "sr.only",
                  h: "sr.only",
                  p: "sr.only",
                  m: "sr.only",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  whiteSpace: "nowrap",
                  borderWidth: "0"
                }),
                children: "Go to last page"
              }
            ),
            /* @__PURE__ */ jsx42(
              FontAwesomeIcon16,
              {
                className: css27({ h: "4", w: "4" }),
                icon: faChevronsRight2,
                size: "sm"
              }
            )
          ]
        }
      )
    ]
  }
);
var TablePaginationRPS = ({
  table
}) => /* @__PURE__ */ jsxs24(Flex5, { css: { alignItems: "center", mr: "2", ml: "2", gap: "sm" }, children: [
  /* @__PURE__ */ jsx42(
    "p",
    {
      className: css27({
        fontSize: "sm",
        lineHeight: "sm",
        fontWeight: "medium",
        color: "foreground.base"
      }),
      children: "Rows per page"
    }
  ),
  /* @__PURE__ */ jsxs24(
    Select,
    {
      onValueChange: (value) => {
        table.setPageSize(Number(value));
      },
      value: `${table.getState().pagination.pageSize}`,
      children: [
        /* @__PURE__ */ jsx42(SelectTrigger, { className: css27({ h: "8", w: "70px" }), children: /* @__PURE__ */ jsx42(SelectValue, { placeholder: table.getState().pagination.pageSize }) }),
        /* @__PURE__ */ jsx42(SelectContent, { side: "top", children: [10, 20, 30, 40, 50].map((pageSize) => /* @__PURE__ */ jsx42(SelectItem, { value: `${pageSize}`, children: pageSize }, pageSize)) })
      ]
    }
  )
] });
var TablePagination = ({
  table
}) => /* @__PURE__ */ jsxs24(
  Flex5,
  {
    css: {
      alignItems: "center",
      justifyContent: "space-between",
      pl: "2",
      pr: "2"
    },
    children: [
      /* @__PURE__ */ jsx42(TablePaginationRPS, { table }),
      /* @__PURE__ */ jsx42(TablePaginationActions, { table })
    ]
  }
);

// src/Table/SimpleTable.tsx
import { jsx as jsx43, jsxs as jsxs25 } from "react/jsx-runtime";
var tableStyles = css28({
  "& > div": {
    bg: "base.white",
    color: "foreground.base",
    borderColor: "base.lightGray",
    px: "md",
    pt: "md",
    "& thead": {
      bg: "base.lightherGray",
      "& th": {
        _firstOfType: {
          borderStartStartRadius: "md",
          borderEndStartRadius: "md"
        },
        _lastOfType: {
          borderStartEndRadius: "md",
          borderEndEndRadius: "md"
        }
      }
    },
    "& tr": {
      borderColor: "base.lightGray",
      _lastOfType: {
        border: "none"
      },
      "& td": {
        py: "sm"
      }
    }
  }
});
var SimpleTable = ({
  columns,
  data,
  columnFilters,
  globalFilter,
  emptyComponent,
  setColumnFilters,
  setGlobalFilter
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    state: {
      columnFilters,
      globalFilter
    }
  });
  return /* @__PURE__ */ jsxs25(Flex6, { css: { flexDirection: "column" }, children: [
    /* @__PURE__ */ jsx43(
      Table2,
      {
        className: tableStyles,
        emptyComponent,
        table
      }
    ),
    /* @__PURE__ */ jsx43(
      Flex6,
      {
        css: {
          alignItems: "center",
          justifyContent: "flex-end",
          mt: "md",
          gap: "md"
        },
        children: /* @__PURE__ */ jsx43(Box10, { children: /* @__PURE__ */ jsx43(TablePaginationActions, { table }) })
      }
    )
  ] });
};

// src/Textarea/Textarea.tsx
import { css as css29, cx as cx21 } from "@doowii/design-system/css";
import { jsx as jsx44 } from "react/jsx-runtime";
var Textarea = ({ className, css: cssProp, ...props }) => /* @__PURE__ */ jsx44(
  "textarea",
  {
    ...props,
    className: cx21(
      css29({
        p: "md",
        borderRadius: "md",
        borderWidth: "2px",
        borderColor: "border.base",
        outline: "none",
        resize: "none",
        color: "foreground.base"
      }),
      className,
      css29(cssProp)
    )
  }
);

// src/Toast/Toast.tsx
import {
  css as css30,
  cva as cva11,
  cx as cx22
} from "@doowii/design-system/css";
import { token } from "@doowii/design-system/tokens";
import {
  FontAwesomeIcon as FontAwesomeIcon17
} from "@fortawesome/react-fontawesome";
import * as ToastPrimitives from "@radix-ui/react-toast";
import * as React31 from "react";
import { jsx as jsx45 } from "react/jsx-runtime";
var ToastProvider = ToastPrimitives.Provider;
var ToastViewport = React31.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx45(ToastPrimitives.Viewport, { className, ref, ...props }));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;
var toastVariants = cva11({
  base: {
    pointerEvents: "auto",
    pos: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    m: "2",
    zIndex: "60",
    overflow: "hidden",
    rounded: "md",
    p: "md",
    pr: "ld",
    shadow: "lg",
    transitionProperty: "all",
    transitionTimingFunction: "all",
    transitionDuration: "all"
  },
  variants: {
    position: {
      "top-left": { top: 0, left: "0" },
      "top-right": { top: 0, right: "0" },
      "top-center": { top: 0, left: "50%", transform: "translateX(-50%)" },
      "bottom-left": { left: "0", bottom: "0" },
      "bottom-right": { right: "0", bottom: "0" },
      "bottom-center": {
        left: "50%",
        bottom: "0",
        transform: "translateX(-50%)"
      }
    },
    status: {
      info: {
        bgColor: "toast.info.background",
        color: "toast.info.foreground"
      },
      success: {
        bgColor: "toast.success.background",
        color: "toast.success.foreground"
      },
      warning: {
        bgColor: "toast.warning.background",
        color: "toast.warning.foreground"
      },
      error: {
        bgColor: "toast.error.background",
        color: "toast.error.foreground"
      }
    }
  },
  defaultVariants: { status: "info", position: "top-center" }
});
var Toast = React31.forwardRef(({ className, status, position, ...props }, ref) => /* @__PURE__ */ jsx45(
  ToastPrimitives.Root,
  {
    className: cx22(toastVariants({ status, position }), className),
    ref,
    ...props
  }
));
Toast.displayName = ToastPrimitives.Root.displayName;
var ToastAction = React31.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx45(
  ToastPrimitives.Action,
  {
    className: cx22(
      css30({
        display: "inline-flex",
        h: "8",
        flexShrink: "0",
        alignItems: "center",
        justifyContent: "center",
        rounded: "md",
        borderWidth: "1px",
        bgColor: "transparent",
        pl: "3",
        pr: "3",
        fontSize: "sm",
        lineHeight: "sm",
        fontWeight: "medium",
        transitionProperty: "color, background-color, border-color, text-decoration-color, fill, stroke",
        transitionTimingFunction: "colors",
        transitionDuration: "colors",
        _focus: { ring: "none", ringOffset: "none", shadow: "1" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      className
    ),
    ref,
    ...props
  }
));
ToastAction.displayName = ToastPrimitives.Action.displayName;
var ToastClose = React31.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx45(
  ToastPrimitives.Close,
  {
    className: cx22(
      css30({
        pos: "absolute",
        right: "1",
        top: "1",
        rounded: "md",
        p: "1",
        opacity: "0",
        transitionProperty: "opacity",
        transitionTimingFunction: "opacity",
        transitionDuration: "opacity",
        _focus: { opacity: "1", ring: "none", ringOffset: "none", shadow: "1" },
        _hover: { opacity: "1" }
      }),
      className
    ),
    ref,
    "toast-close": "",
    ...props,
    children: /* @__PURE__ */ jsx45(FontAwesomeIcon17, { icon: faClose })
  }
));
ToastClose.displayName = ToastPrimitives.Close.displayName;
var ToastTitle = React31.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx45(
  ToastPrimitives.Title,
  {
    className: cx22(
      css30({
        textStyle: "label_16",
        lineHeight: "sm",
        fontWeight: "400"
      }),
      className
    ),
    ref,
    ...props
  }
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;
var ToastDescription = React31.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx45(
  ToastPrimitives.Description,
  {
    className: cx22(
      css30({ textStyle: "paragraph_14", opacity: "0.9" }),
      className
    ),
    ref,
    ...props
  }
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;
var statusIconMap = {
  info: faCircleInfo,
  success: faCircleCheck,
  warning: faCircleExclamation,
  error: faCircleExclamation
};
var iconColorMap = {
  info: token("colors.toast.info.icon"),
  success: token("colors.toast.success.icon"),
  warning: token("colors.toast.warning.icon"),
  error: token("colors.toast.error.icon")
};
var ToastIcon = ({
  status = "info",
  icon,
  loading
}) => {
  if (loading) {
    return /* @__PURE__ */ jsx45(Spinner, {});
  }
  return /* @__PURE__ */ jsx45(
    FontAwesomeIcon17,
    {
      color: iconColorMap[status],
      icon: icon ?? statusIconMap[status]
    }
  );
};

// src/Toast/Toaster.tsx
import { css as css31 } from "@doowii/design-system/css";
import { Box as Box11 } from "@doowii/design-system/jsx";

// src/Toast/useToast.tsx
import * as React32 from "react";
var TOAST_LIMIT = 1;
var TOAST_REMOVE_DELAY = 1e6;
var count = 0;
var genId = () => {
  count = (count + 1) % Number.MAX_SAFE_INTEGER;
  return count.toString();
};
var toastTimeouts = /* @__PURE__ */ new Map();
var addToRemoveQueue = (toastId) => {
  if (toastTimeouts.has(toastId)) {
    return;
  }
  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    dispatch({
      type: "REMOVE_TOAST",
      toastId
    });
  }, TOAST_REMOVE_DELAY);
  toastTimeouts.set(toastId, timeout);
};
var reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TOAST":
      return {
        ...state,
        toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT)
      };
    case "UPDATE_TOAST":
      return {
        ...state,
        toasts: state.toasts.map(
          (t) => t.id === action.toast.id ? { ...t, ...action.toast } : t
        )
      };
    case "DISMISS_TOAST": {
      const { toastId } = action;
      if (toastId) {
        addToRemoveQueue(toastId);
      } else {
        state.toasts.forEach((toast2) => {
          addToRemoveQueue(toast2.id);
        });
      }
      return {
        ...state,
        toasts: state.toasts.map(
          (t) => t.id === toastId || toastId === void 0 ? {
            ...t,
            open: false
          } : t
        )
      };
    }
    case "REMOVE_TOAST":
      if (action.toastId === void 0) {
        return {
          ...state,
          toasts: []
        };
      }
      return {
        ...state,
        toasts: state.toasts.filter((t) => t.id !== action.toastId)
      };
  }
};
var memoryState = { toasts: [] };
var listeners = [];
var dispatch = (action) => {
  memoryState = reducer(memoryState, action);
  listeners.forEach((listener) => {
    listener(memoryState);
  });
};
var toast = ({ ...props }) => {
  const id = genId();
  const update = (props2) => dispatch({
    type: "UPDATE_TOAST",
    toast: { ...props2, id }
  });
  const dismiss = () => dispatch({ type: "DISMISS_TOAST", toastId: id });
  dispatch({
    type: "ADD_TOAST",
    toast: {
      ...props,
      id,
      open: true,
      onOpenChange: (open) => {
        if (!open) {
          dismiss();
        }
      }
    }
  });
  return {
    id,
    dismiss,
    update
  };
};
var useToast = () => {
  const [state, setState] = React32.useState(memoryState);
  React32.useEffect(() => {
    listeners.push(setState);
    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);
  return {
    ...state,
    toast,
    dismiss: (toastId) => dispatch({ type: "DISMISS_TOAST", toastId })
  };
};

// src/Toast/Toaster.tsx
import { jsx as jsx46, jsxs as jsxs26 } from "react/jsx-runtime";
var Toaster = () => {
  const { toasts } = useToast();
  return /* @__PURE__ */ jsxs26(ToastProvider, { children: [
    toasts.map(({ id, title, description, action, ...props }) => /* @__PURE__ */ jsxs26(Toast, { ...props, className: css31({ gap: "md" }), children: [
      /* @__PURE__ */ jsx46(ToastIcon, { icon: props.icon, status: props.status, loading: props.loading }),
      /* @__PURE__ */ jsxs26(Box11, { className: "grid gap-1", children: [
        title ? /* @__PURE__ */ jsx46(ToastTitle, { children: title }) : null,
        description ? /* @__PURE__ */ jsx46(ToastDescription, { children: description }) : null
      ] }),
      action,
      /* @__PURE__ */ jsx46(ToastClose, {})
    ] }, id)),
    /* @__PURE__ */ jsx46(ToastViewport, {})
  ] });
};
export {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Avatar,
  AvatarFallback,
  AvatarImage,
  BackgroundBox,
  Badge,
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  Card,
  Checkbox,
  CircularCounter,
  CircularIcon,
  ConfirmationDialog,
  Default,
  Dialog,
  DialogActionButtons,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  FeedbackButton,
  FollowUpCard,
  Heading,
  InputField,
  Label,
  LoadingLogo,
  Logo,
  LogoD,
  LogoDoowii,
  MainSearchBar,
  NavBar,
  NavBarItem,
  PageLayout,
  Progress,
  RequestBar,
  RequestBarContent,
  RequestBarItem,
  RequestBarTrigger,
  SearchInput,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
  Separator3 as Separator,
  SimpleTable,
  Skeleton,
  Spinner,
  SuggestionCard,
  Switch2 as Switch,
  Table2 as Table,
  TableBody2 as TableBody,
  TableHeader2 as TableHeader,
  TablePagination,
  TablePaginationActions,
  TablePaginationRPS,
  Text,
  Textarea,
  Toast,
  ToastAction,
  ToastClose,
  ToastDescription,
  ToastIcon,
  ToastProvider,
  ToastTitle,
  ToastViewport,
  Toaster,
  Tooltip,
  WithIcon,
  buttonRecipe,
  cardRecipe,
  reducer,
  toast,
  useToast
};
