import { ChartErrorState } from "@components/Charts";
import { ChartConfig } from "@doowii-types/chart";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import HighchartsReact from "highcharts-react-official";
import { memo } from "react";

import { ChartLoadingSpinner } from "./ChartLoadingSpinner";
import { HighchartsWithAccessibility, sharedChartOptions } from "./utils";

// TODO: fix this any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataRow = Record<string, any>;

const extractLineData = (data: DataRow[], chartConfig: ChartConfig) => {
  const xAxisColumn =
    chartConfig.columns.find(
      (key) =>
        chartConfig.column_types[key] === "temporal" ||
        chartConfig.column_types[key] === "categorical"
    ) ?? "";
  const yAxisColumns = chartConfig.columns.filter(
    (key) => chartConfig.column_types[key] === "numerical"
  );

  const groupedData = data.reduce((acc, row) => {
    const xValue = row[xAxisColumn];
    if (!acc[xValue]) {
      acc[xValue] = {};
      yAxisColumns.forEach((yColumn) => {
        acc[xValue][yColumn] = 0;
      });
    }
    yAxisColumns.forEach((yColumn) => {
      acc[xValue][yColumn] += row[yColumn];
    });
    return acc;
  }, {});

  const categories = Object.keys(groupedData);
  const seriesData: HighchartsWithAccessibility.SeriesLineOptions[] = yAxisColumns.map(
    (yColumn) => ({
      type: "line",
      name: yColumn,
      data: categories.map((category) => groupedData[category][yColumn]),
    })
  );

  return {
    categories,
    series: seriesData,
  };
};

const LineChart = ({
  data,
  height,
  width,
  chartConfig,
  loading,
  error,
  title,
  // TODO: fix this any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) => {
  const { _ } = useLingui();
  if (loading || !chartConfig || !chartConfig.columns || !data) {
    return <ChartLoadingSpinner />;
  }
  if (error) {
    return <ChartErrorState />;
  }

  const { categories, series } = extractLineData(data, chartConfig);

  const options: HighchartsWithAccessibility.Options = {
    ...sharedChartOptions,
    chart: {
      ...sharedChartOptions.chart,
      type: "line",
      height,
      width,
      zooming: chartConfig.zooming ?? true,
      style: {
        fontFamily: "inherit",
      },
    },
    title: {
      text: chartConfig.chart_title || title || _(msg`Line Chart`),
    },
    xAxis: {
      categories,
      title: {
        text: chartConfig.columns.find(
          (key: string) =>
            chartConfig.column_types[key] === "temporal" ||
            chartConfig.column_types[key] === "categorical"
        ),
      },
      crosshair: chartConfig.x_axis_crosshair ?? true,
    },
    yAxis: {
      title: {
        text: _(msg`Values`),
      },
    },
    series,
    credits: {
      enabled: false,
    },
    legend: {
      layout: chartConfig.legend_layout || "horizontal",
      align: "center",
      verticalAlign: chartConfig.legend_vertical_align || "bottom",
      floating: chartConfig.legend_floating ?? false,
    },
    tooltip: {
      enabled: chartConfig.tooltip ?? true,
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: chartConfig.data_labels ?? false,
          formatter() {
            return this.y !== 0 ? this.y : null;
          },
        },
        enableMouseTracking: true,
      },
    },
  };

  return <HighchartsReact highcharts={HighchartsWithAccessibility} options={options} />;
};

const MemoizedLineChart = memo(
  LineChart,
  (prevProps, nextProps) =>
    prevProps.data === nextProps.data &&
    prevProps.height === nextProps.height &&
    prevProps.width === nextProps.width &&
    prevProps.chartConfig === nextProps.chartConfig &&
    prevProps.totalRows === nextProps.totalRows &&
    prevProps.title === nextProps.title
);

export { MemoizedLineChart };
