interface SelectionControlProps {
  setSelectedColumns: React.Dispatch<React.SetStateAction<string[]>>;
  id: string;
  selectedColumns: string[];
}
const SelectionControl = ({ setSelectedColumns, id, selectedColumns }: SelectionControlProps) => (
  <input
    checked={selectedColumns.includes(id)}
    onChange={(e) => {
      setSelectedColumns((prev: string[]) => {
        if (e.target.checked) {
          return [...prev, e.target.value];
        }
        return prev.filter((v) => v !== e.target.value);
      });
    }}
    type="checkbox"
    value={id}
  />
);

export { SelectionControl };
