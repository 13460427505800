/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectedColumns } from "@doowii-types/chart";
import { Board, Pin, PinboardContextI } from "@doowii-types/pinboard";
import { VisualizationType } from "@doowii-types/viz";
import { useAuth } from "@hooks/useAuth";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { withSentry } from "@utils/wrapper";
import { useToast } from "doowii-ui";
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { createContext, FC, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidV4 } from "uuid";

import { TABLE } from "../../constants/constants";
import { db } from "../../services/firebase";
import { useChatData } from "../chat";

const initialState: PinboardContextI = {
  boards: new Map(),
  currBoardId: null,
  loading: false,
  pinboardResults: [],
  setBoards: () => {},
  setCurrBoardId: () => {},
  setPinboardResults: () => {},
  addNewBoard: async () => "",
  deleteBoard: async () => {},
  updateBoardName: async () => {},
  fetchPinboardResultsForBoard: async () => {},
  fetchNextPagePinboardResultsForBoard: async () => {},
  pinToBoard: async () => {},
  unpinFromBoard: async () => {},
  updatePinTitle: async () => {},
  updatePinVisualization: async () => {},
};

const PinboardContext = createContext<PinboardContextI>(initialState);
export const usePinboard = () => useContext(PinboardContext);

export const PinboardProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { _ } = useLingui();
  const [boards, setBoards] = useState<Map<string, Board>>(new Map());
  const [currBoardId, setCurrBoardId] = useState<string | null>(null);
  const [pinboardResults, setPinboardResults] = useState<Pin[]>([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot | null>(null);
  const [loading, setLoading] = useState(false);

  const { userDocument } = useAuth();

  const { allResults, answer }: any = useChatData();

  const pinsUnsubscribeRef = useRef<ReturnType<typeof onSnapshot>>();
  const pageSize = 50;
  const { toast } = useToast();

  useEffect(() => {
    setLoading(true);
    setBoards(new Map());
    const userId = userDocument?.id;
    if (!userId) {
      setLoading(false);
      return;
    }

    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardsCollection = collection(organizationRef, "pinboards");
    const createdByQuery = query(pinboardsCollection, where("created_by", "==", userId));
    const canEditQuery = query(pinboardsCollection, where("can_edit", "array-contains", userId));

    const mergeAndUpdateBoards = (docSnap: QueryDocumentSnapshot<any>) => {
      const updatedBoards = new Map([...boards, [docSnap.id, docSnap.data()]]);
      setBoards((prevBoards) => new Map([...prevBoards, [docSnap.id, docSnap.data()]]));

      if (updatedBoards.size > 0) {
        setCurrBoardId((prevCurrBoardId) => prevCurrBoardId ?? updatedBoards.keys().next().value);
      } else {
        setCurrBoardId(null);
      }
    };

    const unsubscribeCreatedBy = onSnapshot(createdByQuery, (snapshot) => {
      snapshot.forEach(mergeAndUpdateBoards);
    });

    const unsubscribeCanEdit = onSnapshot(canEditQuery, (snapshot) => {
      snapshot.forEach(mergeAndUpdateBoards);
    });

    setLoading(false);

    // Cleanup the listeners on component unmount
    return () => {
      unsubscribeCreatedBy();
      unsubscribeCanEdit();
    };
  }, [userDocument]);

  useEffect(() => {
    // Whenever the current board ID changes, fetch the results for that board
    setPinboardResults([]);
    fetchPinboardResultsForBoard();

    // Cleanup the listener on component unmount or when the board ID changes
    return () => {
      if (pinsUnsubscribeRef.current) {
        pinsUnsubscribeRef.current();
      }
    };
  }, [currBoardId]);

  const addNewBoard = withSentry(async (name: string) => {
    try {
      if (!userDocument) {
        throw new Error("User not found!");
      }
      const userId = userDocument.id;
      const organizationRef = doc(db, "organizations", userDocument.organization);
      const pinboardsCollection = collection(organizationRef, "pinboards");
      const userDocRef = doc(organizationRef, "users", userId);

      const uuid = uuidV4();

      const pinboardData: Board = {
        created_by: userId,
        can_edit: [],
        can_view: [],
        pins: [],
        name,
        id: uuid,
        created_at: serverTimestamp(),
        last_updated: serverTimestamp(),
        count: 0,
      };
      await setDoc(doc(pinboardsCollection, uuid), pinboardData);
      await updateDoc(userDocRef, {
        pinboards: arrayUnion(uuid),
      });

      toast({
        title: _(msg`Pinboard created`),
        description: _(msg`Pinboard has been created successfully`),
        status: "success",
      });
      return uuid;
    } catch (error) {
      toast({
        title: _(msg`Failed to create Pinboard`),
        description: _(msg`Failed to create Pinboard. Please try again later.`),
        status: "error",
      });
    }
  });

  const deleteBoard = withSentry(async (boardId: string) => {
    try {
      if (!userDocument) {
        throw new Error("User not found!");
      }
      const organizationRef = doc(db, "organizations", userDocument.organization);

      const pinboardsCollection = collection(organizationRef, "pinboards");
      const pinboardDocRef = doc(pinboardsCollection, boardId);

      // Fetch the pinboard document
      const pinboardDocSnap = await getDoc(pinboardDocRef);

      if (!pinboardDocSnap.exists()) {
        throw new Error("Pinboard not found!");
      }

      // Update the user documents to remove the pinboard ID
      const pinboardData = pinboardDocSnap.data();
      const allUsersToUpdate = [...pinboardData.can_edit, ...pinboardData.can_view];
      allUsersToUpdate.push(pinboardData.created_by);
      const userUpdates = allUsersToUpdate.map(async (userId) => {
        const usersCollection = collection(organizationRef, "users");
        const userDocRef = doc(usersCollection, userId);
        return updateDoc(userDocRef, {
          pinboards: arrayRemove(boardId),
        });
      });
      await Promise.all(userUpdates);

      // Delete the pinboard document and all its pins
      const pinsCollectionRef = collection(pinboardDocRef, "pins");
      const pinsSnapshot = await getDocs(pinsCollectionRef);
      const batch = writeBatch(db);
      pinsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      batch.delete(pinboardDocRef);
      await batch.commit();

      // Update the local state
      const newBoards = new Map(boards);
      newBoards.delete(boardId);
      setBoards(newBoards);

      if (newBoards.size > 0) {
        setCurrBoardId(newBoards.keys().next().value);
      } else {
        setCurrBoardId(null);
      }

      toast({
        title: _(msg`Pinboard deleted`),
        description: _(msg`Pinboard has been deleted successfully`),
        status: "success",
      });
    } catch (error) {
      toast({
        title: _(msg`Failed to delete Pinboard`),
        description: _(msg`Failed to delete Pinboard. Please try again later.`),
        status: "error",
      });
    }
  });

  const updateBoardName = withSentry(async (boardId: string, name: string) => {
    try {
      if (!userDocument) {
        throw new Error("User not found!");
      }
      const organizationRef = doc(db, "organizations", userDocument.organization);
      const pinboardDocRef = doc(organizationRef, "pinboards", boardId);

      await updateDoc(pinboardDocRef, { name });

      toast({
        title: _(msg`Pinboard name updated`),
        description: _(msg`Pinboard name has been updated successfully`),
        status: "success",
      });
    } catch (error) {
      toast({
        title: _(msg`An error occurred`),
        description: _(msg`Failed to update pinboard name`),
        status: "error",
      });
    }
  });

  const pinToBoard = withSentry(async (chatId: string, boardId: string) => {
    try {
      if (!userDocument || !allResults) {
        throw new Error("User not found!");
      }
      const result = allResults.find((res) => res.id === chatId);
      const chatIndex = allResults.findIndex((item) => item.id === chatId);
      const chatAnswer = answer.find((item, index) => index === chatIndex);
      const organizationRef = doc(db, "organizations", userDocument.organization);
      const pinboardDocRef = doc(organizationRef, "pinboards", boardId);
      const pinRef = doc(pinboardDocRef, "pins", result.id);
      const pinDoc = await getDoc(pinRef);

      if (pinDoc.exists()) {
        console.info("Chat already pinned to the pinboard");
        toast({
          title: _(msg`Chat already pinned`),
          description: _(msg`The chat is already pinned to the pinboard`),
          status: "info",
        });
        return;
      }

      await setDoc(pinRef, {
        id: result.id,
        query: result.query,
        sql: result.sql ?? "",
        originalSql: result.originalSql ?? result.sql ?? "",
        title: result.title,
        visualisation: result.visualisation,
        selectedColumns: result.visualisation === TABLE ? {} : (result.selectedColumns ?? {}),
        timestamp: new Date(),
        can_view: [],
        can_edit: [],
        created_at: new Date(),
        last_updated: new Date(),
        type: result.type || "QUERY",
        status: result.status || "success",
        chartConfig: result.chartConfig || {},
        answer: chatAnswer || "",
        boardId,
      });
      await updateDoc(pinboardDocRef, { pins: arrayUnion(result.id), count: increment(1) });

      toast({
        title: _(msg`Chat pinned`),
        description: _(msg`The chat has been pinned to the pinboard`),
        status: "success",
      });
    } catch (error) {
      toast({
        title: _(msg`Failed to pin chat`),
        description: _(msg`Failed to pin chat to the pinboard`),
        status: "error",
      });
    }
  });

  const unpinFromBoard = withSentry(async (chatId: string, boardId: string) => {
    try {
      if (!userDocument) {
        throw new Error("User not found!");
      }
      const organizationRef = doc(db, "organizations", userDocument.organization);
      const pinboardDocRef = doc(organizationRef, "pinboards", boardId);
      const pinRef = doc(pinboardDocRef, "pins", chatId);
      await updateDoc(pinboardDocRef, { pins: arrayRemove(chatId), count: increment(-1) });
      await deleteDoc(pinRef);
      toast({
        title: _(msg`Chat unpinned`),
        description: _(msg`The chat has been unpinned from the pinboard`),
        status: "success",
      });
    } catch (error) {
      console.error("Error unpinning chat:", error);
      toast({
        title: _(msg`Failed to unpin chat`),
        description: _(msg`Failed to unpin chat from the pinboard`),
        status: "error",
      });
    }
  });

  const fetchAndSetPins = withSentry(async (pins: Pin[]) => {
    setPinboardResults(pins);
    setLoading(false);
  });

  const fetchPinboardResultsForBoard = withSentry(async () => {
    // Ensure we have a board ID to fetch results for
    if (!currBoardId) {
      return;
    }

    if (!userDocument) {
      throw new Error("User not found!");
    }

    setLoading(true);
    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardDocRef = doc(organizationRef, "pinboards", currBoardId);
    const pinsCollection = query(
      collection(pinboardDocRef, "pins"),
      orderBy("created_at", "asc"),
      limit(pageSize)
    );

    // Cleanup any previous listener
    if (pinsUnsubscribeRef.current) {
      pinsUnsubscribeRef.current();
    }

    // Set up a real-time listener for the pins subCollection
    pinsUnsubscribeRef.current = onSnapshot(pinsCollection, async (snapshot) => {
      const fetchedResults: Pin[] = [];
      snapshot.forEach((docSnap) => {
        fetchedResults.push(docSnap.data() as Pin);
      });

      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

      await fetchAndSetPins(fetchedResults);
    });
  });

  const fetchNextPagePinboardResultsForBoard = withSentry(async () => {
    if (!currBoardId || !lastVisible) {
      return;
    }

    if (!userDocument) {
      throw new Error("User not found!");
    }

    const organizationRef = doc(db, "organizations", userDocument.organization);
    const pinboardDocRef = doc(organizationRef, "pinboards", currBoardId);
    const pinsCollectionQuery = query(
      collection(pinboardDocRef, "pins"),
      orderBy("created_at", "asc"),
      startAfter(lastVisible),
      limit(pageSize)
    );
    const snapshot = await getDocs(pinsCollectionQuery);

    const fetchedResults: Pin[] = [];
    snapshot.forEach((docSnap) => {
      fetchedResults.push(docSnap.data() as Pin);
    });
    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
    await fetchAndSetPins([...pinboardResults, ...fetchedResults]);
  });
  // Add other relevant functions for your use case

  const updatePinTitle = withSentry(async (pinboardId: string, pinId: string, title: string) => {
    try {
      if (!userDocument) {
        throw new Error("User not found!");
      }

      const organizationRef = doc(db, "organizations", userDocument.organization);
      const pinboardDocRef = doc(organizationRef, "pinboards", pinboardId);
      const pinRef = doc(pinboardDocRef, "pins", pinId);
      await updateDoc(pinRef, { title });

      toast({
        title: _(msg`Pin title updated`),
        description: _(msg`Pin title updated successfully!`),
        status: "success",
      });
    } catch (error) {
      toast({
        title: _(msg`An error occurred`),
        description: _(msg`Error updating pin title! Please try again later.`),
        status: "error",
      });
    }
  });

  const updatePinVisualization = withSentry(
    async (
      pinboardId: string,
      pinId: string,
      selectedColumns: SelectedColumns,
      visualisation: VisualizationType
    ) => {
      try {
        if (!userDocument) {
          throw new Error("User not found!");
        }

        const _selectedColumns = visualisation === TABLE ? {} : selectedColumns;

        const organizationRef = doc(db, "organizations", userDocument.organization);
        const pinboardDocRef = doc(organizationRef, "pinboards", pinboardId);
        const pinRef = doc(pinboardDocRef, "pins", pinId);
        await updateDoc(pinRef, { selectedColumns: _selectedColumns, visualisation });

        console.info("Pin visualisation updated successfully!");
        toast({ description: _(msg`Pin visualisation updated successfully!`), status: "success" });

        await fetchPinboardResultsForBoard();
      } catch (error) {
        console.error("Error updating pin visualisation:", error);
        toast({
          description: _(msg`Error updating pin visualisation! Please try again later.`),
          status: "error",
        });

        throw error;
      }
    }
  );

  return (
    <PinboardContext.Provider
      value={{
        loading,
        boards,
        setBoards,
        currBoardId,
        setCurrBoardId,
        pinboardResults,
        setPinboardResults,
        addNewBoard,
        deleteBoard,
        updateBoardName,
        fetchPinboardResultsForBoard,
        fetchNextPagePinboardResultsForBoard,
        pinToBoard,
        unpinFromBoard,
        updatePinTitle,
        updatePinVisualization,
      }}
    >
      {children}
    </PinboardContext.Provider>
  );
};
