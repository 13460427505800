import { faArrowAltDown, faArrowAltUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@styled-system/css";
import { SortingState } from "@tanstack/react-table";

interface SortingControlProps {
  isSorted: false | "asc" | "desc";
  id: string;

  setSorting: (sorting: SortingState) => void;
}

const SortingControl = ({ isSorted, id, setSorting }: SortingControlProps) => (
  <button
    className={css({
      cursor: "pointer",
      _hover: { color: "base.blueBrandPrimary" },
    })}
    onClick={() => {
      if (isSorted === false) {
        setSorting([
          {
            id,
            desc: false,
          },
        ]);
      } else if (isSorted === "asc") {
        setSorting([
          {
            id,
            desc: true,
          },
        ]);
      } else if (isSorted === "desc") {
        setSorting([]);
      }
    }}
    type="button"
  >
    <FontAwesomeIcon
      icon={isSorted === "asc" || isSorted === false ? faArrowAltUp : faArrowAltDown}
    />
  </button>
);

export { SortingControl };
