import { ParentDocTypeEnum } from "@api/retriever.i";
import { DataContent } from "@components/DataContent";
import { DataNavigator } from "@components/DataNavigator";
import { ShareResultDialog } from "@components/ShareResultDialog";
import { SQLSidePanel } from "@components/SQLSidePanel";
import { useChatData } from "@context/chat";
import type { Result } from "@doowii-types/chat";
import { DataVizType, DataVizTypes } from "@doowii-types/viz";
import { css, cx } from "@styled-system/css";
import { HStack } from "@styled-system/jsx";
import { flex } from "@styled-system/patterns";
import { useEffect, useState } from "react";

import { ChatMenu } from "./ChatMenu";
import { ApolloDialog } from "@components/Apollo/ApolloDialog";

interface ChatDataBoardProps {
  result: Result;
  setIsShareResultDialogOpen: (isOpen: boolean) => void;
  isShareResultDialogOpen: boolean;
  isApolloDialogOpen: boolean;
  setIsApolloDialogOpen: (isOpen: boolean) => void;
}

const ChatDataBoard = ({
  result,
  setIsShareResultDialogOpen,
  isShareResultDialogOpen,
  isApolloDialogOpen,
  setIsApolloDialogOpen,
}: ChatDataBoardProps) => {
  const { currentThread } = useChatData();
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [selectedDataVizType, setSelectedDataVizType] = useState<DataVizType>(
    result.visualisation || DataVizTypes.TABLE
  );
  useEffect(() => {
    setSelectedDataVizType(result.visualisation);
  }, [result]);

  return (
    <div
      className={cx(
        flex({
          direction: "column",
        })
      )}
    >
      <div
        className={cx(
          flex({
            direction: "row",
            justify: "space-between",
            align: "center",
          }),
          css({
            h: "16",
            p: "4",
          })
        )}
      >
        <DataNavigator
          columnTypes={result.chartConfig?.column_types}
          selectedColumns={selectedColumns}
          selectedDataVizType={selectedDataVizType}
          setSelectedDataVizType={setSelectedDataVizType}
          suggestedChartType={result.chartConfig?.suggestion}
          setIsApolloDialogOpen={setIsApolloDialogOpen}
        />
        <HStack gap="none">
          <SQLSidePanel
            chatId={result.id}
            originalSql={result.originalSql ?? result.sql}
            sql={result.sql}
          />
          <ChatMenu chatId={result.id} title={result.title} />
        </HStack>
      </div>

      <div className={css({ w: "full", maxHeight: "3xl", overflowY: "auto" })}>
        <DataContent
          currentResult={result}
          parentDocId={currentThread}
          parentDocType={ParentDocTypeEnum.THREAD}
          selectedColumns={selectedColumns}
          selectedDataVizType={selectedDataVizType}
          setSelectedColumns={setSelectedColumns}
        />
      </div>
      <ShareResultDialog
        isOpen={isShareResultDialogOpen}
        parentDocId={currentThread}
        parentDocType={ParentDocTypeEnum.THREAD}
        result={result}
        selectedColumns={selectedColumns}
        selectedDataVizType={selectedDataVizType}
        setIsShareResultDialogOpen={setIsShareResultDialogOpen}
        setSelectedColumns={setSelectedColumns}
      />
      <ApolloDialog
        isOpen={isApolloDialogOpen}
        parentDocId={currentThread}
        parentDocType={ParentDocTypeEnum.THREAD}
        result={result}
        selectedColumns={selectedColumns}
        selectedDataVizType={selectedDataVizType}
        setIsOpen={setIsApolloDialogOpen}
        setSelectedColumns={setSelectedColumns}
      />
    </div>
  );
};

export { ChatDataBoard };
