import { ParentDocTypeEnum } from "@api/retriever.i";
import { DataContent } from "@components/DataContent";
import { DataNavigator } from "@components/DataNavigator";
import { usePinboard } from "@context/pinboard";
import { Pin } from "@doowii-types/pinboard";
import { DataVizType, DataVizTypes } from "@doowii-types/viz";
import { css, cx } from "@styled-system/css";
import { flex } from "@styled-system/patterns";
import { useEffect, useState } from "react";

interface FullScreenChatDataBoardProps {
  pin: Pin;
}

const FullScreenChatDataBoard = ({ pin }: FullScreenChatDataBoardProps) => {
  const { currBoardId } = usePinboard();
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [selectedDataVizType, setSelectedDataVizType] = useState<DataVizType>(
    pin.visualisation ?? DataVizTypes.TABLE
  );
  useEffect(() => {
    setSelectedDataVizType(pin.visualisation);
  }, [pin]);

  return (
    <div
      className={cx(
        flex({
          direction: "column",
        })
      )}
    >
      <div
        className={cx(
          flex({
            direction: "row",
            justify: "space-between",
            align: "center",
          }),
          css({
            h: "16",
            p: "4",
          })
        )}
      >
        <DataNavigator
          columnTypes={pin.chartConfig?.column_types}
          selectedColumns={selectedColumns}
          selectedDataVizType={selectedDataVizType}
          setSelectedDataVizType={setSelectedDataVizType}
          suggestedChartType={pin.chartConfig?.suggestion}
          setIsApolloDialogOpen={() => {}}
        />
      </div>

      <div className={css({ w: "full", maxHeight: "3xl", overflowY: "auto" })}>
        <DataContent
          currentResult={pin}
          parentDocId={currBoardId as string}
          parentDocType={ParentDocTypeEnum.PINBOARD}
          selectedColumns={selectedColumns}
          selectedDataVizType={selectedDataVizType}
          setSelectedColumns={setSelectedColumns}
        />
      </div>
    </div>
  );
};

export { FullScreenChatDataBoard };
