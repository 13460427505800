// ApolloDialog.tsx
import React, { useState } from "react";
import { DataContent } from "@components/DataContent";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogBody,
  DialogFooter,
  Button,
  MainSearchBar,
  RequestBar,
  RequestBarItem,
  RequestBarTrigger,
} from "doowii-ui";
import { css } from "@styled-system/css";
import { HStack, Flex, Box } from "@styled-system/jsx";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Result } from "@doowii-types/chat";
import { DataVizType, Pin } from "@doowii-types/index";
import { ParentDocTypeEnum } from "@api/retriever.i";
import { ApolloChatArea } from "./ApolloChatArea";
import { UserAvatar } from "@components/UserAvatar";
import { LogoAvatar } from "@components/ChatContainer";

interface ApolloDialogProps {
  result: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  selectedColumns: string[];
  selectedDataVizType: DataVizType;
  setSelectedColumns: (columns: string[]) => void;
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}

const ApolloDialog: React.FC<ApolloDialogProps> = ({
  result,
  parentDocId,
  parentDocType,
  selectedColumns,
  selectedDataVizType,
  setSelectedColumns,
  setIsOpen,
  isOpen,
}) => {
  const { _ } = useLingui();
  const [searchInput, setSearchInput] = useState("");

  const handleSave = () => {
    // TODO: Implement save functionality
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined} variant="fullScreen">
        <DialogHeader>
          <DialogTitle style={{ margin: "0" }}>
            <Trans>Edit chart</Trans>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className={css({ width: "80vw", height: "80vh" })}>
          <Flex direction="row" gap="md" height="100%">
            {/* Left Side - DataContent */}
            <Box width="70%" height="100%">
              <DataContent
                currentResult={result}
                parentDocId={parentDocId}
                parentDocType={parentDocType}
                selectedColumns={selectedColumns}
                selectedDataVizType={selectedDataVizType}
                setSelectedColumns={setSelectedColumns}
              />
            </Box>

            {/* Right Side */}
            <Box width="30%" display="flex" flexDirection="column" height="100%">
              {/* Top Right - Dropdown */}
              <Box flexShrink={0}>{/* Placeholder for dropdown component */}</Box>

              {/* Chat Area */}
              <Box flex="1" overflowY="auto" mt="md" mb="md">
                <ApolloChatArea>
                  <Flex direction="column" gap="sm">
                    {/* Replace with your chat bubble components */}
                    <RequestBar type="multiple">
                      <RequestBarItem value="item-1">
                        <RequestBarTrigger>
                          <UserAvatar />
                          Hello World
                        </RequestBarTrigger>
                      </RequestBarItem>
                    </RequestBar>
                    <RequestBar type="multiple">
                      <RequestBarItem value="item-1">
                        <RequestBarTrigger>
                          <LogoAvatar />
                          What up
                        </RequestBarTrigger>
                      </RequestBarItem>
                    </RequestBar>
                  </Flex>
                </ApolloChatArea>
              </Box>

              {/* Bottom Right - MainSearchBar */}
              <Box flexShrink={0}>
                <MainSearchBar
                  placeholder={_("Ask Doowii")}
                  label={_("Chat Input")}
                  onSubmit={(value) => setSearchInput(value)}
                  size="small"
                />
              </Box>
            </Box>
          </Flex>
        </DialogBody>
        <DialogFooter>
          <HStack justifyContent="center" width="full" gap="md">
            <Button onClick={handleSave} variant="primary">
              <Trans>Save chart</Trans>
            </Button>
            <Button onClick={handleCancel} variant="secondary">
              <Trans>Cancel</Trans>
            </Button>
          </HStack>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { ApolloDialog };
