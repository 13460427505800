import { RowData, SortingState } from "@tanstack/react-table";
import { withSentry } from "@utils/wrapper";

import { retrieverPost } from "./base";
import { Columns, DataTypes, ParentDocTypeEnum, RowCount, Rows } from "./retriever.i";
import { convertCase } from "./utils";

interface FetchPageDataParams {
  pageSize: number;
  pageNumber: number;
  sortModel?: SortingState;
  docId: string;
  parentDocType: ParentDocTypeEnum;
  parentDocId?: string;
  retrievalType?: string;
  userDefinedQuery?: boolean;
}

interface SortModel {
  field: string;
  sort: string;
}

const convertFormat = (before: SortingState): SortModel[] => [
  {
    field: before[0].id,
    sort: before[0].desc ? "desc" : "asc",
  },
];

const fetchPageData = withSentry(
  async ({
    pageSize,
    pageNumber,
    docId,
    parentDocId,
    parentDocType,
    sortModel = [],
    userDefinedQuery = false,
  }: FetchPageDataParams): Promise<{
    rows: Rows;
    columns: Columns<RowData>;
    rowCount: RowCount;
    dataTypes: DataTypes;
  }> => {
    const response = await retrieverPost("retrieve", {
      doc_id: docId,
      parent_doc_id: parentDocId,
      parent_doc_type: parentDocType,
      page_size: pageSize,
      page_number: pageNumber,
      sort_model: sortModel.length > 0 ? convertFormat(sortModel) : [],
      user_defined_query: userDefinedQuery,
    });

    const columns: Columns<RowData> =
      response.data.rows.length > 0
        ? Object.keys(response.data.rows[0]).map((key) => ({
            header: convertCase(key),
            accessorKey: key,
          }))
        : [];
    const rows: Rows = response.data.rows.map((row) => {
      const newRow = { ...row };
      Object.entries(row).forEach(([key, value]) => {
        if (response.data.data_types[key] === "float") {
          if (typeof value === "string") {
            newRow[key] = parseFloat(value).toFixed(2);
          } else if (typeof value === "number") {
            newRow[key] = parseFloat(value.toFixed(2));
          }
        }
      });
      return newRow;
    });

    return {
      rows,
      columns,
      rowCount: response.data.totalRows,
      dataTypes: response.data.data_types,
    };
  }
);

const fetchAllData = withSentry(async (filename, doc_id, parent_doc_id, parent_doc_type) => {
  try {
    const response = await retrieverPost("retrieve/download", {
      doc_id,
      parent_doc_id,
      parent_doc_type,
      filename,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

const validateUserDefinedSQL = withSentry(
  async (sql: string): Promise<{ isValid: boolean; message: string }> => {
    try {
      const response = await retrieverPost("retrieve/validate_sql", {
        proposed_sql: sql,
      });
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const sqlValidation = await response.data;
      return {
        isValid: sqlValidation.isValid,
        message: sqlValidation.message,
      };
    } catch (error) {
      console.error("Error validating SQL: ", error);
      return {
        isValid: false,
        message: "An error occurred while validating the SQL.",
      };
    }
  }
);

const fetchQueryMetaData = withSentry(async (sql: string) => {
  try {
    const response = await retrieverPost("retrieve/sql_metadata", { sql });

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const sqlMetadata = response.data;
    return {
      tables: sqlMetadata.tables,
      joins: sqlMetadata.joins,
      columns: sqlMetadata.columns,
    };
  } catch (error) {
    console.error("Error fetching SQL metadata: ", error);

    throw new Error("Failed to load SQL metadata. Please try again later.");
  }
});
export { fetchAllData, fetchPageData, fetchQueryMetaData, validateUserDefinedSQL };
